import { createSlice } from '@reduxjs/toolkit'

const oidcInitialState = {
    isLoadingUser: false,
    user: {
        access_token: '-',
    },
    userInfo: null,
    ocwRequestId: null,
}

const resolveOidcOperation = (state, operationType, data) => {
    switch (operationType) {
        case 'RESET':
            state.user = null
            state.userInfo = null
            state.isLoadingUser = false
            break
        case 'SET':
            let newUser = {
                ...state.user,
                scope: data.scope,
                token_type: data.token_type,
                profile: data.profile,
            }
            if (data.id_token) {
                newUser.id_token = data.id_token
            }
            if (data.access_token) {
                newUser.access_token = data.access_token
            }
            if (data.refresh_token) {
                newUser.refresh_token = data.refresh_token
            }
            if (data.expires_at) {
                newUser.expires_at = data.expires_at
            }
            state.user = newUser
            break
        case 'SET_ACCESS_TOKEN':
            state.user = {
                ...state.user,
                access_token: data,
            }
            break
        case 'SET_USER_INFO':
            state.userInfo = {
                ...data,
                access_token: undefined,
            }
            break
        case 'SET_OCW_REQUEST_ID':
            state.ocwRequestId = data
            break
        case 'LOADING':
            state.isLoadingUser = true
            break
        default:
            break
    }
}

export const oidcSlice = createSlice({
    name: 'oidc',
    initialState: oidcInitialState,
    reducers: {
        userExpired: (state) => {
            resolveOidcOperation(state, 'RESET', null)
        },
        userFound: {
            reducer: (state, data) => {
                resolveOidcOperation(state, 'SET', data.payload.user)
            },
            prepare: (payload) => ({
                payload: {
                    ...payload,
                    user: JSON.parse(JSON.stringify(payload.user)),
                },
            }),
        },
        setUserInfo: {
            reducer: (state, data) => {
                resolveOidcOperation(state, 'SET_ACCESS_TOKEN', data.payload.userInfo.access_token)
                resolveOidcOperation(state, 'SET_USER_INFO', data.payload.userInfo)
                resolveOidcOperation(state, 'SET_OCW_REQUEST_ID', data.payload.ocwRequestId)
            },
            prepare: (payload) => {
                return {
                    payload: {
                        ...payload,
                        userInfo: payload.userInfo || null,
                        ocwRequestId: payload.ocwRequestId,
                    },
                }
            },
        },
        silentRenewError: (state) => {
            resolveOidcOperation(state, 'RESET', null)
        },
        sessionTerminated: (state) => {
            resolveOidcOperation(state, 'RESET', null)
        },
        userExpiring: (state) => state,
        loadUserError: (state) => state,
        loadingUser: (state) => {
            resolveOidcOperation(state, 'LOADING', null)
        },
        userSignedOut: (state) => {
            resolveOidcOperation(state, 'RESET', null)
        },
        clearOcwRegistrationId: (state) => {
            resolveOidcOperation(state, 'SET_OCW_REQUEST_ID', null)
        },
    },
})

export const {
    userExpired,
    userFound,
    setUserInfo,
    silentRenewError,
    sessionTerminated,
    userExpiring,
    loadUserError,
    loadingUser,
    userSignedOut,
    clearOcwRegistrationId,
} = oidcSlice.actions

import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
// import {USER_FOUND, USER_EXPIRED} from 'redux-oidc';
import createRootReducer from './reducers'
import { setAccessToken, setIdToken } from './api'
import { SET_ID_TOKEN } from './types/reduxActionTypes'

export const history = createBrowserHistory()

const userFoundAction = 'oidc/userFound'
const userExpiredAction = 'oidc/userExpired'
const userSignedOutAction = 'oidc/userSignedOut'
const setAccessCredentialsAction = 'oidc/setUserInfo'

const persistConfig = {
    key: 'root',
    storage: storageSession,
}

// // Dispatches when a valid user is found (on startup, after token refresh or token callback).
const saveTokens = () => (next) => (action) => {
    if (action.type === userFoundAction) {
        const accessToken = action.payload.user.access_token
        const idToken = action.payload.user.id_token
        if (idToken) {
            setIdToken(idToken)
        }
        if (accessToken) {
            setAccessToken(accessToken)
        }
        if (!idToken && !accessToken) {
            console.error('ID token and access token not received.')
        }
    } else if (action.type === SET_ID_TOKEN) {
        const idToken = action.payload.id_token
        setIdToken(idToken)
    } else if (action.type === setAccessCredentialsAction) {
        const accessToken = action.payload.userInfo.access_token
        setAccessToken(accessToken)
    } else if (action.type === userExpiredAction || action.type === userSignedOutAction) {
        setAccessToken('')
    }
    return next(action)
}

//
// const initI18n = () => (next) => (action) => {
//   const {basePath} = ConfigurationHolder.getConfiguration();
//   if (action.type === USER_FOUND) {
//     i18nServiceInit(basePath, true);
//   }
//   if (action.type === USER_EXPIRED) {
//     i18nServiceInit(basePath, false);
//   }
//   return next(action);
// };

const rootReducer = createRootReducer(history)
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(preloadedState) {
    const store = createStore(
        persistedReducer,
        preloadedState,
        compose(
            composeWithDevTools(
                applyMiddleware(
                    thunk,
                    routerMiddleware(history), // for dispatching history actions
                    saveTokens
                    // initI18n,
                )
            )
        )
    )
    const persistor = persistStore(store)
    return { store, persistor }
}

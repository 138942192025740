import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import {
    DEFAULT_CULTURE,
    ignoreKeyInHTML,
    TRANSLATIONS_NAMESPACE,
    vehicleIdentificationDefaultValue,
    vehicleIdentificationPlaceholder,
} from '../../constants/global'
import { useParams } from 'react-router'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import { downloadAndDispatchTranslation } from '../../actions/i18nActions'
import { useSelector } from 'react-redux'

export default function ConsentDocumentPreview() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { setLanguage } = useChangeLanguage()
    const { lang } = useParams()
    const orderDetails = useSelector((state) => state.customer.orderDetails)
    const bid = useSelector((state) => state.oidc.userInfo.bid)

    const vehicleIdentification =
        orderDetails.vin ||
        (orderDetails.commissionNumber && bid && orderDetails.commissionYear
            ? `${orderDetails.commissionNumber}-${bid}-${orderDetails.commissionYear}`
            : false) ||
        orderDetails.orderNumber ||
        vehicleIdentificationDefaultValue

    useEffect(() => {
        const init = async () => {
            await downloadAndDispatchTranslation(lang || DEFAULT_CULTURE)
            setLanguage(lang || DEFAULT_CULTURE)
            window.setTimeout(() => window.print(), 1000)
        }
        init()
    }, [lang, setLanguage])

    return (
        <div className="content-wrapper--print">
            <div className="content-wrapper">
                {parse(
                    t('general.documents.consent.description', {
                        replace: ignoreKeyInHTML,
                    }).replace(vehicleIdentificationPlaceholder, vehicleIdentification)
                )}
                {parse(
                    t('general.documents.consent.categories.title', { replace: ignoreKeyInHTML })
                )}
                <ul className="caret-list">
                    {parse(
                        t('general.documents.consent.categories.content', {
                            replace: ignoreKeyInHTML,
                        })
                    )}
                    {parse(
                        t('general.documents.consent.agreement.title', { replace: ignoreKeyInHTML })
                    )}
                </ul>
                <div className="caret-list">
                    {parse(
                        t('general.documents.consent.agreement.content', {
                            replace: ignoreKeyInHTML,
                        })
                    )}
                </div>
                <div style={{ height: '30px' }} />
                <p>
                    {parse(t('general.documents.consent.date', { replace: ignoreKeyInHTML }))}
                    <input type="text" className="signature" />
                </p>
                <div style={{ height: '10px' }} />
                <p>
                    {parse(t('general.documents.consent.signature', { replace: ignoreKeyInHTML }))}
                    <input type="text" className="signature" />
                </p>
            </div>
        </div>
    )
}

import { v4 as uuid4 } from 'uuid'
import { dateFormats, defaultCalendarFormat } from '../constants/dateFormats'
import { setConsentsCreateDialogStatus } from '../actions/popupsActions'

export function getDateFormat(language) {
    if (language === null) {
        return defaultCalendarFormat
    }
    language = language.substr(0, 2)
    return dateFormats[language]
}

export function isRegistrationProcess() {
    const pathname = window.location.pathname
    return (
        pathname === '/customer-information' ||
        pathname === '/skoda-ident' ||
        pathname === '/vehicle-details'
    )
}

export const checkUrl = () => {
    if (isRegistrationProcess()) {
        setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.cancel)
        return true
    }
    return false
}

export function compareIgnoreCase(a, b) {
    if (a === null) {
        return 1
    }
    if (b === null) {
        return -1
    }
    let r1 = isNaN(a) ? a.toLowerCase() : a.toString()
    let r2 = isNaN(b) ? b.toLowerCase() : b.toString()
    return r1.localeCompare(r2)
}

export const CONSENTS_CREATE_DIALOG_STATUS_MAP = {
    inactive: 'INACTIVE',
    // dialog shown in default mode
    active: 'ACTIVE',
    // dialog shown with customer data loading from ocw
    ocw: 'OCW',
    // dialog shown with customer data prefilled
    addVehicle: 'ADD_VEHICLE',
    // dialog shown in cancel creation process mode
    cancel: 'CANCEL',
    // dialog shown in cancel add vehicle mode
    cancelAddVehicle: 'CANCEL_ADD_VEHICLE',
}

export const VEHICLE_INFO_TYPES_ENUM = {
    update: 'UPDATE',
    // when updating vehicle info for non existing vehicle in customer creation process
    updateOnCreation: 'UPDATE_ON_CREATION',
}

export const CONSENT_TYPES_ENUM = {
    paper: 'PAPER',
    digital: 'DIGITAL',
}

export const getSessionId = () => {
    const sessionId = window.sessionStorage.getItem('x-session-id') || uuid4()
    // saving sessionId in case it was not present
    window.sessionStorage.setItem('x-session-id', sessionId)
    return sessionId
}

export const clearSessionId = () => {
    window.sessionStorage.removeItem('x-session-id')
}

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled('div')((props) => ({
    maxWidth: '552px',
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    padding: '45px 18px',

    [props.theme.breakpoints.up('sm')]: {
        width: 552,
        padding: '75px 98px',
    },
}))

export default function Panel({ children }) {
    return <Wrapper>{children}</Wrapper>
}

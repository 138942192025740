import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createCustomerReducer } from './reducers/createCustomerReducer'
import { commissionsReducer } from './reducers/commissionsReducer'
import { accountReducer } from './reducers/accountReducer'
import { optionsReducer } from './reducers/optionsReducer'
import { popupsReducer } from './reducers/popupsReducer'
import { authReducer } from './reducers/authReducer'
import { expiredLicensesReducer } from './reducers/expiredLicensesReducer'

import notifyReducer from 'react-redux-notify'
import { oidcSlice } from './oidc/OidcSlice'

const o = (history) =>
    combineReducers({
        router: connectRouter(history),
        oidc: oidcSlice.reducer,
        auth: authReducer,
        account: accountReducer,
        commissions: commissionsReducer,
        customer: createCustomerReducer,
        options: optionsReducer,
        popups: popupsReducer,
        notifications: notifyReducer,
        expiredLicenses: expiredLicensesReducer,
    })

export default o

import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
    TextField,
    FormHelperText,
    InputAdornment,
    IconButton,
    Button,
} from '@material-ui/core'
import PencilIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'
import { consentsEnum, ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../constants/global'
import {
    errorTextMap,
    getErrorText,
    INCORRECT_EMAIL,
    validateCustomerWithConsents,
    validateEmail,
    validateVin,
} from '../util/validators'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateCustomerActions } from '../hooks/actions/useUpdateCustomerActions'
import Close from '@material-ui/icons/Close'
import { useSaveInputChange } from '../hooks/form/useSaveInputChange'
import { Accounts, Customers, Vehicles } from '../api'
import { useCustomerActions } from '../hooks/actions/useCustomerActions'
import ServicePartnerSearchInput from './ServicePartnerSearchInput/ServicePartnerSearchInput'
import { useServicePartnersActions } from '../hooks/actions/useServicePartnerActions'
import { useDispatchNotification } from '../hooks/useDispatchNotification'
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify'
import { CONSENT_TYPES_ENUM, CONSENTS_CREATE_DIALOG_STATUS_MAP } from '../util/util'
import {
    ADD_CUSTOMER_INFO_FROM_GROUP_ID,
    SET_ACCOUNT_CHECK_DATA,
    SET_CUSTOMER_DATA,
    SET_EXPIRED_LICENSES,
} from '../types/reduxActionTypes'
import { allowAccessToCreateProcess, setConsentsCreateDialogStatus } from '../actions/popupsActions'
import { useHistory } from 'react-router'
import { CloseCross } from './global'
import { clearOcwRegistrationId } from '../oidc/OidcSlice'
import CancelNewCustomerPopUp from './PopUp/variants/CancelNewCustomerPopUp'
import { defaultCountryId } from '../constants/defaultFormValues'

const isFormValid = (data) => {
    const validStateOptions = [
        'firstName',
        'lastName',
        'vin',
        'commissionNumber',
        'orderNumber',
        'psp',
        'countryId',
        'preferredLanguage',
    ]
    return validStateOptions.every((item) => data[item] === null)
}

const year = new Date().getFullYear()

const ConsentsCreateDialog = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const dispatch = useDispatch()
    const history = useHistory()

    const { userInfo } = useSelector((state) => state.oidc)
    const customer = useSelector((state) => state.customer)
    const { customerInformation, orderDetails, consentVerification } = customer
    const { countries, selectedLanguage } = useSelector((state) => state.options)
    const { consentsDialogStatus } = useSelector((state) => state.popups)
    const [accountCheckedEmail, setAccountCheckedEmail] = useState('')

    const { setSearchParam } = useSaveInputChange()
    const {
        saveCustomerInformationField,
        saveCustomerInformationItem,
        saveConsentVerificationField,
        saveCustomerInformationAddressItem,
        saveOrderDetailsField,
        clearCustomer,
        resetCustomerWithData,
    } = useUpdateCustomerActions()
    const { setConsentId, setConsentType } = useCustomerActions()
    const { setPsp } = useServicePartnersActions()
    const dispatchNotification = useDispatchNotification()

    const [customerAccount, setCustomerAccount] = useState({})
    const [errors, setErrors] = useState({})
    const [isEmailChecked, setIsEmailChecked] = useState(false)
    const [isFirstNamePrefilled, setIsFirstNamePrefilled] = useState(false)
    const [isFirstNameDisabled, setIsFirstNameDisabled] = useState(false)
    const [isLastNameDisabled, setIsLastNameDisabled] = useState(false)
    const [isLastNamePrefilled, setIsLastNamePrefilled] = useState(false)
    const [successfullyCheckedEmail, setSuccessfullyCheckedEmail] = useState('')
    const [consentsAgreed, setConsentsAgreed] = useState(null)
    const [existingVehicleCustomersId, setExistingVehicleCustomersId] = useState(null)
    const [existingVehicleCustomersEmail, setExistingVehicleCustomersEmail] = useState(null)
    const [modelName, setModelName] = useState('')
    const [isEmailRqInProgress, setIsEmailRqInProgress] = useState(false)
    const [isConsentEmailRqInProgress, setIsConsentEmailRqInProgress] = useState(false)

    const firstNameInputRef = useRef(null)
    const lastNameInputRef = useRef(null)
    const checkExistingVehicleHandleRef = useRef(null)

    const accountCheckHandle = useCallback(async () => {
        const accountsResponse = await Accounts.checkAccount(customerInformation.email)

        // MSP-51523 - Hide ident
        // hardcoded ident to false https://jira.skoda.vwgroup.com/browse/MSP-65206
        accountsResponse.data.skodaIdentAccount = false
        setCustomerAccount(accountsResponse.data)
        if (accountsResponse.data.skodaIdentAccount) {
            saveConsentVerificationField(consentsEnum.IDENT, true)
        }
        dispatch({
            type: SET_ACCOUNT_CHECK_DATA,
            payload: {
                accountCheck: accountsResponse.data,
            },
        })
        setIsEmailChecked(true)
        setSuccessfullyCheckedEmail(customerInformation.email)
        if (accountsResponse.data.connectAccount) {
            const getCustomerGroupIdData = async () => {
                try {
                    const response = await Customers.getCustomerGroupIdData(
                        customerInformation.email
                    )

                    const selectedCountry =
                        countries.find(
                            (item) => response.data.address?.countryId === item.id.toString()
                        ) ||
                        // fallback to dealer country
                        countries.find((item) => userInfo.country === item.countryCode)

                    dispatch({
                        type: ADD_CUSTOMER_INFO_FROM_GROUP_ID,
                        payload: {
                            customer: {
                                ...response.data,
                                email: response.data.email ?? '-',
                                firstName: response.data.firstName ?? '-',
                                lastName: response.data.lastName ?? '-',
                                middleName: response.data.middleName ?? '-',
                                nickname: response.data.nickname ?? '-',
                                phoneNumber: response.data.phoneNumber ?? '-',
                                address: {
                                    countryId: selectedCountry?.id,
                                },
                                preferredLanguage:
                                    selectedCountry?.languages &&
                                    selectedCountry.languages.includes(
                                        response.data.preferredLanguage
                                    )
                                        ? response.data.preferredLanguage
                                        : selectedCountry?.defaultLanguage || null,
                            },
                        },
                    })
                } catch (e) {
                    console.error(e)
                }
            }
            getCustomerGroupIdData(customerInformation.email)
            setIsFirstNamePrefilled(true)
            setIsLastNamePrefilled(true)
            setIsFirstNameDisabled(true)
            setIsLastNameDisabled(true)
        } else if (accountsResponse.data.cbaCustomerId) {
            const customerResponse = await Customers.getCustomer(
                accountsResponse.data.cbaCustomerId
            )
            dispatch({
                type: SET_CUSTOMER_DATA,
                payload: {
                    customer: {
                        ...customer,
                        ...customerResponse.data,
                        summaryData: customerResponse.data.consentVerification,
                        customerInformation: customerResponse.data.customerInformation,
                    },
                },
            })
            // customer exists in CBA therefore changing to add vehicle flow
            setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.addVehicle)
            setIsFirstNamePrefilled(true)
            setIsLastNamePrefilled(true)
            setIsFirstNameDisabled(true)
            setIsLastNameDisabled(true)
        } else {
            const countryId =
                userInfo.country &&
                countries.find((item) => userInfo.country === item.countryCode).id

            consentsDialogStatus !== CONSENTS_CREATE_DIALOG_STATUS_MAP.ocw &&
                resetCustomerWithData({
                    email: customerInformation.email,
                    address: {
                        countryId,
                    },
                    preferredLanguage: selectedLanguage.substring(0, 2),
                })
            setIsFirstNamePrefilled(false)
            setIsLastNamePrefilled(false)
            setIsFirstNameDisabled(false)
            setIsLastNameDisabled(false)
        }
    }, [
        consentsDialogStatus,
        countries,
        customer,
        customerInformation.email,
        dispatch,
        resetCustomerWithData,
        saveConsentVerificationField,
        selectedLanguage,
        userInfo.country,
    ])

    const onEmailSubmitHandler = async () => {
        setErrors({})
        const error = validateEmail(customerInformation.email)
        if (!error) {
            setSearchParam(null)
            setIsEmailRqInProgress(true)
            try {
                await accountCheckHandle()
            } catch (e) {
                console.error(e)
            }
            setIsEmailRqInProgress(false)
        } else {
            setErrors((prevState) => ({
                ...prevState,
                email: error,
            }))
        }
    }

    const onCloseHandler = () => {
        setConsentsCreateDialogStatus(
            consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.addVehicle
                ? CONSENTS_CREATE_DIALOG_STATUS_MAP.cancelAddVehicle
                : CONSENTS_CREATE_DIALOG_STATUS_MAP.cancel
        )
    }

    const getDataValidationResult = () =>
        validateCustomerWithConsents({
            consentVerification,
            email: customerInformation.email,
            firstName: customerInformation.firstName,
            lastName: customerInformation.lastName,
            vin: orderDetails.vin,
            commissionNumber: orderDetails.commissionNumber,
            orderNumber: orderDetails.orderNumber,
            preferredServicePartnerId: orderDetails.preferredServicePartnerId,
            preferredServicePartnerName: orderDetails.preferredServicePartnerName,
            preferredLanguage: customerInformation.preferredLanguage,
            countryId: customerInformation.address?.countryId,
        })

    const onPrintHandler = async () => {
        const validationResult = getDataValidationResult()
        setErrors(validationResult)
        if (isFormValid(validationResult)) {
            const locale = `${customerInformation.preferredLanguage}-${
                countries.find((country) => country.id === customerInformation.address.countryId)
                    ?.countryCode
            }`
            window.open(`/consent-documents/${locale}`, '_blank')
            try {
                const response = await Accounts.createConsents({
                    ...consentVerification,
                    email: customerInformation.email,
                    locale,
                    vin: orderDetails.vin,
                    commissionNumber: orderDetails.commissionNumber,
                    commissionNumberBid: userInfo.bid,
                    commissionNumberYear: orderDetails.commissionYear,
                    orderNumber: orderDetails.orderNumber,
                    preferredServicePartnerId: orderDetails.preferredServicePartnerId || null,
                    preferredServicePartnerName: orderDetails.preferredServicePartnerName || null,
                    consentType: CONSENT_TYPES_ENUM.paper,
                })

                setConsentId(response.data.consentId)
                setConsentsAgreed(true)
                setConsentType(CONSENT_TYPES_ENUM.paper)

                onProceedHandler()
            } catch (e) {
                console.error(e)
            }
        }
    }

    const onSendEmailHandler = async () => {
        const validationResult = getDataValidationResult()
        setErrors(validationResult)
        if (isFormValid(validationResult)) {
            setIsConsentEmailRqInProgress(true)
            try {
                const response = await Accounts.createConsents({
                    ...consentVerification,
                    email: customerInformation.email,
                    locale: `${customerInformation.preferredLanguage}-${
                        countries.find(
                            (country) => country.id === customerInformation.address.countryId
                        )?.countryCode
                    }`,
                    vin: orderDetails.vin,
                    commissionNumber: orderDetails.commissionNumber,
                    commissionNumberBid: userInfo.bid,
                    commissionNumberYear: orderDetails.commissionYear,
                    orderNumber: orderDetails.orderNumber,
                    preferredServicePartnerId: orderDetails.preferredServicePartnerId || null,
                    preferredServicePartnerName: orderDetails.preferredServicePartnerName || null,
                    consentType: CONSENT_TYPES_ENUM.digital,
                })

                setConsentId(response.data.consentId)

                dispatchNotification({
                    message: t('general.consentEmail.sent.success', { replace: ignoreKeyInHTML }),
                    type: NOTIFICATION_TYPE_SUCCESS,
                    duration: 3000,
                    canDismiss: true,
                })
                setConsentsAgreed(true)
                setConsentType(CONSENT_TYPES_ENUM.digital)

                onProceedHandler()
            } catch (e) {
                console.error(e)
                dispatchNotification({
                    message: t('general.consentEmail.sent.error', { replace: ignoreKeyInHTML }),
                    type: NOTIFICATION_TYPE_ERROR,
                    duration: 3000,
                    canDismiss: true,
                })
            }
            setIsConsentEmailRqInProgress(false)
        }
    }

    const emailChangeHandler = useCallback(
        (e) => {
            saveCustomerInformationField(e)
            if (e.target.value === successfullyCheckedEmail && e.target.value.length > 0) {
                setIsEmailChecked(true)
            } else {
                setIsEmailChecked(false)
            }
            dispatch({
                type: SET_EXPIRED_LICENSES,
                payload: {},
            })
        },
        [saveCustomerInformationField, successfullyCheckedEmail, dispatch]
    )

    const updateOrderDetailsHandle = (e) => {
        e.persist()
        saveOrderDetailsField(e.target.name, e.target.value)
    }

    const onProceedHandler = () => {
        allowAccessToCreateProcess()
        setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive)
        history.push('/customer-information')
    }

    const checkVinCallback = useCallback(
        async (vin) => {
            const vehicleCustomerResponse = await Vehicles.getVehicleCustomerByVin(vin)

            vehicleCustomerResponse?.data?.customerId &&
                setExistingVehicleCustomersId(vehicleCustomerResponse?.data?.customerId)
            vehicleCustomerResponse?.data?.email &&
                setExistingVehicleCustomersEmail(vehicleCustomerResponse?.data?.email)

            vehicleCustomerResponse?.data?.customerId &&
                vehicleCustomerResponse?.data?.email === customerInformation.email &&
                setErrors((prevState) => ({
                    ...prevState,
                    vin: t('general.order.vin.alreadyExisted'),
                }))

            vehicleCustomerResponse?.data?.customerId &&
                vehicleCustomerResponse?.data?.email !== customerInformation.email &&
                setErrors((prevState) => ({
                    ...prevState,
                    vin: t('general.order.vin.alreadyExistedAnotherCustomer'),
                }))
        },
        [customerInformation.email, t]
    )

    const onChangeCommissionNumberFullHandle = useCallback(
        async (commissionNumberFull) => {
            setExistingVehicleCustomersId(null)
            setErrors((prevState) => ({
                ...prevState,
                commissionNumber: null,
            }))
            checkExistingVehicleHandleRef.current &&
                window.clearTimeout(checkExistingVehicleHandleRef.current)
            if (/\d{6}-\d{3}-\d{4}/.test(commissionNumberFull)) {
                checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                    try {
                        const commissionNumberParts = commissionNumberFull.split('-')
                        const vehiclesResponse =
                            await Vehicles.getVehicleCustomerByFullCommissionNumber(
                                commissionNumberFull
                            )

                        vehiclesResponse?.data?.customerId &&
                            setExistingVehicleCustomersId(vehiclesResponse?.data?.customerId)
                        vehiclesResponse?.data?.email &&
                            setExistingVehicleCustomersEmail(vehiclesResponse?.data?.email)

                        vehiclesResponse?.data?.customerId &&
                            vehiclesResponse?.data?.email === customerInformation.email &&
                            setErrors((prevState) => ({
                                ...prevState,
                                commissionNumber: t('general.order.commission.alreadyExisted'),
                            }))

                        vehiclesResponse?.data?.customerId &&
                            vehiclesResponse?.data?.email !== customerInformation.email &&
                            setErrors((prevState) => ({
                                ...prevState,
                                commissionNumber: t(
                                    'general.order.commission.alreadyExistedAnotherCustomer'
                                ),
                            }))

                        const vinAndModelNameResponse =
                            await Vehicles.getVinAndModelNameByCommissionNumber(
                                // number, year, bid - switched params bid and year
                                commissionNumberParts[0],
                                commissionNumberParts[2],
                                commissionNumberParts[1]
                            )
                        if (vinAndModelNameResponse.data?.vin) {
                            saveOrderDetailsField('vin', vinAndModelNameResponse.data.vin)
                            await checkVinCallback(vinAndModelNameResponse.data?.vin)
                        }
                        vinAndModelNameResponse.data?.modelName &&
                            setModelName(vinAndModelNameResponse.data.modelName)
                    } catch (e) {
                        console.error(e)
                    }
                }, 500)
            }
        },
        [checkVinCallback, customerInformation.email, saveOrderDetailsField, t]
    )

    const onChangeVinHandle = useCallback(
        (vin) => {
            setExistingVehicleCustomersId(null)
            setModelName('')
            dispatch({
                type: SET_EXPIRED_LICENSES,
                payload: {},
            })
            setErrors((prevState) => ({
                ...prevState,
                vin: null,
            }))
            checkExistingVehicleHandleRef.current &&
                window.clearTimeout(checkExistingVehicleHandleRef.current)
            if (validateVin(vin) === null) {
                checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                    try {
                        await checkVinCallback(vin)
                        const modelNameResponse = await Vehicles.getModelNameByVin(vin)
                        modelNameResponse.data?.modelName &&
                            setModelName(modelNameResponse.data.modelName)
                        const expiredLicenses = await Vehicles.getExpiredLicensesByVin(
                            vin,
                            selectedLanguage.replace('-', '_')
                        )
                        dispatch({
                            type: SET_EXPIRED_LICENSES,
                            payload: expiredLicenses?.data || {},
                        })
                    } catch (e) {
                        console.error(e)
                    }
                }, 500)
            }
        },
        [checkVinCallback, selectedLanguage, dispatch]
    )

    const checkVehicleExistsByOrderNumber = useCallback(
        (orderNumber) => {
            setExistingVehicleCustomersId(null)
            setErrors((prevState) => ({
                ...prevState,
                orderNumber: null,
            }))
            checkExistingVehicleHandleRef.current &&
                window.clearTimeout(checkExistingVehicleHandleRef.current)
            if (orderNumber) {
                checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                    try {
                        const vehiclesResponse = await Vehicles.getVehicleCustomerByOrderNumber(
                            orderNumber
                        )

                        vehiclesResponse?.data?.customerId &&
                            setExistingVehicleCustomersId(vehiclesResponse?.data?.customerId)
                        vehiclesResponse?.data?.email &&
                            setExistingVehicleCustomersEmail(vehiclesResponse?.data?.email)

                        vehiclesResponse?.data?.customerId &&
                            vehiclesResponse?.data?.email === customerInformation.email &&
                            setErrors((prevState) => ({
                                ...prevState,
                                orderNumber: t('general.order.orderNo.alreadyExisted'),
                            }))

                        vehiclesResponse?.data?.customerId &&
                            vehiclesResponse?.data?.email !== customerInformation.email &&
                            setErrors((prevState) => ({
                                ...prevState,
                                orderNumber: t(
                                    'general.order.orderNo.alreadyExistedAnotherCustomer'
                                ),
                            }))
                    } catch (e) {
                        console.error(e)
                    }
                }, 500)
            }
        },
        [customerInformation.email, t]
    )

    useEffect(() => {
        if (
            customerInformation.email &&
            accountCheckedEmail !== customerInformation.email &&
            (consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.addVehicle ||
                consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.ocw)
        ) {
            setAccountCheckedEmail(customerInformation.email)
            accountCheckHandle()
        }
    }, [accountCheckHandle, consentsDialogStatus, customerInformation.email, accountCheckedEmail])

    useEffect(() => {
        !orderDetails.commissionYear && saveOrderDetailsField('commissionYear', year)
    }, [orderDetails.commissionYear, saveOrderDetailsField])

    useEffect(() => {
        consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.ocw &&
            dispatch(clearOcwRegistrationId())
    }, [consentsDialogStatus, dispatch])

    useEffect(() => {
        !isFirstNameDisabled && firstNameInputRef?.current && firstNameInputRef.current.focus()
    }, [isFirstNameDisabled])

    useEffect(() => {
        !isLastNameDisabled && lastNameInputRef?.current && lastNameInputRef.current.focus()
    }, [isLastNameDisabled])

    useEffect(() => {
        if (customer.skodaIdent?.licenseNumber) {
            saveConsentVerificationField(consentsEnum.IDENT, true)
        }
    }, [customer.skodaIdent.licenseNumber, saveConsentVerificationField])

    return (
        <>
            {/* fixed open(=true) prop since the visibility is controlled in parent component
            to force fresh dialog render */}
            <>
                {consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.cancel ||
                consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.cancelAddVehicle ? (
                    <CancelNewCustomerPopUp />
                ) : (
                    <Dialog open={true} maxWidth={'sm'} fullWidth onClose={onCloseHandler}>
                        <DialogTitle disableTypography>
                            <CloseCross onClick={onCloseHandler}>
                                <Close />
                            </CloseCross>
                            {t(
                                consentsDialogStatus ===
                                    CONSENTS_CREATE_DIALOG_STATUS_MAP.addVehicle
                                    ? 'general.button.addVehicle'
                                    : 'general.customer.create.popup.title'
                            )}
                        </DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth error={!!errors.email}>
                                <InputLabel>{t('general.customer.create.popup.email')}</InputLabel>
                                <Input
                                    name={'email'}
                                    value={customerInformation.email ?? ''}
                                    onChange={emailChangeHandler}
                                    disabled={
                                        consentsDialogStatus ===
                                        CONSENTS_CREATE_DIALOG_STATUS_MAP.addVehicle
                                    }
                                />
                                {errors.email && (
                                    <FormHelperText>
                                        {errors.email === INCORRECT_EMAIL ? (
                                            t(getErrorText(errors.email))
                                        ) : (
                                            <>
                                                {t('general.customer.create.popup.email')}{' '}
                                                {t(getErrorText(errors.email))}
                                            </>
                                        )}
                                    </FormHelperText>
                                )}
                                {isEmailChecked &&
                                    !customerAccount.groupIdAccount &&
                                    !customerAccount.cbaCustomerId &&
                                    !customerAccount.skodaIdentAccount && (
                                        <FormHelperText>
                                            {t('general.customer.account.newCustomer')}
                                        </FormHelperText>
                                    )}
                                {isEmailChecked &&
                                    customerAccount.groupIdAccount &&
                                    !customerAccount.cbaCustomerId &&
                                    !customerAccount.skodaIdentAccount && (
                                        <FormHelperText>
                                            {t('general.customer.account.hasGroupId')}
                                        </FormHelperText>
                                    )}
                                {isEmailChecked &&
                                    customerAccount.groupIdAccount &&
                                    customerAccount.cbaCustomerId &&
                                    !customerAccount.skodaIdentAccount && (
                                        <FormHelperText>
                                            {t('general.customer.account.hasCBAAndGroupId')}
                                        </FormHelperText>
                                    )}
                                {isEmailChecked &&
                                    customerAccount.groupIdAccount &&
                                    customerAccount.cbaCustomerId &&
                                    customerAccount.skodaIdentAccount && (
                                        <FormHelperText>
                                            {t('general.customer.account.hasCBAAndGroupIdAndIdent')}
                                        </FormHelperText>
                                    )}
                                {isEmailChecked &&
                                    !customerAccount.groupIdAccount &&
                                    customerAccount.cbaCustomerId &&
                                    !customerAccount.skodaIdentAccount && (
                                        <FormHelperText>
                                            {t('general.customer.account.hasCBA')}
                                        </FormHelperText>
                                    )}
                                {isEmailChecked &&
                                    customerAccount.groupIdAccount &&
                                    !customerAccount.cbaCustomerId &&
                                    customerAccount.skodaIdentAccount && (
                                        <FormHelperText>
                                            {t('general.customer.account.hasGroupIdAndIdent')}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                            {isEmailChecked && (
                                <>
                                    <TextField
                                        name={'firstName'}
                                        inputRef={firstNameInputRef}
                                        value={customerInformation.firstName ?? ''}
                                        label={t('general.customer.firstName')}
                                        InputLabelProps={{
                                            required: !isFirstNameDisabled,
                                        }}
                                        fullWidth
                                        error={!!errors.firstName}
                                        helperText={
                                            errors.firstName && (
                                                <>
                                                    {t('general.customer.firstName')}{' '}
                                                    {t(getErrorText(errors.firstName))}
                                                </>
                                            )
                                        }
                                        onChange={saveCustomerInformationField}
                                        disabled={isFirstNameDisabled}
                                        InputProps={
                                            isFirstNamePrefilled
                                                ? {
                                                      endAdornment: (
                                                          <InputAdornment position="end">
                                                              <IconButton
                                                                  onClick={() => {
                                                                      setIsFirstNameDisabled(
                                                                          !isFirstNameDisabled
                                                                      )
                                                                  }}
                                                              >
                                                                  <PencilIcon />
                                                              </IconButton>
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : undefined
                                        }
                                    />
                                    <TextField
                                        name={'lastName'}
                                        inputRef={lastNameInputRef}
                                        value={customerInformation.lastName ?? ''}
                                        label={t('general.customer.lastName')}
                                        InputLabelProps={{
                                            required: !isLastNameDisabled,
                                        }}
                                        fullWidth
                                        error={!!errors.lastName}
                                        helperText={
                                            errors.lastName && (
                                                <>
                                                    {t('general.customer.lastName')}{' '}
                                                    {t(getErrorText(errors.lastName))}
                                                </>
                                            )
                                        }
                                        onChange={saveCustomerInformationField}
                                        disabled={isLastNameDisabled}
                                        InputProps={
                                            isLastNamePrefilled
                                                ? {
                                                      endAdornment: (
                                                          <InputAdornment position="end">
                                                              <IconButton
                                                                  onClick={() => {
                                                                      setIsLastNameDisabled(
                                                                          !isLastNameDisabled
                                                                      )
                                                                  }}
                                                              >
                                                                  <PencilIcon />
                                                              </IconButton>
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : undefined
                                        }
                                    />
                                    <Grid container>
                                        <Grid item sm={6}>
                                            <TextField
                                                select
                                                name={'countryId'}
                                                onChange={(e) => {
                                                    saveCustomerInformationAddressItem(
                                                        'countryId',
                                                        e.target.value
                                                    )
                                                    const defaultCountryLanguage = countries.find(
                                                        (item) =>
                                                            e.target.value === item.id.toString()
                                                    )?.defaultLanguage
                                                    defaultCountryLanguage &&
                                                        saveCustomerInformationItem(
                                                            'preferredLanguage',
                                                            defaultCountryLanguage
                                                        )
                                                }}
                                                label={t('general.customer.country')}
                                                value={
                                                    customerInformation?.address?.countryId ||
                                                    defaultCountryId
                                                }
                                                error={!!errors?.countryId}
                                                helperText={
                                                    errors.countryId && (
                                                        <>
                                                            {t('general.customer.country')}{' '}
                                                            {t(getErrorText(errors.countryId))}
                                                        </>
                                                    )
                                                }
                                                required
                                            >
                                                {[
                                                    {
                                                        value: '0',
                                                        label: t('general.select.choose'),
                                                        disabled: true,
                                                    },
                                                    ...countries
                                                        .map((c) => ({
                                                            value: c.id,
                                                            label: t(
                                                                `general.country.${c.countryCode}`
                                                            ),
                                                            countryNameTranslated: t(
                                                                `general.country.${c.countryCode}`,
                                                                {
                                                                    replace: ignoreKeyInHTML,
                                                                }
                                                            ),
                                                        }))
                                                        .sort((a, b) =>
                                                            a.countryNameTranslated.localeCompare(
                                                                b.countryNameTranslated,
                                                                selectedLanguage.substring(0, 2)
                                                            )
                                                        ),
                                                ].map((item) => (
                                                    <MenuItem
                                                        value={item.value}
                                                        key={item.value}
                                                        disabled={item.disabled}
                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item sm={6}>
                                            {!!(
                                                customerInformation?.address?.countryId && countries
                                            ) && (
                                                <TextField
                                                    select
                                                    name={'preferredLanguage'}
                                                    onChange={saveCustomerInformationField}
                                                    label={t('general.customer.preferredLanguage')}
                                                    value={
                                                        customerInformation.preferredLanguage || ''
                                                    }
                                                    error={!!errors.preferredLanguage}
                                                    helperText={
                                                        errors.preferredLanguage && (
                                                            <>
                                                                {t(
                                                                    'general.customer.preferredLanguage'
                                                                )}{' '}
                                                                {t(
                                                                    getErrorText(
                                                                        errors.preferredLanguage
                                                                    )
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                    required
                                                >
                                                    {[
                                                        {
                                                            value: '',
                                                            label: t('general.select.choose'),
                                                            disabled: true,
                                                        },
                                                        ...(countries
                                                            .find(
                                                                (country) =>
                                                                    country.id.toString() ===
                                                                    customerInformation.address.countryId.toString()
                                                            )
                                                            ?.languages?.map((item) => ({
                                                                value: item.toString(),
                                                                label: t(
                                                                    `general.language.${item}`
                                                                ),
                                                            })) || []),
                                                    ].map((item) => (
                                                        <MenuItem
                                                            value={item.value}
                                                            key={item.value}
                                                            disabled={item.disabled}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        name={'vin'}
                                        value={orderDetails.vin ?? ''}
                                        label={t('general.order.vin')}
                                        InputLabelProps={{
                                            required:
                                                !orderDetails.orderNumber &&
                                                !orderDetails.commissionNumber,
                                        }}
                                        fullWidth
                                        error={!!errors.vin}
                                        helperText={
                                            errors.vin && errorTextMap[errors.vin] ? (
                                                <>
                                                    {t('general.order.vin')}{' '}
                                                    {t(errorTextMap[errors.vin])}
                                                </>
                                            ) : (
                                                errors.vin || modelName || null
                                            )
                                        }
                                        onChange={(e) => {
                                            updateOrderDetailsHandle(e)
                                            onChangeVinHandle(e.target.value.toUpperCase())
                                        }}
                                    />
                                    <Grid container spacing={1} wrap={'nowrap'}>
                                        <Grid item md={2}>
                                            <FormControl error={!!errors.commissionNumber}>
                                                <InputLabel
                                                    style={{ whiteSpace: 'nowrap' }}
                                                    required={
                                                        !orderDetails.vin &&
                                                        !orderDetails.orderNumber
                                                    }
                                                >
                                                    {t('general.order.commission')}
                                                </InputLabel>

                                                <Input
                                                    name={'commissionNumber'}
                                                    value={orderDetails.commissionNumber ?? ''}
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 6,
                                                    }}
                                                    style={{
                                                        letterSpacing: '1px',
                                                    }}
                                                    error={!!errors.commissionNumber}
                                                    onChange={(e) => {
                                                        saveOrderDetailsField(
                                                            'commissionNumber',
                                                            e.target.value
                                                        )
                                                        onChangeCommissionNumberFullHandle(
                                                            `${e.target.value}-${userInfo.bid}-${orderDetails.commissionYear}`
                                                        )
                                                    }}
                                                    placeholder={'______'}
                                                    disabled={!!orderDetails.vin}
                                                />
                                            </FormControl>
                                            {errors.commissionNumber && (
                                                <FormHelperText
                                                    style={{ whiteSpace: 'nowrap' }}
                                                    error
                                                >
                                                    {errorTextMap[errors.commissionNumber] ? (
                                                        <>
                                                            {t('general.order.commission')}{' '}
                                                            {t(
                                                                errorTextMap[
                                                                    errors.commissionNumber
                                                                ]
                                                            )}
                                                        </>
                                                    ) : (
                                                        errors.commissionNumber || null
                                                    )}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item md={1}>
                                            <FormControl error={!!errors.commissionNumber}>
                                                <InputLabel></InputLabel>
                                                <Input
                                                    name={'bid'}
                                                    value={userInfo.bid ?? ''}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl error={!!errors.commissionNumber}>
                                                <InputLabel></InputLabel>
                                                <Select
                                                    name={'commission-year'}
                                                    value={orderDetails.commissionYear || ''}
                                                    fullWidth
                                                    onChange={(e) => {
                                                        saveOrderDetailsField(
                                                            'commissionYear',
                                                            e.target.value
                                                        )
                                                        onChangeCommissionNumberFullHandle(
                                                            `${orderDetails.commissionNumber}-${userInfo.bid}-${e.target.value}`
                                                        )
                                                    }}
                                                    disabled={!!orderDetails.vin}
                                                >
                                                    <MenuItem value={year + 1}>{year + 1}</MenuItem>
                                                    <MenuItem value={year}>{year}</MenuItem>
                                                    <MenuItem value={year - 1}>{year - 1}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        name={'orderNumber'}
                                        value={orderDetails.orderNumber ?? ''}
                                        label={t('general.order.orderNo')}
                                        InputLabelProps={{
                                            required:
                                                !orderDetails.vin && !orderDetails.commissionNumber,
                                        }}
                                        fullWidth
                                        error={!!errors.orderNumber}
                                        helperText={
                                            errors.orderNumber &&
                                            errorTextMap[errors.orderNumber] ? (
                                                <>
                                                    {t('general.order.orderNo')}{' '}
                                                    {t(errorTextMap[errors.orderNumber])}
                                                </>
                                            ) : (
                                                errors.orderNumber || null
                                            )
                                        }
                                        onChange={(e) => {
                                            saveOrderDetailsField('orderNumber', e.target.value)
                                            checkVehicleExistsByOrderNumber(e.target.value)
                                        }}
                                        disabled={
                                            !!orderDetails.vin || !!orderDetails.commissionNumber
                                        }
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel>{t('general.consents.title')}</InputLabel>
                                    </FormControl>
                                    <Box>
                                        <FormControlLabel
                                            style={{ marginTop: '18px' }}
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!consentVerification?.[
                                                            consentsEnum.CONNECT_SERVICES
                                                        ]
                                                    }
                                                    onChange={(e, checked) =>
                                                        saveConsentVerificationField(
                                                            e.target.name,
                                                            checked
                                                        )
                                                    }
                                                    name={consentsEnum.CONNECT_SERVICES}
                                                />
                                            }
                                            label={t('general.consents.checkbox.services')}
                                        />
                                    </Box>
                                    {/* MSP-51523 - Hide ident */}
                                    {/*<Box>*/}
                                    {/*    <FormControlLabel*/}
                                    {/*        control={*/}
                                    {/*            <Checkbox*/}
                                    {/*                disabled={*/}
                                    {/*                    customerAccount.skodaIdentAccount ||*/}
                                    {/*                    !!customer.skodaIdent?.licenseNumber*/}
                                    {/*                }*/}
                                    {/*                checked={*/}
                                    {/*                    !!consentVerification?.[consentsEnum.IDENT]*/}
                                    {/*                }*/}
                                    {/*                onChange={(e, checked) =>*/}
                                    {/*                    saveConsentVerificationField(*/}
                                    {/*                        e.target.name,*/}
                                    {/*                        checked*/}
                                    {/*                    )*/}
                                    {/*                }*/}
                                    {/*                name={consentsEnum.IDENT}*/}
                                    {/*            />*/}
                                    {/*        }*/}
                                    {/*        label={t('general.consents.checkbox.skodaIdent')}*/}
                                    {/*    />*/}
                                    {/*</Box>*/}
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!consentVerification?.[consentsEnum.PSP]
                                                    }
                                                    onChange={(e, checked) =>
                                                        saveConsentVerificationField(
                                                            e.target.name,
                                                            checked
                                                        )
                                                    }
                                                    name={consentsEnum.PSP}
                                                />
                                            }
                                            label={t('general.consents.checkbox.psp')}
                                        />
                                    </Box>
                                    {consentVerification?.[consentsEnum.PSP] && (
                                        <ServicePartnerSearchInput
                                            orderDetails={orderDetails}
                                            setPsp={setPsp}
                                            errors={errors}
                                            editMode={true}
                                            disabled={false}
                                        />
                                    )}
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            {!isEmailChecked && (
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={onEmailSubmitHandler}
                                    disabled={!customerInformation.email || isEmailRqInProgress}
                                >
                                    {t('general.customer.create.popup.button')}
                                </Button>
                            )}
                            {isEmailChecked && !consentsAgreed && !existingVehicleCustomersId && (
                                <>
                                    <Button
                                        variant={'outlined'}
                                        onClick={onPrintHandler}
                                        disabled={
                                            !consentVerification?.[consentsEnum.CONNECT_SERVICES] ||
                                            isConsentEmailRqInProgress ||
                                            (!orderDetails.vin &&
                                                !orderDetails.commissionNumber &&
                                                !orderDetails.orderNumber) ||
                                            !customerInformation.firstName ||
                                            !customerInformation.lastName ||
                                            !customerInformation.preferredLanguage
                                        }
                                    >
                                        {t('general.button.print')}
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={onSendEmailHandler}
                                        disabled={
                                            !consentVerification?.[consentsEnum.CONNECT_SERVICES] ||
                                            isConsentEmailRqInProgress ||
                                            (!orderDetails.vin &&
                                                !orderDetails.commissionNumber &&
                                                !orderDetails.orderNumber) ||
                                            !customerInformation.firstName ||
                                            !customerInformation.lastName ||
                                            !customerInformation.preferredLanguage
                                        }
                                    >
                                        {t('general.button.sendConsent')}
                                    </Button>
                                </>
                            )}
                            {isEmailChecked &&
                                existingVehicleCustomersId &&
                                existingVehicleCustomersEmail === customerInformation.email && (
                                    <Box mt={3}>
                                        <Button
                                            color={'primary'}
                                            variant={'contained'}
                                            onClick={() => {
                                                clearCustomer()
                                                setConsentsCreateDialogStatus(
                                                    CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive
                                                )
                                                history.push(
                                                    `/customers/${existingVehicleCustomersId}`
                                                )
                                            }}
                                        >
                                            {t('general.customer.goToVehicleDetail')}
                                        </Button>
                                    </Box>
                                )}
                        </DialogActions>
                    </Dialog>
                )}
            </>
        </>
    )
}

export default ConsentsCreateDialog

import React from 'react'
import styled from 'styled-components'
import tooltipIcon from '../../assets/images/tooltip.svg'
import Tooltip from '@material-ui/core/Tooltip'
import './theme.scss'

const Box = styled.div`
    font-size: 14px;
    padding: 10px;
    font-weight: 300;
    line-height: 1.43;
    color: #fff;
`

const CustomTooltip = styled(Tooltip)`
     {
        ${({ alignv }) => !alignv && 'margin-bottom: 4px;'}
    }
     {
        ${({ alignv }) => alignv === 'center' && 'align-self: center;'}
    }
    max-width: 500px;
    cursor: pointer;

    tooltip: {
        font-size: 14px;
        padding: 10px;
        font-weight: 300;
        line-height: 1.43;
        color: yellow;
    }
`

const Icon = styled.img`
    width: 24px;
    height: 24px;
     {
        ${({ size }) =>
            size &&
            size === 'small' &&
            `
        width: 18px;
        height: 18px;
    `}
    }
     {
        ${({ size }) =>
            size &&
            size === 'big' &&
            `
        width: 45px;
        height: 45px;
    `}
    }
`

export default function InputTooltip({ text, size, alignv }) {
    return (
        <CustomTooltip
            title={<Box>{text}</Box>}
            placement="top"
            classes={{
                tooltip: 'tooltip-box__wrapper',
            }}
            alignv={alignv}
        >
            <Icon src={tooltipIcon} alt="" size={size} />
        </CustomTooltip>
    )
}

import { SET_ACCOUNT_CHECK_DATA, CLEAR_ACCOUNT_CHECK_DATA } from '../types/reduxActionTypes'

const initialState = {
    accountCheck: null,
}

const userSignedOutAction = 'oidc/userSignedOut'

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT_CHECK_DATA:
            return {
                ...state,
                accountCheck: action.payload.accountCheck,
            }
        case CLEAR_ACCOUNT_CHECK_DATA:
            return {
                ...state,
                accountCheck: null,
            }
        case userSignedOutAction:
            return initialState
        default:
            return state
    }
}

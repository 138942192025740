import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SortableTable from '../../components/SortableTable'
import TabNavigation from '../../components/TabNavigation'
import Page from '../../components/Wrappers/Page'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/Loading'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import { Commissions } from '../../api'

const FilterRow = styled.div`
    display: flex;
    position: relative;
`
const NoItemsFound = styled.div`
    margin-top: 50px;
    font-size: 18px;
    text-align: center;
`

const tabs = [
    {
        label: 'general.customers.tabs.all',
        tooltip: 'general.customers.tabs.all.tooltip',
        name: 'all',
    },
    {
        label: 'general.customers.tabs.missingCommission',
        tooltip: 'general.customers.tabs.missingCommission.tooltip',
        name: 'missing_commission',
    },
    {
        label: 'general.customers.tabs.incomplete',
        tooltip: 'general.customers.tabs.incomplete.tooltip',
        name: 'incomplete',
    },
    {
        label: 'general.customers.tabs.failures',
        tooltip: 'general.customers.tabs.failures.tooltip',
        name: 'failed',
        suffixError: 'failedCommissionsCount',
    },
]

function CommissionsList() {
    const { searchParam } = useSelector((state) => state.commissions)
    const [commissions, setCommissions] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const [isCommissionsLoading, setIsCommissionsLoading] = useState(false)
    const [failedCommissionsCount, setFailedCommissionsCount] = useState(0)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const title =
        searchParam === null ? (
            t('general.header.dealershipCustomers')
        ) : (
            <>
                {t('general.dealershipCustomers.searchResults')} "{searchParam}"
            </>
        )

    useEffect(() => {
        const init = async () => {
            setIsCommissionsLoading(true)
            try {
                const response = searchParam
                    ? await Commissions.searchCommissions(searchParam)
                    : await Commissions.getCommissions({ tabName: tabs[activeTab].name })
                response?.data?.commissionList
                    ? setCommissions(
                          response.data.commissionList.map((item) => ({
                              ...item,
                              customerStatus: item.customerStatus.status,
                              vehicleStatus: item.vehicleStatus.status,
                              customerStatusFailed: item.customerStatus.failed,
                              vehicleStatusFailed: item.vehicleStatus.failed,
                          }))
                      )
                    : setCommissions([])
                setFailedCommissionsCount(response?.data?.failedCommissionsCount || 0)
            } catch (e) {
                console.error(e)
            }
            setIsCommissionsLoading(false)
        }
        init()
    }, [activeTab, searchParam])

    return (
        <ScrollTop>
            <Page title={title}>
                {searchParam === null && (
                    <FilterRow>
                        <TabNavigation
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            tabs={tabs}
                            errorSuffixes={{ failedCommissionsCount }}
                        />
                    </FilterRow>
                )}
                {isCommissionsLoading ? (
                    <Loading />
                ) : commissions && commissions.length ? (
                    <SortableTable
                        data={commissions.map((item) => ({
                            ...item,
                            customerStatus: item.customerStatus
                                ? `general.statuses.customer.${item.customerStatus}`
                                : item.customerStatus,
                            vehicleStatus: item.vehicleStatus
                                ? `general.statuses.vehicle.${item.vehicleStatus}`
                                : item.vehicleStatus,
                        }))}
                    />
                ) : (
                    <NoItemsFound>{t('general.dealershipCustomers.noItemsFound')}</NoItemsFound>
                )}
            </Page>
        </ScrollTop>
    )
}

export default WithHeader(CommissionsList)

import { SET_SEARCH_PARAM } from '../types/reduxActionTypes'

const initialState = {
    searchParam: null,
}

export const commissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_PARAM:
            return {
                ...state,
                searchParam: action.payload.value,
            }
        default:
            return state
    }
}

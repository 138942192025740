import React from 'react'
import styled from 'styled-components'
import LanguageSelector from './LanguageSelector'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { checkUrl } from '../util/util'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'
import { useSelector } from 'react-redux'

const Root = styled.div`
    display: flex;
    height: 55px;
    min-height: 55px;
    padding: 0 30px;
    background-color: #4a4a4a;
    align-items: center;
`
const LeftSide = styled.div`
    display: flex;
    flex: 1 0 auto;
`
const RightSide = styled.div`
    flex: 1 0 auto;
    justify-content: flex-end;
    display: flex;
`
const Link = styled.div`
    margin-right: 40px;
    color: #c0c0c0;
    font-size: 12px;
    cursor: pointer;
`

const Element = styled.div`
    margin-right: 40px;
    color: #c0c0c0;
    font-size: 12px;
    cursor: default;
`

export default function Footer() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { selectedLanguage } = useSelector((state) => state.options)

    const checkAndShowPopupForHome = (url) => {
        if (!checkUrl(url)) {
            history.push({
                pathname: url,
            })
        }
    }

    const openImprint = () => {
        const lang = selectedLanguage.substring(0, 2)
        window.open(
            // TODO better to use .env config (dev-ops involvement required)
            window.location.hostname === 'cba.skoda-connect.com'
                ? `https://consent.vwgroup.io/consent/v1/texts/consent/cz/${lang}/skoda-imprint/latest/html`
                : `https://consent-sandbox.vwgroup.io/consent/v1/texts/consent/cz/${lang}/skoda-imprint/latest/html`,
            '_blank'
        )
    }

    const openTermsOfUse = () => {
        checkAndShowPopupForHome('/terms-of-use')
    }

    const openLegal = () => {
        checkAndShowPopupForHome('/legal')
    }

    return (
        <div className="noprint">
            <Root>
                <LeftSide>
                    <Element>{'© ŠKODA AUTO a.s. ' + new Date().getUTCFullYear()}</Element>
                    <Link onClick={openImprint}>{t('general.footer.imprint')}</Link>
                    <Link onClick={openTermsOfUse}>{t('general.footer.termsOfUse')}</Link>
                    <Link onClick={openLegal}>{t('general.footer.legal')}</Link>
                </LeftSide>
                <RightSide>
                    <LanguageSelector />
                </RightSide>
            </Root>
        </div>
    )
}

import React from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const LabelWithTooltip = ({ labelText, tooltipText }) => {
    return (
        <Grid container alignItems={'center'} spacing={1} wrap={'nowrap'}>
            <Grid item>{labelText}</Grid>
            {tooltipText && (
                <Grid item>
                    <Tooltip title={tooltipText}>
                        <InfoOutlinedIcon color={'primary'} fontSize={'small'} />
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    )
}

export default LabelWithTooltip

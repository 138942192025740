import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { rqStatusEnum, TRANSLATIONS_NAMESPACE } from '../constants/global'
import { Vehicles } from '../api'
import {
    somethingWentWrongErrorMessage,
    vehicleDeletedSuccessMessage,
} from '../types/reduxNotificationTypes'
import { useDispatchNotification } from '../hooks/useDispatchNotification'
import Popup from './PopUp/PopUp'
import { WarningIcon } from './Icons/WarningIcon'
import { Box, Button, Typography } from '@material-ui/core'

export const VehicleRemoveDialog = ({
    isVehicleRemoveDialogOpenWithConsentId,
    setIsVehicleRemoveDialogOpenWithVehicleId,
    getVehicles,
    getStatusesData,
    customerId,
}) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const [rqStatus, setRqStatus] = useState(rqStatusEnum.INITIAL)
    const dispatchNotification = useDispatchNotification()

    const onCloseHandle = () => {
        setIsVehicleRemoveDialogOpenWithVehicleId(-1)
        setRqStatus(rqStatusEnum.INITIAL)
    }

    const onSubmitHandler = async () => {
        setRqStatus(rqStatusEnum.IN_PROGRESS)
        try {
            await Vehicles.deleteVehicleData(isVehicleRemoveDialogOpenWithConsentId)
            getVehicles(customerId)
            getStatusesData(customerId)
            dispatchNotification(vehicleDeletedSuccessMessage)
            onCloseHandle()
        } catch (e) {
            console.error(e)
            dispatchNotification(somethingWentWrongErrorMessage)
            setRqStatus(rqStatusEnum.ERROR)
        }
    }

    return (
        <Popup
            onClose={onCloseHandle}
            body={
                <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
                    <WarningIcon width={56} />
                    <Box mt={3}>
                        <Typography variant={'h3'} align={'center'}>
                            {t('general.vehicle.deleteVehicle.confirmDeleteContent')}
                        </Typography>
                    </Box>
                </Box>
            }
            title={t('general.vehicle.deleteVehicle.confirmDeleteTitle')}
            actions={
                <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={onSubmitHandler}
                        disabled={rqStatus === rqStatusEnum.IN_PROGRESS}
                    >
                        {t('general.customer.create.popup.yes')}
                    </Button>
                    <Box mt={2}>
                        <Button
                            style={{ minWidth: '140px' }}
                            variant={'outlined'}
                            onClick={onCloseHandle}
                            disabled={rqStatus === rqStatusEnum.IN_PROGRESS}
                        >
                            {t('general.customer.create.popup.no')}
                        </Button>
                    </Box>
                </Box>
            }
            open={isVehicleRemoveDialogOpenWithConsentId > 0}
        />
    )
}

export const CUSTOMER_INFORMATION_STEP = 0
export const SKODA_IDENT_STEP = 1
export const VEHICLE_DETAILS_STEP = 2
export const CONSENT_VERIFICATION_STEP = 3

export const NAVIGATION_BUTTONS_CONFIGURATION = {
    0: {
        isCancel: true,
        buttonLabelKey: 'general.button.continue.ident',
        nextButtonUrl: '/skoda-ident',
    },
    1: {
        isBack: true,
        isSkip: true,
        buttonLabelKey: 'general.button.continue.order',
        backButtonUrl: '/customer-information',
        nextButtonUrl: '/vehicle-details',
    },
    2: {
        isBack: true,
        buttonLabelKey: 'general.button.continue.consents',
        backButtonUrl: '/skoda-ident',
        nextButtonUrl: '/consent-verification',
    },
    3: {
        buttonLabelKey: 'general.button.consents.create.customer',
        isCancelCustomer: true,
        nextButtonUrl: '/',
        backButtonUrl: '/vehicle-details',
    },
}

import React, { useState } from 'react'
import styled from 'styled-components'
import avatar from '../../assets/images/avatar.svg'
import DealerProfilePopover from '../DealerProfilePopover'

const Avatar = styled.div`
    cursor: pointer;
`

export function DealerAvatar() {
    const [anchorEl, setAnchorEl] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Avatar onClick={handleClick}>
                <img src={avatar} alt="Profile" />
            </Avatar>
            <DealerProfilePopover onClose={handleClose} anchorEl={anchorEl} />
        </>
    )
}

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import {
    closeCancelNewCustomer,
    disallowAccessToCreateProcess,
    onCancelNewCustomerConfirm,
} from '../../../actions/popupsActions'
import React from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import PopUp from '../PopUp'
import { useSaveInputChange } from '../../../hooks/form/useSaveInputChange'
import { CONSENTS_CREATE_DIALOG_STATUS_MAP, isRegistrationProcess } from '../../../util/util'
import { Box, Button, Typography } from '@material-ui/core'

export default function CancelNewCustomerPopUp() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { setSearchParam } = useSaveInputChange()
    const { consentsDialogStatus } = useSelector((state) => state.popups)

    const onCancelNewCustomer = () => {
        disallowAccessToCreateProcess()
        onCancelNewCustomerConfirm()
        setSearchParam(null)
        if (isRegistrationProcess()) {
            history.push({
                pathname: '/customers',
            })
        }
    }

    const content = (
        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <WarningIcon width={56} />
            <Box mt={3}>
                <Typography variant={'h3'} align={'center'}>
                    {t(
                        consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.cancelAddVehicle
                            ? 'general.vehicle.create.popup.exit'
                            : 'general.customer.create.popup.exit'
                    )}
                </Typography>
            </Box>
        </Box>
    )

    const actions = (
        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <Button color={'primary'} variant={'contained'} onClick={onCancelNewCustomer}>
                {t('general.customer.create.popup.yes')}
            </Button>
            <Box mt={2}>
                <Button
                    style={{ minWidth: '140px' }}
                    variant={'outlined'}
                    onClick={() => closeCancelNewCustomer(consentsDialogStatus)}
                >
                    {t('general.customer.create.popup.no')}
                </Button>
            </Box>
        </Box>
    )

    const config = {
        title: t(
            consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.cancelAddVehicle
                ? 'general.vehicle.cancel.popup.title'
                : 'general.customer.cancel.popup.title'
        ),
        actions: actions,
        body: content,
    }
    return (
        <PopUp
            onClose={() => closeCancelNewCustomer(consentsDialogStatus)}
            open={true}
            body={config.body}
            title={config.title}
            actions={config.actions}
        />
    )
}

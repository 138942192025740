import { NONE } from '../constants/defaultFormValues'
import { countryPhoneCodes } from '../constants/countryPhoneCodesConfiguration'
import { consentsEnum } from '../constants/global'
import { isValidPhoneNumber } from 'libphonenumber-js/max'

export const EMPTY = 'empty'
export const NOT_NUMBER = 'isNotNumber'
export const INVALID = 'invalid'
export const INVALID_DATE = 'invalid_date'
export const INVALID_PHONE = 'invalid_phone'
export const INCORRECT_EMAIL = 'incorrect_email'
export const PAST = 'past'
export const FUTURE = 'future'
export const DATE_OF_BIRTH = 'date_of_birth'
export const PSP = 'psp'
export const ONE_OF_DATES = 'ONE_OF_DATES'

export const errorTextMap = {
    [EMPTY]: 'general.validation.empty',
    [INVALID]: 'general.validation.invalid',
    [NOT_NUMBER]: 'general.validation.isNotNumber',
    [PAST]: 'general.validation.past',
    [FUTURE]: 'general.validation.future',
    [DATE_OF_BIRTH]: 'general.validation.birth',
    [ONE_OF_DATES]: 'general.validation.2dates',
    [PSP]: 'general.validation.psp',
    [INVALID_DATE]: 'general.validation.invalid',
    [INVALID_PHONE]: 'general.validation.phone',
    [INCORRECT_EMAIL]: 'general.customer.create.popup.incorrectEmail',
}

export const getErrorText = (errorCode) => {
    return errorTextMap[errorCode] || undefined
}

export const validateLanguage = (language) => {
    return language && language !== NONE ? null : EMPTY
}

export const validateEmail = (value) => {
    if (!value) {
        return EMPTY
    }
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return re.test(String(value).toLowerCase()) ? null : INCORRECT_EMAIL
}

export const validateVin = (value) => {
    if (!value) {
        return EMPTY
    }
    const re = new RegExp('^[A-HJ-NPR-Z0-9]{13}[0-9]{4}$')
    return re.test(value) ? null : INVALID
}

const validateSelect = (value, isMandatory) => {
    if (!isMandatory) {
        return null
    }
    if (!value) {
        return EMPTY
    }
    return parseInt(value) > 0 ? null : INVALID
}

const validateCountryCode = (value, isMandatory) => {
    if (value === null || value === undefined) {
        return isMandatory ? EMPTY : null
    }
    return countryPhoneCodes.find((c) => c.code === value) ? null : INVALID
}

const validateString = (value, minLength = 1) => {
    if (!value) {
        return EMPTY
    }
    return value.length >= minLength ? null : INVALID
}

const validateNumbers = (value, minLength, maxLength) => {
    if (!value) {
        return EMPTY
    }
    const re =
        minLength !== undefined
            ? new RegExp('^[0-9]{' + minLength + ',' + maxLength + '}$')
            : /^[0-9]*$/
    return re.test(String(value).toLowerCase()) ? null : NOT_NUMBER
}

// checks validity of phone number including country dial code prefix
// regex is not sufficient - we need real numbers validation
// because external systems require an existing number
const validatePhone = (phoneNumberWithPrefix) => {
    if (!phoneNumberWithPrefix) {
        return null
    }

    return isValidPhoneNumber(phoneNumberWithPrefix) ? null : INVALID_PHONE
}

export function CustomerInformationFormValidator(customerInformation) {
    return {
        email: validateEmail(customerInformation.email),
        firstName: validateString(customerInformation.firstName),
        lastName: validateString(customerInformation.lastName),
        nickname: validateString(customerInformation.nickname),
        dialCode:
            customerInformation.phoneNumber || customerInformation.dialCode
                ? validateString(customerInformation.dialCode)
                : null,
        phoneNumber: customerInformation.phoneNumber
            ? validatePhone(`${customerInformation.dialCode}${customerInformation.phoneNumber}`)
            : null,
        address: {
            countryId: validateSelect(customerInformation?.address?.countryId, true),
        },
        preferredLanguage: validateLanguage(customerInformation.preferredLanguage),
    }
}

export function SkodaIdentFormValidator(skodaIdent) {
    return {
        licenseNumber: validateString(skodaIdent.licenseNumber),
        countryOfIssueCode: validateCountryCode(skodaIdent.countryOfIssueCode, true),
    }
}

export function OrderDetailsFormValidator(orderDetails) {
    return {
        vin:
            !orderDetails.vin && (orderDetails.orderNumber || orderDetails.commissionNumber)
                ? null
                : validateVin(orderDetails.vin),
        commissionNumber:
            !orderDetails.commissionNumber && (orderDetails.vin || orderDetails.orderNumber)
                ? null
                : validateNumbers(orderDetails.commissionNumber, 6, 6),
        orderNumber:
            !orderDetails.orderNumber && (orderDetails.vin || orderDetails.commissionNumber)
                ? null
                : validateString(orderDetails.orderNumber),
    }
}

export const validateCustomerInformation = (data) => {
    return CustomerInformationFormValidator(data)
}

export const validateCustomerWithConsents = (data) => {
    return {
        email: validateEmail(data.email),
        firstName: validateString(data.firstName),
        lastName: validateString(data.lastName),
        vin:
            !data.vin && (data.orderNumber || data.commissionNumber) ? null : validateVin(data.vin),
        commissionNumber:
            !data.commissionNumber && (data.vin || data.orderNumber)
                ? null
                : validateNumbers(data.commissionNumber, 6, 6),
        orderNumber:
            !data.orderNumber && (data.vin || data.commissionNumber)
                ? null
                : validateString(data.orderNumber),
        psp:
            data.consentVerification[consentsEnum.PSP] &&
            (!data.preferredServicePartnerId || !data.preferredServicePartnerName)
                ? EMPTY
                : null,
        preferredLanguage: validateString(data.preferredLanguage),
        countryId: validateSelect(data.countryId, true),
    }
}

export const validateVehicleDetails = (data) => {
    return {
        vin:
            !data.vin && (data.orderNumber || data.commissionNumber) ? null : validateVin(data.vin),
        commissionNumber:
            !data.commissionNumber && (data.vin || data.orderNumber)
                ? null
                : validateNumbers(data.commissionNumber, 6, 6),
        orderNumber:
            !data.orderNumber && (data.vin || data.commissionNumber)
                ? null
                : validateString(data.orderNumber),
        consentLanguage: validateString(data.consentLanguage),
        psp:
            data.consents[consentsEnum.PSP] &&
            (!data.preferredServicePartnerId || !data.preferredServicePartnerName)
                ? EMPTY
                : null,
    }
}

export const isIdentValid = (validationErrors) =>
    validationErrors.licenseNumber === null && validationErrors.countryOfIssueCode === null

export const isCustomerInformationValid = (validationErrors) => {
    const customerInformationProperties = [
        'email',
        'firstName',
        'lastName',
        'nickname',
        'phoneNumber',
        'preferredLanguage',
    ]

    return (
        customerInformationProperties.every((item) => validationErrors[item] === null) &&
        validationErrors.address.countryId === null
    )
}

import React from 'react'
import styled from 'styled-components'
import info from '../../assets/images/info.svg'

const Icon = styled.div`
    background-color: #4ba82e;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
`

export function InfoIcon() {
    return (
        <Icon>
            <img src={info} width={30} alt="info" />
        </Icon>
    )
}

import React from 'react'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'

const Link = styled.a`
    color: #808285;
`

const LinkWrapper = styled.div`
    margin-right: 15px;
`

export function UserManualLink() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    return (
        <LinkWrapper>
            <Link
                href={t('general.header.userManualLink', { replace: ignoreKeyInHTML })}
                title={t('general.header.userManual', { replace: ignoreKeyInHTML })}
                target={'_blank'}
            >
                <MenuBookIcon />
            </Link>
        </LinkWrapper>
    )
}

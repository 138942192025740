import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const defaultTheme = createTheme()

let muiTheme = createTheme({
    palette: {
        primary: { main: '#4ba82e' },
        secondary: { main: '#4d4d4d' },
        fontFamily: '"SKODA Next CBA", Arial, Helvetica, sans-serif',
        text: { secondary: '#4b4a4b' },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'SKODA Next CBA, Arial, Helvetica, sans-serif',
        h1: {
            fontSize: 50,
        },
        h2: {
            fontSize: 36,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 28,
            fontWeight: 'bold',
        },
        h4: {
            fontWeight: 'bold',
            fontSize: '20px',
        },
        h5: {
            fontWeight: 600,
            fontSize: '1em',
            letterSpacing: '-1px',
        },
        body1: {
            fontWeight: 300,
        },
        subtitle1: {
            fontWeight: 300,
        },
    },
    props: {
        MuiGrid: {
            spacing: 3,
        },
        MuiTooltip: {
            placement: 'top',
        },
        MuiCheckbox: {
            color: 'primary',
        },
        MuiSelect: {
            IconComponent: ExpandMoreIcon,
        },
        MuiInputLabel: {
            shrink: true,
        },
        MuiDialog: {
            maxWidth: 'xs',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: '22.5px',
                fontSize: '12px',
                fontWeight: 'bold',
                letterSpacing: '1px',
                lineHeight: '24px',
                padding: '10px 20px',
                minWidth: '240px',
            },
            outlined: {
                padding: '10px 20px',
            },
            text: {
                padding: '10px 20px',
            },
            sizeSmall: {
                minWidth: 'auto',
                padding: '4px 8px',
                lineHeight: 'normal',
            },
            sizeLarge: {
                minWidth: 'auto',
            },
        },
        MuiTooltip: {
            tooltip: {
                maxWidth: '500px',
                fontSize: '14px',
                fontWeight: '300',
                padding: '10px',
                backgroundColor: '#252525',
            },
            tooltipPlacementTop: {
                margin: '8px 0',

                [defaultTheme.breakpoints.up('sm')]: {
                    margin: '8px 0',
                },
            },
        },
        MuiInput: {
            underline: {
                fontWeight: 300,
                '&:before': {
                    borderBottomColor: '#000',
                },
                '&:after': {
                    borderBottomColor: '#000',
                },
                '&$disabled': {
                    '&:before': {
                        borderBottomStyle: 'solid',
                    },
                },
            },
            formControl: {
                'label + &': {
                    marginTop: '24px',
                },
            },
        },
        MuiInputBase: {
            root: {
                marginTop: '8px',
            },
            input: {
                '&$disabled': {
                    color: '#333',
                    cursor: 'default',
                },
            },
        },
        MuiFormLabel: {
            root: {
                fontWeight: '400',
                '&$focused': {
                    color: '#808285',
                },
            },
        },
        MuiTextField: {
            root: {
                width: '100%',
            },
        },
        MuiFormHelperText: {
            root: {
                textAlign: 'right',
                marginTop: '0',
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'none',
                },
                textTransform: 'none',
            },
        },
        MuiStepIcon: {
            root: {
                color: '#fff',
                border: `1px solid #c0c0c0`,
                borderRadius: '50%',
                '&$completed': {
                    color: '#4b4a4b',
                    border: `none`,
                },
                '&$active': {
                    color: '#4ba82e',
                    border: `none`,
                    '&& text': {
                        fill: '#fff',
                    },
                },
            },
            text: {
                fill: '#4b4a4b',
            },
            active: {},
            completed: {},
        },
        MuiStepLabel: {
            label: {
                '&$completed': {
                    color: '#4b4a4b',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.71',
                    letterSpacing: 'normal',
                },
                '&$active': {
                    color: '#4ba82e',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.71',
                    letterSpacing: 'normal',
                },
            },
        },
        MuiStepConnector: {
            alternativeLabel: {
                left: `calc(-50% + 12px)`,
                right: `calc(50% + 12px)`,
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#4ba82e',
            },
        },
        MuiInputAdornment: {
            root: {
                '&& button': {
                    padding: '0',
                },
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                height: '280px',
                minHeight: '280px',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                height: '64',
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: '#fff',
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                display: 'none',
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: 0,
            },
        },
        MuiDialogTitle: {
            root: {
                fontWeight: 'bold',
                fontSize: '20px',
                color: '#4b4a4b',
                padding: '24px',
                borderBottom: 'solid rgba(184, 184, 184, 0.4) 1px',
                minHeight: '24px',
            },
        },
        MuiDialogContent: {
            root: {
                padding: '32px 60px 40px',
                color: '#4b4a4b',
            },
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'center',
                paddingBottom: '24px',
            },
        },
        MuiFormControl: {
            root: {
                marginTop: '18px',
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(55, 58, 71, 0.9)',
            },
        },
    },
})
muiTheme = responsiveFontSizes(muiTheme)

export default muiTheme

import { getSessionId } from './util/util'

const CbaApi = require('connect_boarding_application_api/dist')
const {
    AccountsApi,
    CustomersApi,
    I18nApi,
    ServicePartnerApi,
    CommissionsApi,
    OptionsApi,
    DownloadCBAReportApi,
    UserApi,
    EmailNotificationsApi,
    VehiclesApi,
} = CbaApi

const defaultClient = CbaApi.ApiClient.instance

defaultClient.authentications = {
    CbaIdToken: {
        type: 'bearer',
        accessToken: '',
    },
    CbaAccessToken: {
        type: 'bearer',
        accessToken: '',
    },
}

defaultClient.defaultHeaders = {
    'X-Session-ID': getSessionId(),
}

function setAccessToken(accessToken) {
    defaultClient.authentications.CbaAccessToken.accessToken = accessToken
}

function setIdToken(idToken) {
    defaultClient.authentications.CbaIdToken.accessToken = idToken
}

function setBasePath(basePath) {
    defaultClient.basePath = basePath
}

const Customers = new CustomersApi()
const Accounts = new AccountsApi()
const I18n = new I18nApi()
const Options = new OptionsApi()
const Commissions = new CommissionsApi()
const ServicePartner = new ServicePartnerApi()
const DownloadReport = new DownloadCBAReportApi()
const Users = new UserApi()
const EmailNotifications = new EmailNotificationsApi()
const Vehicles = new VehiclesApi()

export {
    ServicePartner,
    Customers,
    Accounts,
    I18n,
    Options,
    Commissions,
    DownloadReport,
    Users,
    EmailNotifications,
    Vehicles,
    setAccessToken,
    setIdToken,
    setBasePath,
}

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { Accounts } from '../../api'
import { RESET_CUSTOMER, SET_CONSENT_ID, SET_CONSENT_TYPE } from '../../types/reduxActionTypes'

export function useCustomerActions() {
    const dispatch = useDispatch()

    const setGroupIdRegistered = useCallback((uri) => {
        const body = {
            uri,
        }
        Accounts.groupIdRegistered(body)
            .then(() => {})
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const clearCustomer = useCallback(async () => {
        dispatch({
            type: RESET_CUSTOMER,
        })
    }, [dispatch])

    const setConsentId = useCallback(
        (consentId) => {
            dispatch({
                type: SET_CONSENT_ID,
                payload: consentId,
            })
        },
        [dispatch]
    )

    const setConsentType = useCallback(
        (consentType) => {
            dispatch({
                type: SET_CONSENT_TYPE,
                payload: consentType,
            })
        },
        [dispatch]
    )

    return {
        setGroupIdRegistered,
        clearCustomer,
        setConsentId,
        setConsentType,
    }
}

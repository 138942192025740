import React from 'react'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { DEFAULT_CULTURE, ignoreKeyInHTML } from './constants/global'

i18next
    // passes i18n down to react-i18next
    .use(initReactI18next)
    // adds translation wrapped in a span element with original translation key
    .use({
        name: 'keysInHTML',
        type: 'postProcessor',
        process: (value, key, options) => {
            return options.replace === ignoreKeyInHTML
                ? value
                : React.createElement('span', { 'data-key': key[0] }, value)
        },
    })
    .init({
        resources: {},
        lng: DEFAULT_CULTURE,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        postProcess: 'keysInHTML',
    })

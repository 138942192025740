import { store } from '../index'
import { Options } from '../api'
import { SET_OPTIONS } from '../types/reduxActionTypes'

export function getOptions() {
    const countries = store.getState().options.countries
    if (!countries || countries.length === 0) {
        Options.getOptions()
            .then((response) => {
                store.dispatch({
                    type: SET_OPTIONS,
                    payload: {
                        options: response.data,
                    },
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

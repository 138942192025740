import React from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../../components/Wrappers/Page'
import { TermsOfUseContent } from '../Documents/TermsOfUseContent'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { getTranslations } from '../../actions/i18nActions'
import { WithHeader } from '../../components/Wrappers/WithHeader'

function TermsOfUsePage() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    getTranslations()

    return (
        <ScrollTop>
            <Page
                withBackLink={true}
                backLinkUrl={'/customers'}
                title={t('general.footer.termsOfUse.title')}
                hideInPrint={true}
            >
                <TermsOfUseContent />
            </Page>
        </ScrollTop>
    )
}

export default WithHeader(TermsOfUsePage)

import React, { useCallback, useRef, useState } from 'react'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
    TextField,
    Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { consentsEnum, ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../constants/global'
import { errorTextMap, getErrorText, validateVehicleDetails, validateVin } from '../util/validators'
import Close from '@material-ui/icons/Close'
import { Accounts, Vehicles } from '../api'
import ServicePartnerSearchInput from './ServicePartnerSearchInput/ServicePartnerSearchInput'
import { useDispatchNotification } from '../hooks/useDispatchNotification'
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify'
import { useDispatch, useSelector } from 'react-redux'
import { updateErrorMessage, updateSuccessMessage } from '../types/reduxNotificationTypes'
import LabelWithTooltip from './LabelWithTooltip'
import { CONSENT_TYPES_ENUM, VEHICLE_INFO_TYPES_ENUM } from '../util/util'
import { CloseCross } from './global'
import { useUpdateCustomerActions } from '../hooks/actions/useUpdateCustomerActions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { SET_EXPIRED_LICENSES } from '../types/reduxActionTypes'

const isFormValid = (data) => {
    const validStateOptions = ['vin', 'commissionNumber', 'orderNumber', 'consentLanguage', 'psp']
    return validStateOptions.every((item) => data[item] === null)
}
const year = new Date().getFullYear()

const VehicleInfoDialog = ({
    customer,
    vehicleData,
    setOpen,
    getVehiclesData,
    getStatusesData,
    actionType = VEHICLE_INFO_TYPES_ENUM.update,
}) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const dispatchNotification = useDispatchNotification()
    const dispatch = useDispatch()
    const { saveOrderDetailsField, saveConsentVerificationField, saveCustomerInformationItem } =
        useUpdateCustomerActions()
    const { customerInformation } = customer
    const commissionNumberParts = vehicleData.commissionNumber.split('-')
    const commissionNumber = commissionNumberParts[0] ?? ''
    const commissionBid = commissionNumberParts[1] ?? ''
    const commissionYear = commissionNumberParts[2] ?? ''
    const { countries, selectedLanguage } = useSelector((state) => state.options)
    const { userInfo } = useSelector((state) => state.oidc)
    const [isSubmitRqInProgress, setIsSubmitRqInProgress] = useState(false)
    const [isEmailRqInProgress, setIsEmailRqInProgress] = useState(false)
    const [modelName, setModelName] = useState('')
    const checkExistingVehicleHandleRef = useRef(null)

    const [data, setData] = useState({
        ...vehicleData,
        commissionNumber,
        commissionBid,
        commissionYear,
        consentLanguage: customerInformation.preferredLanguage,
    })
    const [errors, setErrors] = useState({})
    const [consentsAgreedData, setConsentsAgreedData] = useState({
        [consentsEnum.CONNECT_SERVICES]: vehicleData.consents[consentsEnum.CONNECT_SERVICES],
        [consentsEnum.IDENT]: vehicleData.consents[consentsEnum.IDENT],
        [consentsEnum.PSP]: vehicleData.consents[consentsEnum.PSP],
        vin: vehicleData.vin,
        commissionNumber,
        commissionBid,
        commissionYear,
        consentLanguage: customerInformation.preferredLanguage,
    })

    const onCloseHandler = () => {
        setOpen(-1)
    }

    const saveDataField = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            [e.target.name]:
                e.target.name === 'vin' ? e.target.value.toUpperCase() : e.target.value,
        }))
    }

    const saveConsentVerificationHandle = (name, checked) => {
        setData((prevState) => ({
            ...prevState,
            consents: {
                ...prevState.consents,
                [name]: checked,
            },
        }))
    }

    const setPsp = (id, name) => {
        setData((prevState) => ({
            ...prevState,
            preferredServicePartnerId: id,
            preferredServicePartnerName: name,
        }))
    }

    const updateConsentsAgreedData = () => {
        setConsentsAgreedData({
            [consentsEnum.CONNECT_SERVICES]: data.consents[consentsEnum.CONNECT_SERVICES],
            [consentsEnum.IDENT]: data.consents[consentsEnum.IDENT],
            [consentsEnum.PSP]: data.consents[consentsEnum.PSP],
            vin: data.vin,
            commissionNumber: data.commissionNumber,
            commissionBid: data.commissionBid,
            commissionYear: data.commissionYear,
            consentLanguage: data.consentLanguage,
        })

        // updating consents also in store to reflect changes outside dialog
        if (actionType === VEHICLE_INFO_TYPES_ENUM.updateOnCreation) {
            saveConsentVerificationField(
                consentsEnum.CONNECT_SERVICES,
                data.consents[consentsEnum.CONNECT_SERVICES]
            )
            saveConsentVerificationField(consentsEnum.IDENT, data.consents[consentsEnum.IDENT])
            saveConsentVerificationField(consentsEnum.PSP, data.consents[consentsEnum.PSP])
        }
    }

    const onPrintHandler = async () => {
        const formErrors = validateVehicleDetails(data)
        setErrors(formErrors)

        if (isFormValid(formErrors)) {
            const locale = `${data.consentLanguage}-${
                countries.find((country) => country.id === customerInformation.address.countryId)
                    ?.countryCode
            }`
            window.open(`/consent-documents/${locale}`, '_blank')
            try {
                await Accounts.modifyConsents(vehicleData.consentId, {
                    consentType: CONSENT_TYPES_ENUM.paper,
                    email: customerInformation.email,
                    locale,
                    vin: data.vin || null,
                    commissionNumber: data.commissionNumber || null,
                    commissionNumberBid: data.commissionNumber ? data.commissionBid : null,
                    commissionNumberYear: data.commissionNumber ? data.commissionYear : null,
                    orderNumber: data.orderNumber || null,
                    preferredServicePartnerId: data.consents[consentsEnum.PSP]
                        ? data.preferredServicePartnerId
                        : null,
                    preferredServicePartnerName: data.consents[consentsEnum.PSP]
                        ? data.preferredServicePartnerName
                        : null,
                    ...data.consents,
                })
                updateConsentsAgreedData()
            } catch (e) {
                console.error(e)
            }
        }
    }

    const onSendEmailHandler = async () => {
        const formErrors = validateVehicleDetails(data)
        setErrors(formErrors)

        if (isFormValid(formErrors)) {
            setIsEmailRqInProgress(true)
            try {
                await Accounts.modifyConsents(vehicleData.consentId, {
                    consentType: CONSENT_TYPES_ENUM.digital,
                    email: customerInformation.email,
                    locale: `${data.consentLanguage}-${
                        countries.find(
                            (country) => country.id === customerInformation.address.countryId
                        )?.countryCode
                    }`,
                    vin: data.vin || null,
                    commissionNumber: data.commissionNumber || null,
                    commissionNumberBid: data.commissionNumber ? data.commissionBid : null,
                    commissionNumberYear: data.commissionNumber ? data.commissionYear : null,
                    orderNumber: data.orderNumber || null,
                    preferredServicePartnerId: data.preferredServicePartnerId || null,
                    preferredServicePartnerName: data.preferredServicePartnerName || null,
                    ...data.consents,
                })

                dispatchNotification({
                    message: t('general.consentEmail.sent.success', { replace: ignoreKeyInHTML }),
                    type: NOTIFICATION_TYPE_SUCCESS,
                    duration: 3000,
                    canDismiss: true,
                })
                updateConsentsAgreedData()
            } catch (e) {
                console.error(e)
                dispatchNotification({
                    message: t('general.consentEmail.sent.error', { replace: ignoreKeyInHTML }),
                    type: NOTIFICATION_TYPE_ERROR,
                    duration: 3000,
                    canDismiss: true,
                })
            }
            setIsEmailRqInProgress(false)
        }
    }

    const onSubmitHandler = async () => {
        const formErrors = validateVehicleDetails(data)
        setErrors(formErrors)

        if (isFormValid(formErrors)) {
            if (actionType === VEHICLE_INFO_TYPES_ENUM.update) {
                setIsSubmitRqInProgress(true)
                try {
                    await Vehicles.updateVehicleOrderData(vehicleData.vehicleId, {
                        ...data.consents,
                        vin: data.vin || null,
                        commissionNumber: data.commissionNumber || null,
                        commissionNumberBid: data.commissionNumber ? data.commissionBid : null,
                        commissionNumberYear: data.commissionNumber ? data.commissionYear : null,
                        orderNumber: data.orderNumber || null,
                        preferredServicePartnerId: data.consents[consentsEnum.PSP]
                            ? data.preferredServicePartnerId
                            : null,
                        preferredServicePartnerName: data.consents[consentsEnum.PSP]
                            ? data.preferredServicePartnerName
                            : null,
                        dealer: {
                            id: userInfo.dealershipId,
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName,
                        },
                    })
                    dispatchNotification(updateSuccessMessage)
                    getVehiclesData(customer.id)
                    getStatusesData(customer.id)
                    setOpen(-1)
                } catch (e) {
                    console.error(e)
                    dispatchNotification(updateErrorMessage)
                }
                setIsSubmitRqInProgress(false)
            } else if (actionType === VEHICLE_INFO_TYPES_ENUM.updateOnCreation) {
                const fieldsToUpdate = ['orderNumber', 'commissionNumber', 'commissionYear', 'vin']
                fieldsToUpdate.forEach((item) => saveOrderDetailsField(item, data[item]))
                if (!data.consents[consentsEnum.PSP]) {
                    saveOrderDetailsField('preferredServicePartnerId', null)
                    saveOrderDetailsField('preferredServicePartnerName', null)
                }
                saveCustomerInformationItem('preferredLanguage', data.consentLanguage)
                setOpen(-1)
            }
        }
    }

    const checkVinCallback = useCallback(
        async (vin) => {
            const vehicleCustomerResponse = await Vehicles.getVehicleCustomerByVin(vin)

            vehicleCustomerResponse?.data?.customerId &&
                vehicleCustomerResponse?.data?.email === customerInformation.email &&
                setErrors((prevState) => ({
                    ...prevState,
                    vin: t('general.order.vin.alreadyExisted'),
                }))

            vehicleCustomerResponse?.data?.customerId &&
                vehicleCustomerResponse?.data?.email !== customerInformation.email &&
                setErrors((prevState) => ({
                    ...prevState,
                    vin: t('general.order.vin.alreadyExistedAnotherCustomer'),
                }))
        },
        [customerInformation.email, t]
    )

    const onChangeVinHandle = useCallback(
        (vin) => {
            setModelName('')
            actionType === VEHICLE_INFO_TYPES_ENUM.updateOnCreation &&
                dispatch({
                    type: SET_EXPIRED_LICENSES,
                    payload: {},
                })
            setErrors((prevState) => ({
                ...prevState,
                vin: null,
            }))
            checkExistingVehicleHandleRef.current &&
                window.clearTimeout(checkExistingVehicleHandleRef.current)
            if (validateVin(vin) === null) {
                checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                    try {
                        await checkVinCallback(vin)
                        const modelNameResponse = await Vehicles.getModelNameByVin(vin)
                        modelNameResponse.data?.modelName &&
                            setModelName(modelNameResponse.data.modelName)
                        const expiredLicenses = await Vehicles.getExpiredLicensesByVin(
                            vin,
                            selectedLanguage.replace('-', '_')
                        )
                        actionType === VEHICLE_INFO_TYPES_ENUM.updateOnCreation &&
                            dispatch({
                                type: SET_EXPIRED_LICENSES,
                                payload: expiredLicenses?.data || {},
                            })
                    } catch (e) {
                        console.error(e)
                    }
                }, 500)
            }
        },
        [checkVinCallback, selectedLanguage, actionType, dispatch]
    )

    const onChangeCommissionNumberFullHandle = useCallback(
        async (commissionNumberFull) => {
            setErrors((prevState) => ({
                ...prevState,
                commissionNumber: null,
            }))
            checkExistingVehicleHandleRef.current &&
                window.clearTimeout(checkExistingVehicleHandleRef.current)
            if (/\d{6}-\d{3}-\d{4}/.test(commissionNumberFull)) {
                checkExistingVehicleHandleRef.current = window.setTimeout(async () => {
                    try {
                        const commissionNumberParts = commissionNumberFull.split('-')
                        const vehiclesResponse =
                            await Vehicles.getVehicleCustomerByFullCommissionNumber(
                                commissionNumberFull
                            )

                        vehiclesResponse?.data?.customerId &&
                            vehiclesResponse?.data?.email === customerInformation.email &&
                            setErrors((prevState) => ({
                                ...prevState,
                                commissionNumber: t('general.order.commission.alreadyExisted'),
                            }))

                        vehiclesResponse?.data?.customerId &&
                            vehiclesResponse?.data?.email !== customerInformation.email &&
                            setErrors((prevState) => ({
                                ...prevState,
                                commissionNumber: t(
                                    'general.order.commission.alreadyExistedAnotherCustomer'
                                ),
                            }))

                        const vinAndModelNameResponse =
                            await Vehicles.getVinAndModelNameByCommissionNumber(
                                // number, year, bid - switched params bid and year
                                commissionNumberParts[0],
                                commissionNumberParts[2],
                                commissionNumberParts[1]
                            )
                        if (vinAndModelNameResponse.data?.vin) {
                            if (actionType === VEHICLE_INFO_TYPES_ENUM.updateOnCreation) {
                                saveOrderDetailsField('vin', vinAndModelNameResponse.data.vin)
                            } else {
                                setData((prevState) => ({
                                    ...prevState,
                                    vin: vinAndModelNameResponse.data.vin,
                                }))
                            }
                            await checkVinCallback(vinAndModelNameResponse.data?.vin)
                        }
                        vinAndModelNameResponse.data?.modelName &&
                            setModelName(vinAndModelNameResponse.data.modelName)
                    } catch (e) {
                        console.error(e)
                    }
                }, 500)
            }
        },
        [actionType, checkVinCallback, customerInformation.email, saveOrderDetailsField, t]
    )

    return (
        <>
            {/* fixed open(=true) prop since the visibility is controlled in parent component
            to force fresh dialog render */}
            <Dialog open={true} maxWidth={'sm'} fullWidth onClose={onCloseHandler}>
                <DialogTitle disableTypography>
                    <CloseCross onClick={onCloseHandler}>
                        <Close />
                    </CloseCross>
                    {t('general.vehicle.update.title')}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        name={'email'}
                        value={customerInformation.email}
                        label={t('general.customer.create.popup.email')}
                        fullWidth
                        disabled
                    />
                    <TextField
                        name={'firstName'}
                        value={customerInformation.firstName}
                        label={t('general.customer.firstName')}
                        fullWidth
                        disabled
                    />
                    <TextField
                        name={'lastName'}
                        value={customerInformation.lastName}
                        label={t('general.customer.lastName')}
                        fullWidth
                        disabled
                    />
                    <FormControl fullWidth error={!!errors.consentLanguage}>
                        <InputLabel required style={{ whiteSpace: 'nowrap' }}>
                            {t('general.footer.language')}
                        </InputLabel>
                        <Select
                            displayEmpty={false}
                            value={data.consentLanguage ?? ''}
                            onChange={saveDataField}
                            label={t('general.customer.create.popup.email')}
                            name={'consentLanguage'}
                        >
                            <MenuItem value={''} disabled>
                                {t('general.select.choose')}
                            </MenuItem>
                            {countries
                                .find(
                                    (country) =>
                                        country.id.toString() ===
                                        (customerInformation.address?.countryId || '').toString()
                                )
                                ?.languages?.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {t(`general.language.${item}`)}
                                    </MenuItem>
                                ))}
                        </Select>
                        {errors.consentLanguage && (
                            <FormHelperText style={{ whiteSpace: 'nowrap' }} error>
                                {t('general.footer.language')}{' '}
                                {t(getErrorText(errors.consentLanguage))}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <TextField
                        name={'vin'}
                        value={data.vin ?? ''}
                        label={
                            <LabelWithTooltip
                                labelText={t('general.order.vin')}
                                tooltipText={
                                    vehicleData.editLocked &&
                                    t('general.record.locked.enrollmentStarted')
                                }
                            />
                        }
                        InputLabelProps={{
                            required: !data.orderNumber && !data.commissionNumber,
                        }}
                        fullWidth
                        error={!!errors.vin}
                        helperText={
                            errors.vin ? (
                                <>
                                    {t('general.order.vin')} {t(getErrorText(errors.vin))}
                                </>
                            ) : (
                                modelName
                            )
                        }
                        onChange={(e) => {
                            saveDataField(e)
                            onChangeVinHandle(e.target.value.toUpperCase())
                        }}
                        disabled={vehicleData.editLocked}
                    />
                    <Grid container spacing={1} wrap={'nowrap'}>
                        <Grid item md={2}>
                            <FormControl error={!!errors.commissionNumber}>
                                <InputLabel
                                    style={{ whiteSpace: 'nowrap' }}
                                    required={!data.vin && !data.orderNumber}
                                >
                                    {t('general.order.commission')}
                                </InputLabel>
                                <Input
                                    name={'commissionNumber'}
                                    value={data.commissionNumber ?? ''}
                                    style={{ letterSpacing: '1px' }}
                                    inputProps={{
                                        maxLength: 6,
                                    }}
                                    onChange={(e) => {
                                        saveDataField(e)
                                        onChangeCommissionNumberFullHandle(
                                            `${e.target.value}-${userInfo.bid}-${data.commissionYear}`
                                        )
                                    }}
                                    placeholder={'______'}
                                    disabled={vehicleData.editLocked || !!data.vin}
                                />
                            </FormControl>
                            {errors.commissionNumber && (
                                <FormHelperText style={{ whiteSpace: 'nowrap' }} error>
                                    {errorTextMap[errors.commissionNumber] ? (
                                        <>
                                            {t('general.order.commission')}{' '}
                                            {t(errorTextMap[errors.commissionNumber])}
                                        </>
                                    ) : (
                                        errors.commissionNumber || null
                                    )}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item md={1}>
                            <FormControl>
                                <InputLabel />
                                <Input name={'bid'} value={data.commissionBid ?? ''} disabled />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <InputLabel />
                                <Select
                                    name={'commissionYear'}
                                    value={data.commissionYear || ''}
                                    fullWidth
                                    onChange={(e) => {
                                        saveDataField(e)
                                        onChangeCommissionNumberFullHandle(
                                            `${data.commissionNumber}-${userInfo.bid}-${e.target.value}`
                                        )
                                    }}
                                    disabled={vehicleData.editLocked || !!data.vin}
                                >
                                    <MenuItem value={year + 1}>{year + 1}</MenuItem>
                                    <MenuItem value={year}>{year}</MenuItem>
                                    <MenuItem value={year - 1}>{year - 1}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <TextField
                        name={'orderNumber'}
                        value={data.orderNumber ?? ''}
                        label={t('general.order.orderNo')}
                        InputLabelProps={{
                            required: !data.vin && !data.commissionNumber,
                        }}
                        fullWidth
                        error={!!errors.orderNumber}
                        helperText={
                            errors.orderNumber && (
                                <>
                                    {t('general.order.orderNo')}{' '}
                                    {t(getErrorText(errors.orderNumber))}
                                </>
                            )
                        }
                        onChange={saveDataField}
                        disabled={!!data.vin || !!data.commissionNumber}
                    />
                    <FormControl fullWidth>
                        <InputLabel>{t('general.consents.title')}</InputLabel>
                    </FormControl>
                    <Box>
                        <FormControlLabel
                            style={{ marginTop: '18px' }}
                            control={
                                <Checkbox
                                    checked={data.consents[consentsEnum.CONNECT_SERVICES] || false}
                                    onChange={(e, checked) =>
                                        saveConsentVerificationHandle(e.target.name, checked)
                                    }
                                    name={consentsEnum.CONNECT_SERVICES}
                                />
                            }
                            label={t('general.consents.checkbox.services')}
                        />
                    </Box>
                    {/* MSP-51523 - Hide ident */}
                    {/*<Box>*/}
                    {/*      <FormControlLabel*/}
                    {/*          control={*/}
                    {/*              <Checkbox*/}
                    {/*                  checked={data.consents[consentsEnum.IDENT] || false}*/}
                    {/*                  onChange={(e, checked) =>*/}
                    {/*                      saveConsentVerificationHandle(e.target.name, checked)*/}
                    {/*                  }*/}
                    {/*                  name={consentsEnum.IDENT}*/}
                    {/*              />*/}
                    {/*          }*/}
                    {/*          label={t('general.consents.checkbox.skodaIdent')}*/}
                    {/*      />*/}
                    {/*  </Box>*/}
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.consents[consentsEnum.PSP] || false}
                                    onChange={(e, checked) =>
                                        saveConsentVerificationHandle(e.target.name, checked)
                                    }
                                    name={consentsEnum.PSP}
                                />
                            }
                            label={t('general.consents.checkbox.psp')}
                        />
                    </Box>
                    {data.consents[consentsEnum.PSP] && (
                        <ServicePartnerSearchInput
                            orderDetails={data}
                            setPsp={setPsp}
                            errors={errors}
                            editMode={true}
                            disabled={false}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    {consentsAgreedData[consentsEnum.CONNECT_SERVICES] !==
                        data.consents[consentsEnum.CONNECT_SERVICES] ||
                    consentsAgreedData[consentsEnum.IDENT] !== data.consents[consentsEnum.IDENT] ||
                    consentsAgreedData[consentsEnum.PSP] !== data.consents[consentsEnum.PSP] ||
                    (consentsAgreedData.vin && consentsAgreedData.vin !== data.vin) ||
                    (consentsAgreedData.commissionNumber &&
                        consentsAgreedData.commissionNumber !== data.commissionNumber) ||
                    (consentsAgreedData.commissionBid &&
                        consentsAgreedData.commissionBid !== data.commissionBid) ||
                    (consentsAgreedData.commissionYear &&
                        consentsAgreedData.commissionYear !== data.commissionYear) ||
                    consentsAgreedData.consentLanguage !== data.consentLanguage ? (
                        <>
                            <Button
                                style={{ minWidth: '140px' }}
                                variant={'outlined'}
                                onClick={onPrintHandler}
                                disabled={
                                    !data.consents[consentsEnum.CONNECT_SERVICES] ||
                                    (!data.vin && !data.commissionNumber && !data.orderNumber)
                                }
                            >
                                {t('general.button.print')}
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={onSendEmailHandler}
                                disabled={
                                    !data.consents[consentsEnum.CONNECT_SERVICES] ||
                                    isEmailRqInProgress ||
                                    (!data.vin && !data.commissionNumber && !data.orderNumber)
                                }
                            >
                                {t('general.button.sendConsent')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                style={{ minWidth: '140px' }}
                                variant={'outlined'}
                                onClick={onCloseHandler}
                            >
                                {t('general.button.cancel')}
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={onSubmitHandler}
                                disabled={
                                    !data.consents[consentsEnum.CONNECT_SERVICES] ||
                                    isSubmitRqInProgress
                                }
                            >
                                {t('general.button.order.update')}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VehicleInfoDialog

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { allowAccessToCreateProcess, closeHasSkodaIdentPopup } from '../../../actions/popupsActions'
import {
    NAVIGATION_BUTTONS_CONFIGURATION,
    SKODA_IDENT_STEP,
} from '../../../constants/registrationConstants'
import PopUp from '../PopUp'
import React from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import { Box, Button, Typography } from '@material-ui/core'

export default function CustomerHasSkodaIdentPopUp() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()

    const { hasSkodaIdentActive } = useSelector((state) => state.popups)

    const content = (
        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <WarningIcon width={56} />
            <Box mt={3}>
                <Typography variant={'h3'} align={'center'}>
                    {t('general.customer.ident.popup.text')}
                </Typography>
            </Box>
        </Box>
    )

    const onContinue = () => {
        closeHasSkodaIdentPopup()
        allowAccessToCreateProcess()
        history.push({
            pathname: NAVIGATION_BUTTONS_CONFIGURATION[SKODA_IDENT_STEP].nextButtonUrl,
        })
    }

    const actions = (
        <Button variant={'contained'} color={'primary'} onClick={onContinue}>
            {t('general.customer.ident.popup.button')}
        </Button>
    )

    const config = {
        title: t('general.customer.create.popup.title'),
        type: 'HAS_SKODA_IDENT',
        actions: actions,
        body: content,
    }
    return (
        <PopUp
            open={hasSkodaIdentActive}
            body={config.body}
            title={config.title}
            actions={config.actions}
        />
    )
}

import {
    ADD_CUSTOMER_INFO_FROM_GROUP_ID,
    RESET_CUSTOMER,
    SET_CUSTOMER_DATA,
    SET_CONSENT_VERIFICATION_ITEM,
    SET_CUSTOMER_INFORMATION_ITEM,
    SET_CUSTOMER_INFORMATION_ADDRESS_FIELD,
    SET_ORDER_DETAILS_ITEM,
    SET_SKODA_IDENT_ITEM,
    SET_PSP,
    SET_CONSENT_ID,
    SET_CONSENT_TYPE,
    RESET_CUSTOMER_WITH_DATA,
} from '../types/reduxActionTypes'
import { consentsEnum } from '../constants/global'

const skodaIdentDefault = {
    licenseNumber: '',
    countryOfIssueCode: null,
}

const orderDetailsDefault = {
    salesPerson: '',
    commissionNumber: '',
    commissionYear: '',
    orderNumber: '',
    vin: '',
    preferredServicePartnerId: null,
    preferredServicePartnerName: null,
    createdByOcwData: false,
}

const initialState = {
    customerInformation: {
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        nickname: '',
        address: {
            countryId: 0,
        },
        dialCode: null,
        phoneNumber: '',
        preferredLanguage: null,
    },
    skodaIdent: skodaIdentDefault,
    orderDetails: orderDetailsDefault,
    consentVerification: {
        [consentsEnum.CONNECT_SERVICES]: false,
        [consentsEnum.IDENT]: false,
        [consentsEnum.PSP]: false,
    },
    consentId: null,
    consentType: null,
    skodaConnectExistsFlag: false,
    emailAlreadySent: false,
}

const userSignedOutAction = 'oidc/userSignedOut'

export const createCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case userSignedOutAction:
            return initialState
        case SET_CUSTOMER_DATA:
            return {
                ...state,
                ...action.payload.customer,
                customerInformation: {
                    ...action.payload.customer.customerInformation,
                    address: action.payload.customer.customerInformation.address,
                },
                consentVerification: action.payload.customer.summaryData,
            }
        case SET_PSP:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    preferredServicePartnerId: action.payload.value.id,
                    preferredServicePartnerName: action.payload.value.name,
                },
            }
        case RESET_CUSTOMER:
            return initialState
        case RESET_CUSTOMER_WITH_DATA: {
            return {
                ...initialState,
                customerInformation: {
                    ...initialState.customerInformation,
                    ...action.payload,
                    address: {
                        ...initialState.customerInformation.address,
                        ...action.payload.address,
                    },
                },
            }
        }
        case ADD_CUSTOMER_INFO_FROM_GROUP_ID:
            return {
                ...state,
                customerInformation: {
                    ...action.payload.customer,
                },
            }
        case SET_CUSTOMER_INFORMATION_ITEM:
            let newNickname = state.customerInformation.nickname
            if (action.payload.name === 'firstName') {
                newNickname =
                    action.payload.value.substr(0, 98) +
                    ' ' +
                    state.customerInformation.lastName.substring(0, 1)
            }
            if (action.payload.name === 'lastName') {
                newNickname =
                    state.customerInformation.firstName.substring(0, 98) +
                    ' ' +
                    action.payload.value.substr(0, 1)
            }
            if (action.payload.name === 'nickname') {
                newNickname = action.payload.value
            }
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    nickname: newNickname,
                    [action.payload.name]: action.payload.value,
                },
            }
        case SET_CUSTOMER_INFORMATION_ADDRESS_FIELD:
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    address: {
                        ...state.customerInformation.address,
                        [action.payload.name]: action.payload.value,
                    },
                },
            }
        case SET_SKODA_IDENT_ITEM:
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    [action.payload.name]: action.payload.value,
                },
            }
        case SET_ORDER_DETAILS_ITEM:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    [action.payload.name]: action.payload.value,
                },
            }
        case SET_CONSENT_VERIFICATION_ITEM:
            return {
                ...state,
                consentVerification: {
                    ...state.consentVerification,
                    [action.payload.name]: action.payload.value,
                },
            }
        case SET_CONSENT_ID:
            return {
                ...state,
                consentId: action.payload,
            }
        case SET_CONSENT_TYPE:
            return {
                ...state,
                consentType: action.payload,
            }
        default:
            return state
    }
}

import React, { useEffect, useState } from 'react'
import { getOptions } from '../actions/optionsActions'
import { getTranslations } from '../actions/i18nActions'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import { useDispatch, useSelector } from 'react-redux'
import InputTooltip from './Tooltip/InputTooltip'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'
import styled from 'styled-components'
import { BeakIcon } from './Icons/BeakIcon'
import { useChangeLanguage } from '../hooks/useChangeLanguage'
import { makeStyles } from '@material-ui/core'

const Wrapper = styled.div`
    display: flex;
    color: #c0c0c0;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    line-height: 1.83;
    text-align: right;
    text-transform: uppercase;
    margin-right: 100px;
`

const Title = styled(MenuItem)`
    text-transform: uppercase;
`

const Item = styled(MenuItem)`
    text-transform: none;
`

const Country = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: default;
    min-width: 120px;
    max-width: 300px;
`

const CountryName = styled.div`
    margin-right: 10px;
`

const useFormControlStyles = makeStyles({
    root: {
        minWidth: '120px',
        maxWidth: '300px',
        marginTop: 0,
    },
})

const useSelectInputStyles = makeStyles({
    root: {
        color: '#fff',
        marginTop: 0,
    },
})

export default function LanguageSelector() {
    const { userInfo } = useSelector((state) => state.oidc)
    const { selectedLanguage, countries, loading } = useSelector((state) => state.options)
    const dispatch = useDispatch()
    const [languages, setLanguages] = useState([])
    const [selectedCountry, setSelectedCountry] = useState(null)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { setLanguage } = useChangeLanguage()
    const formControlClasses = useFormControlStyles()
    const selectInputClasses = useSelectInputStyles()

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }

    useEffect(() => {
        getOptions()
    }, [])

    useEffect(() => {
        if (countries !== null && userInfo && userInfo.country !== null && countries.length > 0) {
            const selectedCulture = countries.find((c) => c.countryCode === userInfo.country)
            setSelectedCountry(selectedCulture.countryCode)
            const selectedLanguages = selectedCulture.languages.map((c) => {
                return c + '-' + selectedCulture.countryCode
            })
            if (!selectedLanguage || !selectedLanguages.includes(selectedLanguage)) {
                let defaultLang = selectedLanguages[0]
                selectedLanguages.forEach((l) => {
                    if (l.substring(0, 2) === userInfo.language) {
                        defaultLang = l
                    }
                })
                setLanguage(defaultLang)
            }
            setLanguages(selectedLanguages)

            getTranslations(selectedLanguages)
        }
    }, [userInfo, countries, loading, dispatch, setLanguage, selectedLanguage])

    const handleChange = (event) => {
        if (selectedLanguage !== event.target.value) {
            setLanguage(event.target.value)
        }
    }

    if (!countries || !selectedLanguage || languages.length === 0) {
        return null
    }

    return (
        <Wrapper>
            <Country>
                <CountryName>{t('general.country.' + selectedCountry)}</CountryName>
                <InputTooltip text={t('general.footer.country.tooltip')} />
            </Country>
            <FormControl variant={'standard'} classes={{ root: formControlClasses.root }}>
                <Select
                    displayEmpty
                    value={selectedLanguage}
                    onChange={handleChange}
                    input={<Input disableUnderline classes={{ root: selectInputClasses.root }} />}
                    MenuProps={MenuProps}
                    IconComponent={BeakIcon}
                >
                    <Title value="" key="language-selector-title" disabled>
                        {t('general.language.select')}
                    </Title>
                    {languages.map((name) => (
                        <Item key={name} value={name}>
                            {t('general.language.' + name.substr(0, 2))}
                        </Item>
                    ))}
                </Select>
            </FormControl>
        </Wrapper>
    )
}

export function formatDateIntoDDMMYYYY(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return String(1000000 * day + 10000 * month + year)
}

export function formatDateIntoYYYYMMDDWithDashes(date) {
    if (!date) {
        return null
    }
    try {
        let month = date.getMonth() + 1
        let day = date.getDate()
        const year = date.getFullYear()

        if (month.toString().length < 2) {
            month = '0' + month
        }
        if (day.toString().length < 2) {
            day = '0' + day
        }

        return [year, month, day].join('-')
    } catch (e) {
        console.error(e)
        return null
    }
}

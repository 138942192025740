import {
    SET_OPTIONS,
    SET_LANG_AND_TRANSLATIONS,
    SET_SELECTED_LANGUAGE,
} from '../types/reduxActionTypes'

const initialState = {
    countries: [],
    selectedLanguage: null,
    translations: {},
}
const userSignedOutAction = 'oidc/userSignedOut'

export const optionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload.value,
            }
        case SET_OPTIONS:
            return {
                ...state,
                countries: action.payload.options.countries,
            }
        case SET_LANG_AND_TRANSLATIONS:
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.payload.language]: action.payload.translations,
                },
            }
        case userSignedOutAction:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export function prepareCustomerInformationForUpdate(customerInformation) {
    return {
        email: customerInformation.email,
        firstName: customerInformation.firstName,
        middleName: customerInformation.middleName,
        lastName: customerInformation.lastName,
        nickname: customerInformation.nickname,
        address: {
            ...customerInformation.address,
        },
        preferredLanguage: customerInformation.preferredLanguage,
        phoneNumber: customerInformation.phoneNumber
            ? customerInformation.phoneNumber.replace(/\s/g, '')
            : null,
        dialCode:
            customerInformation.dialCode && customerInformation.phoneNumber
                ? customerInformation.dialCode
                : null,
        lastModifiedDate: customerInformation.lastModifiedDate,
    }
}

export function prepareSkodaIdentForUpdate(skodaIdent) {
    return {
        licenseNumber: skodaIdent.licenseNumber,
        countryOfIssueCode: skodaIdent.countryOfIssueCode,
    }
}

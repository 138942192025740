import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_CULTURE, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import styled from 'styled-components'
import { InfoIcon } from '../../components/Icons/InfoIcon'
import { getDefaultTranslations } from '../../actions/i18nActions'
import { useSelector } from 'react-redux'
import { useCustomerActions } from '../../hooks/actions/useCustomerActions'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import { AppStoreIcon } from '../../components/Icons/AppStoreIcon'
import { GooglePlayIcon } from '../../components/Icons/GooglePlayIcon'
import { AppQrCode } from '../../components/Icons/AppQrCode'
import { Box, Typography } from '@material-ui/core'

const IconsDivider = styled.div`
    width: 40px;

    @media (max-width: 768px) {
        width: 20px;
    }

    @media (max-width: 400px) {
        width: 3px;
    }
`

export default function ThankYouPage() {
    const { t, i18n } = useTranslation(TRANSLATIONS_NAMESPACE)

    const { translations, loading } = useSelector((state) => state.options)
    const [loaded, setLoaded] = useState(true)
    const { setGroupIdRegistered } = useCustomerActions()
    const [sent, setSent] = useState(false)
    const { setLanguage } = useChangeLanguage()

    useEffect(() => {
        getDefaultTranslations()
        if (translations && Object.keys(translations).length > 0 && !loading) {
            setLoaded(true)
            if (Object.keys(translations).length === 1) {
                setLanguage(Object.keys(translations)[0])
            } else {
                setLanguage(DEFAULT_CULTURE)
            }
            if (!sent) {
                const uriParsed = `${window.location.origin}${
                    window.location.pathname
                }#${encodeURIComponent(window.location.hash.substring(1))}`
                setGroupIdRegistered(uriParsed)
                setSent(true)
            }
        }
    }, [translations, i18n, loading, setGroupIdRegistered, setLanguage, sent])

    return loaded ? (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            m={4}
            color={'text.secondary'}
        >
            <InfoIcon />
            <Box my={2}>
                <Typography variant={'h3'} align={'center'}>
                    {t('general.thankyou.title')}
                </Typography>
            </Box>
            <Box my={1}>
                <Typography variant={'body1'} align={'center'}>
                    {t('general.thankyou.app.line1')}
                </Typography>
            </Box>
            <Box my={1}>
                <Typography variant={'body1'} align={'center'}>
                    {t('general.thankyou.app.line2')}
                </Typography>
            </Box>
            <Box my={1}>
                <Typography variant={'body1'} align={'center'}>
                    {t('general.thankyou.text')}
                </Typography>
            </Box>
            <Box display={'flex'} my={3}>
                <AppStoreIcon />
                <IconsDivider />
                <GooglePlayIcon />
            </Box>
            <AppQrCode />
        </Box>
    ) : (
        ''
    )
}

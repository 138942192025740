import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import React, { useState } from 'react'
import Popup from '../PopUp'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import { Box, Button, Typography } from '@material-ui/core'
import { Customers } from '../../../api'
import {
    customerDeletedErrorMessage,
    customerDeletedSuccessMessage,
} from '../../../types/reduxNotificationTypes'
import { useDispatchNotification } from '../../../hooks/useDispatchNotification'

export default function DeleteCustomerPopUp({ open, setOpen, customerId }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const [isRqInProgress, setIsRqInProgress] = useState(false)
    const dispatchNotification = useDispatchNotification()

    const deleteCustomer = async () => {
        try {
            await Customers.deleteCustomer(customerId)
            dispatchNotification(customerDeletedSuccessMessage)
        } catch (error) {
            console.error(error)
            dispatchNotification(customerDeletedErrorMessage)
        }
    }

    const confirmDeleteProfile = async () => {
        setIsRqInProgress(true)
        await deleteCustomer(customerId)
        setIsRqInProgress(false)
        setOpen(false)
        history.push('/customers')
    }

    const closeDeleteProfilePopup = () => {
        setOpen(false)
    }

    const content = (
        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <WarningIcon width={56} />
            <Box mt={3}>
                <Typography variant={'h3'} align={'center'}>
                    {t('general.customer.information.deleteCustomer.confirmDelete')}
                </Typography>
            </Box>
        </Box>
    )

    const actions = (
        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <Button
                color={'primary'}
                variant={'contained'}
                onClick={confirmDeleteProfile}
                disabled={isRqInProgress}
            >
                {t('general.customer.create.popup.yes')}
            </Button>
            <Box mt={2}>
                <Button
                    style={{ minWidth: '140px' }}
                    variant={'outlined'}
                    onClick={closeDeleteProfilePopup}
                >
                    {t('general.customer.create.popup.no')}
                </Button>
            </Box>
        </Box>
    )

    return (
        <Popup
            onClose={closeDeleteProfilePopup}
            body={content}
            title={t('general.customer.information.deleteCustomer')}
            actions={actions}
            open={open}
        />
    )
}

import styled from 'styled-components'

export const FormWrapperPadding = styled.div`
    padding: 0 40px;
`

export const FormTitle = styled.div`
    display: flex;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4a4b;
    padding-bottom: 48px;
`
export const TextInfo = styled.div`
    font-size: 18px;
    font-weight: normal;
    line-height: 1.38;
    color: #808285;
    text-align: center;
`

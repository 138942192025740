import React from 'react'
import styled from 'styled-components'
import BackLinkArrow from '../Icons/BackLinkArrow'

const Wrapper = styled.div`
    padding: 50px 100px;
    position: relative;
    @media print {
        padding: ${(props) => (props.hideInPrint ? '0' : '50px 100px')};
    }
`

const TitleWrapper = styled.div`
    display: flex;
    color: #4b4a4b;
    font-size: 32px;
    margin-bottom: 45px;
    margin-right: 390px;
`

const PageTitle = styled.div`
    flex: 0 0 auto;
    font-weight: bold;
    margin-right: 20px;
`

const Subtitle = styled.div`
    flex: 0 0 auto;
`

export default function Page({
    children,
    title,
    subtitle,
    withBackLink,
    backLinkUrl,
    hideInPrint,
}) {
    return (
        <Wrapper hideInPrint>
            {withBackLink && <BackLinkArrow backLinkUrl={backLinkUrl} hideInPrint={hideInPrint} />}
            {title && (
                <div className={hideInPrint ? 'noprint' : ''}>
                    <TitleWrapper>
                        <PageTitle>{title}</PageTitle>
                        {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    </TitleWrapper>
                </div>
            )}
            {children}
        </Wrapper>
    )
}

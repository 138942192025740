import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React from 'react'
import { DateTime } from 'luxon'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { getDateFormat } from '../../util/util'

export function useSortableTableColumns() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { selectedLanguage } = useSelector((state) => state.options)
    const dateFormat = getDateFormat(selectedLanguage)

    const columns = React.useMemo(
        () => [
            {
                Header: 'general.customers.table.col.commission',
                title: 'general.customers.table.col.commission',
                accessor: 'commissionNumber',
                Cell: (props) => {
                    if (props.value === null) {
                        return '-'
                    } else {
                        return props.value
                    }
                },
            },
            {
                Header: 'general.customers.table.col.surname',
                title: 'general.customers.table.col.surname',
                accessor: 'lastName',
                Cell: (props) => {
                    if (props.value === null) {
                        return '-'
                    } else {
                        return props.value
                    }
                },
            },
            {
                Header: 'general.customers.table.col.firstName',
                title: 'general.customers.table.col.firstName',
                accessor: 'firstName',
                Cell: (props) => {
                    if (props.value === null) {
                        return '-'
                    } else {
                        return props.value
                    }
                },
            },
            {
                Header: 'general.customers.table.col.vin',
                title: 'general.customers.table.col.vin',
                accessor: 'vin',
                Cell: (props) => {
                    if (props.value === null) {
                        return '-'
                    } else {
                        return props.value.toUpperCase()
                    }
                },
            },
            {
                Header: 'general.customers.table.col.created',
                title: 'general.customers.table.col.created',
                accessor: (p) => {
                    return new Date(p['dateOfCreation']).getTime()
                },
                Cell: (props) => {
                    if (props.value === null || props.value === 0) {
                        return '-'
                    } else {
                        const date = new Date(props.value)
                        return (
                            <span>{DateTime.fromISO(date.toISOString()).toFormat(dateFormat)}</span>
                        )
                    }
                },
            },
            {
                Header: 'general.customers.table.col.deliveryDateToImporter',
                title: 'general.customers.table.col.deliveryDateToImporter',
                accessor: (p) => {
                    return new Date(p['deliveryDateToImporter']).getTime()
                },
                Cell: (props) => {
                    if (props.value === null || props.value === 0) {
                        return '-'
                    } else {
                        const date = new Date(props.value)
                        return DateTime.fromISO(date.toISOString()).toFormat(dateFormat)
                    }
                },
            },
            {
                Header: 'general.customers.table.col.status',
                title: 'general.customers.table.col.status',
                accessor: 'customerStatus',
                Cell: (props) => (props.value ? t(props.value) : '-'),
            },
            {
                Header: 'general.customers.table.col.vehicleStatus',
                title: 'general.customers.table.col.vehicleStatus',
                accessor: 'vehicleStatus',
                Cell: (props) => (props.value ? t(props.value) : '-'),
            },
        ],
        [dateFormat, t]
    )
    return [columns]
}

import { useEffect } from 'react'
import {
    allowAccessToCreateProcess,
    closeConsentsCreateDialog,
    setConsentsCreateDialogStatus,
} from '../actions/popupsActions'
import { CONSENTS_CREATE_DIALOG_STATUS_MAP } from '../util/util'
import { SET_CUSTOMER_DATA, CLEAR_ACCOUNT_CHECK_DATA } from '../types/reduxActionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { Customers } from '../api'
import { somethingWentWrongErrorMessage } from '../types/reduxNotificationTypes'
import { useDispatchNotification } from './useDispatchNotification'
import { useUpdateCustomerActions } from './actions/useUpdateCustomerActions'

export default function useOcwCreateUser() {
    const dispatch = useDispatch()
    const dispatchNotification = useDispatchNotification()
    const { saveOrderDetailsField } = useUpdateCustomerActions()
    const customer = useSelector((state) => state.customer)
    const { ocwRequestId } = useSelector((state) => state.oidc)

    useEffect(() => {
        const init = async () => {
            if (ocwRequestId) {
                try {
                    setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.ocw)
                    dispatch({
                        type: CLEAR_ACCOUNT_CHECK_DATA,
                    })
                    allowAccessToCreateProcess()
                    const response = await Customers.getOCWData(ocwRequestId)
                    dispatch({
                        type: SET_CUSTOMER_DATA,
                        payload: {
                            customer: {
                                ...customer,
                                summaryData: customer.consentVerification,
                                customerInformation: response.data.customerInformation,
                                orderDetails: {
                                    ...response.data?.orderDetails,
                                },
                            },
                        },
                    })
                    saveOrderDetailsField('createdByOcwData', true)
                } catch (e) {
                    dispatchNotification(somethingWentWrongErrorMessage)
                    closeConsentsCreateDialog()
                    console.error(e)
                }
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ocwRequestId])
}

import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { consentsEnum, TRANSLATIONS_NAMESPACE } from '../constants/global'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 750px;
`

export default function HorizontalStepper({ activeStep }) {
    const { consentVerification } = useSelector((state) => state.customer)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const stepLabels = consentVerification?.[consentsEnum.IDENT]
        ? [t('general.customer.title'), t('general.ident.title'), t('general.order.title')]
        : [t('general.customer.title'), t('general.order.title')]

    return (
        <Wrapper>
            <Stepper activeStep={activeStep} alternativeLabel style={{ background: 'none' }}>
                {stepLabels.map((label, index) => {
                    return (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Wrapper>
    )
}

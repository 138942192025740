import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { CloseCross } from '../global'

export default function PopUp({ title, body, onClose, open, actions }) {
    return (
        <Dialog open={!!open} onClose={onClose}>
            <DialogTitle disableTypography>
                {onClose ? (
                    <CloseCross onClick={onClose}>
                        <Close />
                    </CloseCross>
                ) : null}
                {title}
            </DialogTitle>
            <DialogContent>{body}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    )
}

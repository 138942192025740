import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isRegistrationProcess } from '../util/util'
import { useHistory } from 'react-router'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../constants/global'
import styled from 'styled-components'
import { SearchIcon } from './Icons/SearchIcon'
import { useSelector } from 'react-redux'
import { useSaveInputChange } from '../hooks/form/useSaveInputChange'
import InputTooltip from './Tooltip/InputTooltip'
import { Button } from '@material-ui/core'

const Wrapper = styled.div`
    margin-right: 40px;
    display: flex;
    flex-direction: row;
`

const SearchField = styled.div`
    min-width: 300px;
    display: flex;
    flex-direction: row;
    margin-right: 15px;
    border-bottom: 1px solid ${(props) => (props.active ? '#4ba82e' : '#c0c0c0')};
`

const Input = styled.input`
    width: 250px;
    box-sizing: border-box;
    border: none;
    padding-bottom: 7px;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: normal;
    color: #808285;
    &:focus {
        outline: none;
    }
`

export default function SearchBar() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { searchParam } = useSelector((state) => state.commissions)
    const { setSearchParam } = useSaveInputChange()

    const [searchString, setSearchString] = useState('')

    useEffect(() => {
        if (searchParam === null) {
            setSearchString('')
        } else {
            setSearchString(searchParam)
        }
    }, [searchParam])

    if (isRegistrationProcess()) {
        return null
    }

    const handleChange = (e) => {
        setSearchString(e.target.value)
    }

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        setSearchParam(searchString)
        history.push({
            pathname: '/customers',
        })
    }

    return (
        <Wrapper>
            <SearchField active={searchString.length > 0}>
                <SearchIcon />
                <Input
                    value={searchString}
                    onChange={handleChange}
                    id="search-input-id"
                    placeholder={t('general.search.placeholder', { replace: ignoreKeyInHTML })}
                    onKeyDown={handleEnter}
                />
                <InputTooltip text={t('general.header.search.tooltip')} alignv={'center'} />
            </SearchField>
            <Button
                style={{ minWidth: '100px', height: '30px' }}
                variant={'contained'}
                color={'primary'}
                size={'small'}
                onClick={handleSubmit}
                disabled={searchString.length === 0}
            >
                {t('general.search.button')}
            </Button>
        </Wrapper>
    )
}

import { store } from '../index'
import { I18n } from '../api'
import i18next from 'i18next'
import { SET_LANG_AND_TRANSLATIONS } from '../types/reduxActionTypes'
import {
    DEFAULT_CULTURE,
    SUPPORTED_LANGUAGES,
    SUPPORTED_LANGUAGES_CULTURES,
    TRANSLATIONS_NAMESPACE,
} from '../constants/global'

const initI18n = () => {
    const translations = store.getState().options.translations
    let resources = {}
    Object.entries(translations).forEach(([k, v]) => {
        resources[k] = {
            translation: v,
        }
        if (i18next.getResourceBundle(k, TRANSLATIONS_NAMESPACE) === undefined) {
            i18next.addResourceBundle(k, TRANSLATIONS_NAMESPACE, v)
        }
    })
}

export const getTranslations = (languages) => {
    const { loading, countries, translations } = store.getState().options
    const { userInfo } = store.getState().oidc
    if (userInfo !== null && countries.length > 0) {
        if (!languages) {
            const selectedCountry = countries.find((c) => c.countryCode === userInfo.country)
            const selectedLanguages = selectedCountry.languages.map(
                (c) => c + '-' + selectedCountry.countryCode
            )
            loadLanguages(selectedLanguages, translations, loading)
        } else {
            loadLanguages(languages, translations, loading)
        }
    } else {
        getDefaultTranslations()
    }
}

const createSelectedLanguages = () => {
    const browserLang = navigator.language

    if (browserLang) {
        if (SUPPORTED_LANGUAGES.includes(browserLang.substring(0, 2))) {
            const lang = SUPPORTED_LANGUAGES_CULTURES[browserLang.substring(0, 2)]
            return [lang]
        } else {
            return [DEFAULT_CULTURE]
        }
    } else {
        return [DEFAULT_CULTURE]
    }
}

export const getDefaultTranslations = () => {
    const { translations, loading } = store.getState().options
    const selectedLanguages = createSelectedLanguages()

    loadLanguages(selectedLanguages, translations, loading)
}

const loadLanguages = (selectedLanguages, translations, loading) => {
    let loaded = true
    selectedLanguages.forEach((l) => {
        if (translations[l] === null || translations[l] === undefined) {
            loaded = false
        }
    })
    if (loaded && Object.keys(translations).length > 0) {
        initI18n()
    } else {
        if (!loading) {
            Promise.all(selectedLanguages.map((l) => I18n.getCultureTranslations(l)))
                .then((responses) => {
                    responses.forEach((r, i) => {
                        store.dispatch({
                            type: SET_LANG_AND_TRANSLATIONS,
                            payload: {
                                language: selectedLanguages[i],
                                translations: r.data,
                            },
                        })
                    })
                    initI18n()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }
}

export const downloadAndDispatchTranslation = async (lang) => {
    try {
        const translationsResponse = await I18n.getCultureTranslations(lang)
        store.dispatch({
            type: SET_LANG_AND_TRANSLATIONS,
            payload: {
                language: lang,
                translations: translationsResponse.data,
            },
        })
        initI18n()
    } catch (e) {
        console.error(e)
    }
}

import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

export const CloseCross = styled(IconButton)`
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    border: 0;
    background: none;
`

import {
    SET_ACCESS_TO_CREATE_PROCESS,
    SET_CONSENTS_CREATE_DIALOG_STATUS,
    SET_HAS_SKODA_IDENT_ACTIVE,
    SET_LOGOUT_FROM_GRP_ACTIVE,
} from '../types/reduxActionTypes'
import { CONSENTS_CREATE_DIALOG_STATUS_MAP } from '../util/util'

const initialState = {
    consentsDialogStatus: CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive,
    hasSkodaIdentActive: false,
    logoutFromGRPPopupActive: false,
    accessToCreateProcess: false,
}
const userSignedOutAction = 'oidc/userSignedOut'
const userFoundAction = 'oidc/userFound'
const userLoadingAction = 'oidc/loadingUser'

export const popupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONSENTS_CREATE_DIALOG_STATUS:
            return {
                ...state,
                consentsDialogStatus: action.payload.value,
            }
        case SET_HAS_SKODA_IDENT_ACTIVE:
            return {
                ...state,
                hasSkodaIdentActive: action.payload.value,
            }
        case SET_LOGOUT_FROM_GRP_ACTIVE:
            return {
                ...state,
                logoutFromGRPPopupActive: action.payload.value,
            }
        case SET_ACCESS_TO_CREATE_PROCESS:
            return {
                ...state,
                accessToCreateProcess: action.payload.value,
            }
        case userFoundAction:
            return {
                ...state,
                logoutFromGRPPopupActive: false,
            }
        case userSignedOutAction:
        case userLoadingAction:
            return {
                ...initialState,
                accessToCreateProcess: state.accessToCreateProcess,
            }
        default:
            return state
    }
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import FullPagePopUp from './PopUp/FullPagePopUp'
import styled from 'styled-components'
import SearchBar from './SearchBar'
import { checkUrl, CONSENTS_CREATE_DIALOG_STATUS_MAP, isRegistrationProcess } from '../util/util'
import { SkodaLogo } from './Icons/SkodaLogo'
import { DealerAvatar } from './Icons/DealerAvatar'
import { UserManualLink } from './Icons/UserManualLink'
import MainMenu from './MainMenu'
import { useSaveInputChange } from '../hooks/form/useSaveInputChange'
import ConsentsCreateDialog from './ConsentsCreateDialog'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 60px;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
`

export default function Header() {
    const history = useHistory()
    const { setSearchParam } = useSaveInputChange()
    const { consentsDialogStatus } = useSelector((state) => state.popups)

    const handleHomePageClick = () => {
        if (!checkUrl('/')) {
            setSearchParam(null)
            history.push({
                pathname: '/customers',
            })
        }
    }

    return (
        <div className="noprint">
            <Wrapper>
                <SkodaLogo click={handleHomePageClick} />
                <MainMenu />
                {!isRegistrationProcess() && <SearchBar />}
                <UserManualLink />
                <DealerAvatar />
                <FullPagePopUp />
            </Wrapper>
            {consentsDialogStatus !== CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive && (
                <ConsentsCreateDialog />
            )}
        </div>
    )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import {
    ignoreKeyInHTML,
    TRANSLATIONS_NAMESPACE,
    vehicleIdentificationDefaultValue,
    vehicleIdentificationPlaceholder,
} from '../../constants/global'
import { getTranslations } from '../../actions/i18nActions'

export function TermsOfUseContent() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    getTranslations()

    return (
        <div className="content-wrapper--print">
            <div className="content-wrapper">
                {parse(
                    t('general.documents.consent.description', {
                        replace: ignoreKeyInHTML,
                    }).replace(vehicleIdentificationPlaceholder, vehicleIdentificationDefaultValue)
                )}
                {parse(
                    t('general.documents.consent.categories.title', { replace: ignoreKeyInHTML })
                )}
                <ul className="caret-list">
                    {parse(
                        t('general.documents.consent.categories.content', {
                            replace: ignoreKeyInHTML,
                        })
                    )}
                    {parse(
                        t('general.documents.consent.agreement.title', { replace: ignoreKeyInHTML })
                    )}
                </ul>
                <div className="caret-list">
                    {parse(
                        t('general.documents.consent.agreement.content', {
                            replace: ignoreKeyInHTML,
                        })
                    )}
                </div>
                <div style={{ height: '30px' }} />
                <p>
                    {parse(t('general.documents.consent.date', { replace: ignoreKeyInHTML }))}
                    <input type="text" className="signature" />
                </p>
                <div style={{ height: '10px' }} />
                <p>
                    {parse(t('general.documents.consent.signature', { replace: ignoreKeyInHTML }))}
                    <input type="text" className="signature" />
                </p>
            </div>
        </div>
    )
}

// customer
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA'
export const ADD_CUSTOMER_INFO_FROM_GROUP_ID = 'ADD_CUSTOMER_INFO_FROM_GROUP_ID'
export const RESET_CUSTOMER = 'RESET_CUSTOMER'
// resets customer data in store to initial values with specified values (data) changed
export const RESET_CUSTOMER_WITH_DATA = 'RESET_CUSTOMER_WITH_DATA'

// account check
export const SET_ACCOUNT_CHECK_DATA = 'SET_ACCOUNT_CHECK_DATA'
export const CLEAR_ACCOUNT_CHECK_DATA = 'CLEAR_ACCOUNT_CHECK_DATA'

// customer information
export const SET_CUSTOMER_INFORMATION_ITEM = 'SET_CUSTOMER_INFORMATION_ITEM'
export const SET_CUSTOMER_INFORMATION_ADDRESS_FIELD = 'SET_CUSTOMER_INFORMATION_ADDRESS_FIELD'

// skoda ident
export const SET_SKODA_IDENT_ITEM = 'SET_SKODA_IDENT_ITEM'

// order details
export const SET_ORDER_DETAILS_ITEM = 'SET_ORDER_DETAILS_ITEM'

// consent verification
export const SET_CONSENT_VERIFICATION_ITEM = 'SET_CONSENT_VERIFICATION_ITEM'
export const SET_CONSENT_ID = 'SET_CONSENT_ID'
export const SET_CONSENT_TYPE = 'SET_CONSENT_TYPE'

// service partners
export const SET_PSP = 'SET_PSP'

// popups
export const SET_CONSENTS_CREATE_DIALOG_STATUS = 'SET_CONSENTS_CREATE_DIALOG_STATUS'
export const SET_HAS_SKODA_IDENT_ACTIVE = 'SET_HAS_SKODA_IDENT_ACTIVE'
export const SET_LOGOUT_FROM_GRP_ACTIVE = 'SET_LOGOUT_FROM_GRP_ACTIVE'
export const SET_ACCESS_TO_CREATE_PROCESS = 'SET_ACCESS_TO_CREATE_PROCESS'

// search
export const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM'

// options
export const SET_OPTIONS = 'SET_OPTIONS'

// i18n
export const SET_LANG_AND_TRANSLATIONS = 'SET_LANG_AND_TRANSLATIONS'

// language selector
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE'

// authentication
export const REMOVE_CONTEXT_URI = 'REMOVE_CONTEXT_URI'
export const SET_CONTEXT_URI = 'SET_CONTEXT_URI'
export const SET_ID_TOKEN = 'SET_ID_TOKEN'

// Expired licenses
export const SET_EXPIRED_LICENSES = 'SET_EXPIRED_LICENSES'

import React, { useEffect, useState } from 'react'
import { MuiThemeProvider } from 'material-ui'
import HorizontalStepper from '../../components/HorizontalStepper'
import { CUSTOMER_INFORMATION_STEP } from '../../constants/registrationConstants'
import { useSelector } from 'react-redux'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import Panel from '../../components/Wrappers/Panel'
import { useHistory } from 'react-router-dom'
import {
    getErrorText,
    isCustomerInformationValid,
    validateCustomerInformation,
} from '../../util/validators'
import { setConsentsCreateDialogStatus, showHasSkodaIdentPopup } from '../../actions/popupsActions'
import { useTranslation } from 'react-i18next'
import { INPUT_LONG } from '../../constants/defaultFormValues'
import { consentsEnum, ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { CONSENTS_CREATE_DIALOG_STATUS_MAP } from '../../util/util'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { FormTitle } from '../../components/FormElements'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { countryPhoneCodes } from '../../constants/countryPhoneCodesConfiguration'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import RequiredBox from '../../components/RequiredBox'
import { Box, Button, Grid, MenuItem, TextField } from '@material-ui/core'
import LabelWithTooltip from '../../components/LabelWithTooltip'

function CreateCustomerInformation() {
    const { customerInformation, consentVerification } = useSelector((state) => state.customer)
    const { accountCheck } = useSelector((state) => state.account)
    const { accessToCreateProcess } = useSelector((state) => state.popups)
    const { countries, selectedLanguage } = useSelector((state) => state.options)
    const [errors, setErrors] = useState({})

    let history = useHistory()
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { saveCustomerInformationField, saveCustomerInformationItem } = useUpdateCustomerActions()

    useEffect(() => {
        if (!accessToCreateProcess) {
            history.push({
                pathname: '/customers',
            })
        } else {
            if (!customerInformation.dialCode) {
                const userDialCode = countries.find(
                    (item) => customerInformation.address?.countryId === item.id.toString()
                )?.dialCode
                userDialCode && saveCustomerInformationItem('dialCode', userDialCode)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToCreateProcess, history])

    const onNextClick = () => {
        const validationErrors = validateCustomerInformation(customerInformation)
        setErrors(validationErrors)
        if (accountCheck?.connectAccount || isCustomerInformationValid(validationErrors)) {
            if (accountCheck !== null) {
                if (!consentVerification?.[consentsEnum.IDENT]) {
                    history.push('/vehicle-details')
                } else if (accountCheck.skodaIdentAccount) {
                    showHasSkodaIdentPopup()
                } else {
                    history.push('/skoda-ident')
                }
            }
        }
    }

    return (
        <ScrollTop>
            <MuiThemeProvider>
                <FormWrapper>
                    <HorizontalStepper activeStep={CUSTOMER_INFORMATION_STEP} />
                    <Panel>
                        <FormTitle>{t('general.customer.title')}</FormTitle>
                        <form noValidate autoComplete="off">
                            <TextField
                                name={'email'}
                                onChange={saveCustomerInformationField}
                                label={
                                    <LabelWithTooltip
                                        tooltipText={t('general.customer.email.tooltip')}
                                        labelText={t('general.customer.email')}
                                    />
                                }
                                value={customerInformation.email}
                                fullWidth
                                disabled
                                required
                            />
                            <TextField
                                name={'firstName'}
                                onChange={saveCustomerInformationField}
                                label={t('general.customer.firstName')}
                                value={customerInformation.firstName || ''}
                                fullWidth
                                error={!!errors.firstName}
                                helperText={
                                    errors.firstName && (
                                        <>
                                            {t('general.customer.firstName')}{' '}
                                            {t(getErrorText(errors.firstName))}
                                        </>
                                    )
                                }
                                disabled={accountCheck?.connectAccount}
                                inputProps={{ maxLength: INPUT_LONG }}
                                required={!accountCheck?.connectAccount}
                            />
                            <TextField
                                name={'lastName'}
                                onChange={saveCustomerInformationField}
                                label={t('general.customer.lastName')}
                                value={customerInformation.lastName || ''}
                                fullWidth
                                error={!!errors.lastName}
                                helperText={
                                    errors.lastName && (
                                        <>
                                            {t('general.customer.lastName')}{' '}
                                            {t(getErrorText(errors.lastName))}
                                        </>
                                    )
                                }
                                disabled={accountCheck?.connectAccount}
                                inputProps={{ maxLength: INPUT_LONG }}
                                required={!accountCheck?.connectAccount}
                            />
                            <TextField
                                name={'middleName'}
                                onChange={saveCustomerInformationField}
                                label={t('general.customer.middleName')}
                                value={customerInformation.middleName || ''}
                                error={!!errors.middleName}
                                helperText={
                                    errors.middleName && (
                                        <>
                                            {t('general.customer.middleName')}{' '}
                                            {t(getErrorText(errors.middleName))}
                                        </>
                                    )
                                }
                                disabled={accountCheck?.connectAccount}
                                inputProps={{ maxLength: INPUT_LONG }}
                            />
                            <TextField
                                name={'nickname'}
                                onChange={saveCustomerInformationField}
                                label={t('general.customer.nickname')}
                                value={customerInformation.nickname || ''}
                                disabled={accountCheck?.connectAccount}
                                inputProps={{ maxLength: INPUT_LONG }}
                                error={!!errors.nickname}
                                helperText={
                                    errors.nickname && (
                                        <>
                                            {t('general.customer.nickname')}{' '}
                                            {t(getErrorText(errors.nickname))}
                                        </>
                                    )
                                }
                                required={!accountCheck?.connectAccount}
                            />
                            {!accountCheck?.connectAccount && (
                                <TextField
                                    select
                                    name={'dialCode'}
                                    onChange={saveCustomerInformationField}
                                    label={t('general.customer.countryCode')}
                                    value={customerInformation.dialCode || ''}
                                    error={!!errors.dialCode}
                                    helperText={
                                        errors.dialCode && (
                                            <>
                                                {t('general.customer.countryCode')}{' '}
                                                {t(getErrorText(errors.dialCode))}
                                            </>
                                        )
                                    }
                                    disabled={accountCheck?.connectAccount}
                                    required={!!customerInformation.phoneNumber}
                                >
                                    {[
                                        {
                                            value: '',
                                            label: t('general.select.choose'),
                                        },
                                        ...countryPhoneCodes
                                            .map((item) => ({
                                                value: item.dial_code,
                                                label: `(${item.dial_code}) ${t(
                                                    `general.country.${item.code}`,
                                                    { replace: ignoreKeyInHTML }
                                                )}`,
                                                countryNameTranslated: t(
                                                    `general.country.${item.code}`,
                                                    {
                                                        replace: ignoreKeyInHTML,
                                                    }
                                                ),
                                            }))
                                            .sort((a, b) =>
                                                a.countryNameTranslated.localeCompare(
                                                    b.countryNameTranslated,
                                                    selectedLanguage.substring(0, 2)
                                                )
                                            ),
                                    ].map((item, index) => (
                                        <MenuItem value={item.value} key={index}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            <TextField
                                name={'phoneNumber'}
                                onChange={saveCustomerInformationField}
                                label={t('general.customer.phoneNumber')}
                                value={
                                    customerInformation.phoneNumber === null
                                        ? ''
                                        : customerInformation.phoneNumber
                                }
                                error={!!errors.phoneNumber}
                                helperText={
                                    errors.phoneNumber && (
                                        <>
                                            {t('general.customer.phoneNumber')}{' '}
                                            {t(getErrorText(errors.phoneNumber))}
                                        </>
                                    )
                                }
                                disabled={accountCheck?.connectAccount}
                            />
                        </form>
                        {!accountCheck?.connectAccount && <RequiredBox withTop />}
                        <Box mt={4}>
                            <Grid container spacing={2} justifyContent={'center'}>
                                <Grid item>
                                    <Button
                                        style={{ minWidth: 'auto' }}
                                        variant={'outlined'}
                                        onClick={() =>
                                            setConsentsCreateDialogStatus(
                                                CONSENTS_CREATE_DIALOG_STATUS_MAP.cancel
                                            )
                                        }
                                    >
                                        {t('general.button.cancel')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color={'primary'}
                                        variant={'contained'}
                                        onClick={onNextClick}
                                    >
                                        {t(
                                            consentVerification?.[consentsEnum.IDENT]
                                                ? 'general.button.continue.ident'
                                                : 'general.button.continue.order'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Panel>
                </FormWrapper>
            </MuiThemeProvider>
        </ScrollTop>
    )
}

export default WithHeader(CreateCustomerInformation)

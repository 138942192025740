import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import {
    RESET_CUSTOMER,
    RESET_CUSTOMER_WITH_DATA,
    SET_CONSENT_VERIFICATION_ITEM,
    SET_CUSTOMER_INFORMATION_ITEM,
    SET_CUSTOMER_INFORMATION_ADDRESS_FIELD,
    SET_ORDER_DETAILS_ITEM,
    SET_SKODA_IDENT_ITEM,
} from '../../types/reduxActionTypes'

export function useUpdateCustomerActions() {
    const dispatch = useDispatch()

    const saveConsentVerificationField = useCallback(
        (name, value) => {
            dispatch({
                type: SET_CONSENT_VERIFICATION_ITEM,
                payload: {
                    name,
                    value,
                },
            })
        },
        [dispatch]
    )

    const saveOrderDetailsField = useCallback(
        (name, value) => {
            dispatch({
                type: SET_ORDER_DETAILS_ITEM,
                payload: {
                    name,
                    value: name === 'vin' ? value.toUpperCase() : value,
                },
            })
        },
        [dispatch]
    )

    const saveSkodaIdentField = useCallback(
        (name, value) => {
            dispatch({
                type: SET_SKODA_IDENT_ITEM,
                payload: {
                    name,
                    value,
                },
            })
        },
        [dispatch]
    )

    const clearCustomer = useCallback(() => {
        dispatch({
            type: RESET_CUSTOMER,
        })
    }, [dispatch])

    const resetCustomerWithData = useCallback(
        (data) => {
            dispatch({
                type: RESET_CUSTOMER_WITH_DATA,
                payload: data,
            })
        },
        [dispatch]
    )

    const saveCustomerInformationField = useCallback(
        (event) => {
            event.persist()
            dispatch({
                type: SET_CUSTOMER_INFORMATION_ITEM,
                payload: {
                    name: event.target.name,
                    value: event.target.value,
                },
            })
        },
        [dispatch]
    )

    const saveCustomerInformationItem = useCallback(
        (name, value) => {
            dispatch({
                type: SET_CUSTOMER_INFORMATION_ITEM,
                payload: {
                    name,
                    value,
                },
            })
        },
        [dispatch]
    )

    const saveCustomerInformationAddressItem = useCallback(
        (name, value) => {
            dispatch({
                type: SET_CUSTOMER_INFORMATION_ADDRESS_FIELD,
                payload: {
                    name,
                    value,
                },
            })
        },
        [dispatch]
    )

    return {
        saveConsentVerificationField,
        saveOrderDetailsField,
        saveSkodaIdentField,
        clearCustomer,
        resetCustomerWithData,
        saveCustomerInformationField,
        saveCustomerInformationItem,
        saveCustomerInformationAddressItem,
    }
}

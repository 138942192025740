import React from 'react'
import InputTooltip from './Tooltip/InputTooltip'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TRANSLATIONS_NAMESPACE } from '../constants/global'
import { getDateFormat } from '../util/util'
import { useDispatch, useSelector } from 'react-redux'
import { SET_EXPIRED_LICENSES } from '../types/reduxActionTypes'
import { CONSENTS_CREATE_DIALOG_STATUS_MAP, isRegistrationProcess } from '../util/util'
import { DateTime } from 'luxon'

const PopUpWrapper = styled.div`
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1500;
    width: 30%;
    border-radius: 15px;
    background: #f75a41;
    padding: 10px;
`

const AlertClose = styled.button`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 3px;
    top: 3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    font-size: 10px;
    padding: 0;
    border: none;
    cursor: pointer;
    transform: translate(50%, -50%);

    &:hover {
        background-color: #d0d0d0;
    }
`

export function ExpiredLicenses() {
    const dispatch = useDispatch()
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { consentsDialogStatus } = useSelector((state) => state.popups)
    const expiredLicenses = useSelector((state) => state.expiredLicenses)
    const { selectedLanguage } = useSelector((state) => state.options)
    const dateFormat = getDateFormat(selectedLanguage)

    return ((expiredLicenses?.expired && expiredLicenses?.expired.length > 0) ||
        (expiredLicenses?.expires && expiredLicenses?.expires.length > 0)) &&
        (consentsDialogStatus !== CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive ||
            isRegistrationProcess()) ? (
        <PopUpWrapper>
            <Typography style={{ fontWeight: 600 }}>{t('expiredLicenses.title')}</Typography>
            <Box>
                {expiredLicenses?.expired?.map((license) => {
                    return (
                        <Typography variant={'body1'} key={license.name}>
                            {t('expiredLicenses.license')} {license.name}{' '}
                            <InputTooltip text={t(license.description)} size={'small'} />{' '}
                            {t('expiredLicenses.isExpired')}
                        </Typography>
                    )
                })}
            </Box>
            <Box>
                {expiredLicenses?.expires?.map((license) => {
                    return (
                        <Typography variant={'body1'} key={license.name}>
                            {t('expiredLicenses.license')} {license.name}{' '}
                            <InputTooltip text={t(license.description)} size={'small'} />{' '}
                            {t('expiredLicenses.expires')}{' '}
                            {license.expirationDate
                                ? `${DateTime.fromJSDate(new Date(license.expirationDate)).toFormat(
                                      dateFormat
                                  )}`
                                : ''}
                        </Typography>
                    )
                })}
            </Box>
            <AlertClose
                onClick={(e) => {
                    dispatch({
                        type: SET_EXPIRED_LICENSES,
                        payload: {},
                    })
                }}
                data-clickaway-callback={'preventClose'}
            >
                &times;
            </AlertClose>
        </PopUpWrapper>
    ) : (
        <></>
    )
}

import React from 'react'
import styled from 'styled-components'

const CardBox = styled.div`
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: white;
    padding: 40px 56px;
`

export const CardTitle = styled.div`
    color: #4b4a4b;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`

export default function Card({ children }) {
    return <CardBox>{children}</CardBox>
}

import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
    ignoreKeyInHTML,
    journeyStatusStatesEnum,
    orderCancelledKey,
    TRANSLATIONS_NAMESPACE,
} from '../constants/global'
import { MediumWidthCard } from './Wrappers/MediumWidthCard'
import { formatDateIntoYYYYMMDDWithDashes } from '../util/formatters'
import PopUp from './PopUp/PopUp'
import { WarningIcon } from './Icons/WarningIcon'
import { Customers, EmailNotifications, Vehicles } from '../api'
import { useDispatchNotification } from '../hooks/useDispatchNotification'
import {
    sendEmailErrorMessage,
    sendEmailSuccessMessage,
    setIdentErrorMessage,
    setIdentSuccessMessage,
    setPspErrorMessage,
    setDmsErrorMessage,
    setPspSuccessMessage,
    setDmsSuccessMessage,
} from '../types/reduxNotificationTypes'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import { getDateFormat } from '../util/util'
import { useSelector } from 'react-redux'

const ListWrapper = styled.div`
    margin-top: 36px;
`

const List = styled.div`
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
`

const Row = styled.div`
    display: flex;
    min-height: 40px;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
     {
        ${({ state }) => {
            if (state === journeyStatusStatesEnum.COMPLETED) {
                return `state: #4ba82e;`
            } else if (state === journeyStatusStatesEnum.FAILED) {
                return `color: #f44336;`
            } else if (state === journeyStatusStatesEnum.NOT_REQUIRED) {
                return `color: #808285;`
            } else {
                return `color: #000;`
            }
        }}
`

const StatusText = styled.div`
    flex: 1 0 auto;
`

const JourneyTHRow = styled.div`
    display: flex;
    padding: 0 56px;
    margin: 20px 0 10px;
    text-transform: uppercase;
    color: #808285;
    font-weight: bold;
    font-size: 13px;
`

const JourneyColStatus = styled.div`
    flex: 0 0 40%;
`

const JourneyColDetail = styled.div`
    flex: 1 0 auto;
`

const JourneyColAction = styled.div`
    display: flex;
    flex: 0 0 10%;
    justify-content: center;
`

const JourneyColDone = styled.div`
    display: flex;
    justify-content: center;
    flex: 0 0 50px;
     {
        ${({ state }) => {
            if (state === journeyStatusStatesEnum.COMPLETED) {
                return `color: #4ba82e;`
            } else if (state === journeyStatusStatesEnum.FAILED) {
                return `color: #f44336;`
            } else if (state === journeyStatusStatesEnum.NOT_REQUIRED) {
                return `color: #808285;`
            } else {
                return `color: #000;`
            }
        }}
    }
`

const SectionTitle = styled.h3`
    margin-left: -20px;
`

const SectionSubTitle = styled.h3`
    font-weight: normal;
`

const buttonActions = {
    action: 'ACTION',
    repeatedAction: 'REPEATED_ACTION',
}

// journey statuses with action helper object with text definitions
const statusesWithActionContent = {
    skodaIdInvitationSent: {
        [buttonActions.action]: {
            actionButton: 'general.button.sendEmail',
            popupTitle: 'general.journey.popup.sendEmail.title',
            popupContent: 'general.journey.popup.sendEmail.warning',
        },
        [buttonActions.repeatedAction]: {
            actionButton: 'general.button.resendEmail',
            popupTitle: 'general.journey.popup.sendEmail.title',
            popupContent: 'general.journey.popup.resendEmail.warning',
        },
    },
    mobileAppInvitationSent: {
        [buttonActions.action]: {
            actionButton: 'general.button.sendEmail',
            popupTitle: 'general.journey.popup.sendEmail.title',
            popupContent: 'general.journey.popup.sendEmail.warning',
        },
        [buttonActions.repeatedAction]: {
            actionButton: 'general.button.resendEmail',
            popupTitle: 'general.journey.popup.sendEmail.title',
            popupContent: 'general.journey.popup.resendEmail.warning',
        },
    },
    skodaIdentVerificationStatus: {
        [buttonActions.action]: {
            actionButton: 'general.button.setIdent',
            popupTitle: '',
            popupContent: 'general.button.setIdent.warning',
        },
    },
    powerPassInvitationSent: {
        [buttonActions.action]: {
            actionButton: 'general.button.sendEmail',
            popupTitle: '',
            popupContent: 'general.journey.popup.sendEmail.warning',
        },
        [buttonActions.repeatedAction]: {
            actionButton: 'general.button.resendEmail',
            popupTitle: '',
            popupContent: 'general.journey.popup.resendEmail.warning',
        },
    },
    preferredServicePartnerSet: {
        [buttonActions.action]: {
            actionButton: 'general.journey.vehicle.button.setPsp',
            popupTitle: '',
            popupContent: 'general.journey.vehicle.button.setDms.warning',
        },
    },
    dealerMaintenanceSchedulingEnablement: {
        [buttonActions.action]: {
            actionButton: 'general.journey.vehicle.button.setDms',
            popupTitle: '',
            popupContent: 'general.journey.vehicle.button.setDms.warning',
        },
    },
    consent: {
        [buttonActions.action]: {
            actionButton: 'general.button.sendEmail',
            popupTitle: '',
            popupContent: 'general.journey.popup.sendEmail.warning',
        },
    },
    ENROLL_CAR_INVITATION_SENT: {
        [buttonActions.action]: {
            actionButton: 'general.button.sendEmail',
            popupTitle: '',
            popupContent: 'general.journey.popup.sendEmail.warning',
        },
        [buttonActions.repeatedAction]: {
            actionButton: 'general.button.resendEmail',
            popupTitle: '',
            popupContent: 'general.journey.popup.resendEmail.warning',
        },
    },
}

const CheckMark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 21" width="30" height="21">
        <path
            d="m26.682.54-15.368 15.3-8.5-8.5L.366 9.788 11.314 20.77 29.13 2.988 26.682.54Z"
            fill="currentColor"
        />
    </svg>
)

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path
            d="m23.39 20.974-8.942-8.942L23.39 3.09 20.942.642 12 9.584 3.058.642.61 3.09l8.942 8.942L.61 20.974l2.448 2.448L12 14.48l8.942 8.942 2.448-2.448Z"
            fill="currentColor"
        />
    </svg>
)

const StatusRow = ({
    statusId,
    statusValue,
    journeyType,
    vehicleId,
    setVehicleIdFromActionButton,
    setIsConfirmationPopupOpenWithStatusData,
}) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const actionClickHandler = () => {
        setVehicleIdFromActionButton && vehicleId && setVehicleIdFromActionButton(vehicleId)
        setIsConfirmationPopupOpenWithStatusData({
            ...statusValue,
            statusId,
            content: statusesWithActionContent[statusId][statusValue.actionButton],
        })
    }

    return (
        <Row state={statusValue.state}>
            <JourneyColStatus>
                <StatusText>{t(`general.statuses.${journeyType}.${statusId}`)}</StatusText>
            </JourneyColStatus>
            <JourneyColDetail>
                {statusValue.stepDescription.value &&
                    `${formatDateIntoYYYYMMDDWithDashes(
                        new Date(statusValue.stepDescription.value)
                    )}: `}
                {t(statusValue.stepDescription.key)}
            </JourneyColDetail>
            <JourneyColAction>
                {statusValue.actionButton !== 'NONE' &&
                    statusesWithActionContent[statusId][statusValue.actionButton] && (
                        <Button
                            size={'small'}
                            onClick={actionClickHandler}
                            color={'primary'}
                            variant={'contained'}
                            title={t(
                                `${
                                    statusesWithActionContent[statusId][statusValue.actionButton]
                                        .actionButton
                                }.tooltip`,
                                { replace: ignoreKeyInHTML }
                            )}
                        >
                            {t(
                                statusesWithActionContent[statusId][statusValue.actionButton]
                                    .actionButton
                            )}
                        </Button>
                    )}
            </JourneyColAction>
            <JourneyColDone state={statusValue.state}>
                {(statusValue.state === journeyStatusStatesEnum.NOT_REQUIRED ||
                    statusValue.state === journeyStatusStatesEnum.FAILED) && <CloseIcon />}
                {statusValue.state === journeyStatusStatesEnum.COMPLETED && <CheckMark />}
            </JourneyColDone>
        </Row>
    )
}

export default function Journey({ customer, statuses, getStatusesData }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const [isConfirmationPopupOpenWithStatusData, setIsConfirmationPopupOpenWithStatusData] =
        useState(null)
    const [vehicleIdFromActionButton, setVehicleIdFromActionButton] = useState(null)
    const dispatchNotification = useDispatchNotification()
    const { selectedLanguage } = useSelector((state) => state.options)
    const dateFormat = getDateFormat(selectedLanguage)

    const { customerJourneyStatuses, vehicleJourneyStatuses } = statuses

    const submitConfirmEmailPopup = async () => {
        await submitCallAction()
        setIsConfirmationPopupOpenWithStatusData(null)
    }

    const sendConsentEmail = async () => {
        try {
            await EmailNotifications.sendDigitalConsentEmailNotification(vehicleIdFromActionButton)
            dispatchNotification(sendEmailSuccessMessage)
        } catch (e) {
            console.error(e)
            dispatchNotification(sendEmailErrorMessage)
        }
    }

    const setIdent = async () => {
        try {
            await Customers.setSkodaIdent(customer.id)
            dispatchNotification(setIdentSuccessMessage)
        } catch (e) {
            console.error(e)
            dispatchNotification(setIdentErrorMessage)
        }
    }

    const setVehiclePSP = async () => {
        try {
            await Vehicles.setPSP(vehicleIdFromActionButton)
            getStatusesData(customer.id)
            dispatchNotification(setPspSuccessMessage)
        } catch (e) {
            console.error(e)
            dispatchNotification(setPspErrorMessage)
        }
    }

    const setVehicleDMS = async () => {
        try {
            await Vehicles.setDMS(vehicleIdFromActionButton)
            getStatusesData(customer.id)
            dispatchNotification(setDmsSuccessMessage)
        } catch (e) {
            console.error(e)
            dispatchNotification(setDmsErrorMessage)
        }
    }

    const sendEmailNotification = async (type, customParams = undefined) => {
        try {
            await EmailNotifications.sendEmailNotification(customer.id, type, customParams)
            dispatchNotification(sendEmailSuccessMessage)
        } catch (e) {
            console.error(e)
            dispatchNotification(sendEmailErrorMessage)
        }
    }

    const submitCallAction = async () => {
        if (isConfirmationPopupOpenWithStatusData.statusId === 'skodaIdInvitationSent') {
            await sendEmailNotification('GROUP_ID_REGISTRATION_INVITATION')
        } else if (isConfirmationPopupOpenWithStatusData.statusId === 'mobileAppInvitationSent') {
            await sendEmailNotification('INSTALL_MOBILE_APP')
        } else if (
            isConfirmationPopupOpenWithStatusData.statusId === 'skodaIdentVerificationStatus'
        ) {
            await setIdent()
        } else if (isConfirmationPopupOpenWithStatusData.statusId === 'powerPassInvitationSent') {
            await sendEmailNotification('REGISTER_POWERPASS', {
                vehicleId: vehicleIdFromActionButton,
            })
        } else if (
            isConfirmationPopupOpenWithStatusData.statusId === 'preferredServicePartnerSet'
        ) {
            await setVehiclePSP()
        } else if (
            isConfirmationPopupOpenWithStatusData.statusId ===
            'dealerMaintenanceSchedulingEnablement'
        ) {
            await setVehicleDMS()
        } else if (isConfirmationPopupOpenWithStatusData.statusId === 'consent') {
            await sendConsentEmail()
        } else if (
            isConfirmationPopupOpenWithStatusData.statusId === 'ENROLL_CAR_INVITATION_SENT'
        ) {
            await sendEmailNotification('ENROLL_CAR_INVITATION_SENT', {
                vehicleId: vehicleIdFromActionButton,
            })
        }
    }

    return (
        <>
            <JourneyTHRow>
                <JourneyColStatus>{t('general.journey.status')}</JourneyColStatus>
                <JourneyColDetail>{t('general.detail')}</JourneyColDetail>
                <JourneyColDone>{t('general.done')}</JourneyColDone>
            </JourneyTHRow>
            <MediumWidthCard>
                <ListWrapper>
                    <SectionTitle>{t('general.journey.customer')}</SectionTitle>
                    <List>
                        {customerJourneyStatuses &&
                            Object.entries(customerJourneyStatuses)
                                // MSP-51523 - Hide ident
                                .filter(([s]) => s !== 'skodaIdentVerificationStatus')
                                .map(([key, value]) => (
                                    <StatusRow
                                        key={key}
                                        statusId={key}
                                        statusValue={value}
                                        journeyType={'customer'}
                                        setIsConfirmationPopupOpenWithStatusData={
                                            setIsConfirmationPopupOpenWithStatusData
                                        }
                                    />
                                ))}
                    </List>
                    {vehicleJourneyStatuses &&
                        vehicleJourneyStatuses.map((vehicle, index) => (
                            <Fragment key={vehicle.vehicleId}>
                                <Grid container justifyContent={'space-between'}>
                                    <Grid item>
                                        <SectionTitle>
                                            {t('general.journey.vehicle')} ({vehicle.vehicleOrderId}
                                            ) {index + 1}/{vehicleJourneyStatuses.length}
                                            {vehicle.modelName ? ` - ${vehicle.modelName}` : ''}
                                        </SectionTitle>
                                    </Grid>
                                    <Grid item>
                                        <SectionSubTitle>
                                            {!!vehicle?.orderStatus?.checkpointCode && (
                                                <>
                                                    <b>
                                                        {t(
                                                            'general.vehicle.orderStatus.orderStatus'
                                                        )}
                                                        :
                                                    </b>{' '}
                                                    {t(
                                                        `general.vehicle.orderStatus.${vehicle.orderStatus.checkpointCode}`
                                                    )}
                                                    {vehicle?.orderStatus?.checkpointCode &&
                                                    vehicle?.orderStatus?.checkpointDate
                                                        ? ` (${DateTime.fromJSDate(
                                                              new Date(
                                                                  vehicle.orderStatus.checkpointDate
                                                              )
                                                          ).toFormat(dateFormat)})`
                                                        : ''}
                                                </>
                                            )}
                                        </SectionSubTitle>
                                    </Grid>
                                </Grid>
                                <List>
                                    {Object.entries(vehicle)
                                        .filter(
                                            ([key, value]) =>
                                                !!value?.stepDescription &&
                                                ((key === orderCancelledKey &&
                                                    value?.state ===
                                                        journeyStatusStatesEnum.FAILED) ||
                                                    key !== orderCancelledKey)
                                        )
                                        .map(([key, value]) => (
                                            <StatusRow
                                                key={key}
                                                statusId={key}
                                                statusValue={value}
                                                journeyType={'vehicle'}
                                                vehicleId={vehicle.vehicleId}
                                                setIsConfirmationPopupOpenWithStatusData={
                                                    setIsConfirmationPopupOpenWithStatusData
                                                }
                                                setVehicleIdFromActionButton={
                                                    setVehicleIdFromActionButton
                                                }
                                            />
                                        ))}
                                </List>
                            </Fragment>
                        ))}
                </ListWrapper>
            </MediumWidthCard>
            <PopUp
                onClose={() => setIsConfirmationPopupOpenWithStatusData(null)}
                open={isConfirmationPopupOpenWithStatusData}
                body={
                    isConfirmationPopupOpenWithStatusData ? (
                        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
                            <WarningIcon width={56} />
                            <Box mt={3}>
                                <Typography variant={'h3'} align={'center'}>
                                    {t(isConfirmationPopupOpenWithStatusData.content.popupContent)}
                                </Typography>
                            </Box>
                        </Box>
                    ) : null
                }
                title={
                    isConfirmationPopupOpenWithStatusData &&
                    isConfirmationPopupOpenWithStatusData.content?.popupTitle
                        ? t(isConfirmationPopupOpenWithStatusData.content.popupTitle)
                        : null
                }
                actions={
                    <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => submitConfirmEmailPopup()}
                        >
                            {t('general.yes')}
                        </Button>
                        <Box mt={2}>
                            <Button
                                style={{ minWidth: '140px' }}
                                variant={'outlined'}
                                onClick={() => setIsConfirmationPopupOpenWithStatusData(null)}
                            >
                                {t('general.no')}
                            </Button>
                        </Box>
                    </Box>
                }
            />
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_CULTURE, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { WarningIcon } from '../../components/Icons/WarningIcon'
import styled from 'styled-components'
import { getDefaultTranslations } from '../../actions/i18nActions'
import ConfigurationHolder from '../../configurationHolder'
import { useSelector } from 'react-redux'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import { Box, Typography } from '@material-ui/core'

const Link = styled.a`
    color: #4ba82e;
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
`

export default function NotLoggedInPage() {
    const { t, i18n } = useTranslation(TRANSLATIONS_NAMESPACE)

    const { translations, loading } = useSelector((state) => state.options)
    const [loaded, setLoaded] = useState(true)
    const { setLanguage } = useChangeLanguage()

    useEffect(() => {
        getDefaultTranslations()
        if (translations && Object.keys(translations).length > 0 && !loading) {
            setLoaded(true)
            if (Object.keys(translations).length === 1) {
                setLanguage(Object.keys(translations)[0])
            } else {
                setLanguage(DEFAULT_CULTURE)
            }
        }
    }, [translations, i18n, loading, setLanguage])

    return loaded ? (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            mb={16}
            mx={4}
            style={{ height: '100%' }}
        >
            <WarningIcon width={60} />
            <Box mt={3} color={'text.secondary'}>
                <Typography variant={'h3'} align={'center'}>
                    {t('general.notlogged.part1')}{' '}
                    <Link href={ConfigurationHolder.getConfiguration().grpRedirectUrl}>
                        Group Retail Portal
                    </Link>{' '}
                    {t('general.notlogged.part2')}
                </Typography>
            </Box>
        </Box>
    ) : (
        ''
    )
}

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useDispatchNotification } from '../useDispatchNotification'
import { Users } from '../../api'
import { REMOVE_CONTEXT_URI } from '../../types/reduxActionTypes'
import { userSignedOut } from '../../oidc/OidcSlice'
import { logoutErrorMessage } from '../../types/reduxNotificationTypes'
import { closeLogoutFromGRPPopup } from '../../actions/popupsActions'
import { clearSessionId } from '../../util/util'

export function useAuthActions() {
    const dispatch = useDispatch()
    const history = useHistory()
    const dispatchNotification = useDispatchNotification()

    const logoutFromGRP = useCallback(
        (refreshToken, accessToken) => {
            Users.logout({ refreshToken, accessToken })
                .then(() => {
                    closeLogoutFromGRPPopup()
                    dispatch({
                        type: REMOVE_CONTEXT_URI,
                    })
                    dispatch(
                        userSignedOut({
                            tokenType: 'WRAPPER',
                        })
                    )
                    clearSessionId()
                    history.push({
                        pathname: '/logout',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    closeLogoutFromGRPPopup()
                    dispatchNotification(logoutErrorMessage)
                })
        },
        [dispatch, dispatchNotification, history]
    )

    return { logoutFromGRP }
}

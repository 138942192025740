import { useHistory } from 'react-router-dom'
import React, { useCallback, Fragment } from 'react'
import styled from 'styled-components'
import Arrow from '../assets/images/icon-arrow-right-light.svg'
import { useSortableTableColumns } from '../hooks/form/useSortableTableColumns'
import { usePagination, useSortBy, useTable } from 'react-table'
import { compareIgnoreCase } from '../util/util'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../constants/global'

const ArrowDown = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -3px;
    right: -11px;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #c0c0c0 transparent transparent transparent;
`
const ArrowUp = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    margin-top: -3px;
    right: -11px;
    border-style: solid;
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #c0c0c0 transparent;
`

const Styles = styled.div`
    overflow: auto;
    table {
        font-family: 'SKODA Next CBA', sans-serif;
        font-size: 14px;
        width: 100%;
        border-spacing: 0 8px;

        thead {
            text-transform: uppercase;
            font-size: 10px;
            color: #808285;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
        }

        tbody {
            tr {
                background: white;
                cursor: pointer;

                &:hover {
                    outline: 1px solid lightgrey;
                }
            }
        }

        th {
            padding: 12px 24px;
            span {
                position: relative;
                user-select: none;
            }
        }

        td {
            padding: 24px;
            max-width: 180px;
            text-overflow: ellipsis;
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
        }

        td:last-child {
            padding: 0;
        }

        th,
        td {
            text-align: left;
        }
    }
`

const ArrowGreenRight = styled.div`
    width: 20px;
    height: 20px;
    img {
        width: 100%;
    }
`
const ArrowGreenLeft = styled(ArrowGreenRight)`
    img {
        transform: rotate(180deg);
    }
`
const Pagination = styled.div`
    display: flex;
    flex: 1 0 auto;
    float: right;
    margin-top: 40px;
`
const CircleButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 28px;
    font-size: 12px;
    color: #4b4a4b;
    font-weight: 800;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #c0c0c0;
    margin-right: 4px;
     {
        ${({ active }) =>
            active &&
            `
            color: #fff;
            border: none;
            background: #4b4a4b;
        `}
    }
`

export default function SortableTable({ data }) {
    const [columns] = useSortableTableColumns()
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 20 },
            sortTypes: {
                alphanumeric: (row1, row2, columnName) => {
                    return compareIgnoreCase(row1.values[columnName], row2.values[columnName])
                },
            },
        },
        useSortBy,
        usePagination
    )

    const clickOnRow = useCallback(
        (row) => () => {
            history.push({
                pathname: '/customers/' + row.customerId,
                state: { customer: row },
            })
        },
        [history]
    )

    const handleNextPage = useCallback(() => {
        nextPage()
    }, [nextPage])

    const handlePreviousPage = useCallback(() => {
        previousPage()
    }, [previousPage])

    if (data.length === 0) {
        return null
    }

    return (
        <Fragment>
            <Styles>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        title={t(column.render('title'), {
                                            replace: ignoreKeyInHTML,
                                        })}
                                    >
                                        <span>
                                            {t(column.render('Header'))}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <ArrowDown />
                                                ) : (
                                                    <ArrowUp />
                                                )
                                            ) : null}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} onClick={clickOnRow(row.original)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                style={{
                                                    color:
                                                        (cell.column.id === 'customerStatus' &&
                                                            row.original.customerStatusFailed) ||
                                                        (cell.column.id === 'vehicleStatus' &&
                                                            row.original.vehicleStatusFailed)
                                                            ? '#ec1e1e'
                                                            : null,
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                    <td>
                                        <ArrowGreenRight>
                                            <img src={Arrow} alt={'Customer Detail'} />
                                        </ArrowGreenRight>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Pagination>
                    {canPreviousPage && (
                        <CircleButton onClick={handlePreviousPage}>
                            <ArrowGreenLeft>
                                <img src={Arrow} alt={'Previous'} />
                            </ArrowGreenLeft>
                        </CircleButton>
                    )}
                    {Array.from(Array(pageCount)).map((x, i) => (
                        <CircleButton key={i} onClick={() => gotoPage(i)} active={i === pageIndex}>
                            {i + 1}
                        </CircleButton>
                    ))}
                    {canNextPage && (
                        <CircleButton onClick={handleNextPage}>
                            <ArrowGreenRight>
                                <img src={Arrow} alt={'Customer Detail'} />
                            </ArrowGreenRight>
                        </CircleButton>
                    )}
                </Pagination>
            </Styles>
        </Fragment>
    )
}

import React from 'react'
import info from '../../assets/images/google-play.svg'
import styled from 'styled-components'

const Icon = styled.img`
    width: 175px;

    @media (max-width: 768px) {
        width: 150px;
    }

    @media (max-width: 400px) {
        width: 130px;
    }
`

export function GooglePlayIcon() {
    return (
        <div>
            <a
                href="https://play.google.com/store/apps/details?id=cz.skodaauto.myskoda"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon src={info} alt="google-play" />
            </a>
        </div>
    )
}

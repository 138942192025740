import React, { useState, useEffect } from 'react'
import Page from '../../components/Wrappers/Page'
import TabNavigation from '../../components/TabNavigation'
import Journey from '../../components/Journey'
import VehiclesList from './VehiclesList'
// MSP-51523 - Hide ident
// import UpdateSkodaIdent from './UpdateSkodaIdent'
import UpdateCustomerInformation from './UpdateCustomerInformation'
import Loading from '../../components/Loading'
// MSP-51523 - Hide ident
// import { consentsEnum } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { useSaveInputChange } from '../../hooks/form/useSaveInputChange'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import { useParams } from 'react-router'
import { Customers } from '../../api'

function CustomerDetail() {
    const [customer, setCustomer] = useState(null)
    const [vehiclesData, setVehiclesData] = useState(null)
    // MSP-51523 - Hide ident
    const [hasCustomerIdentConsent /*, setHasCustomerIdentConsent*/] = useState(false)
    const [journeyStatuses, setJourneyStatuses] = useState(null)
    const { setSearchParam } = useSaveInputChange()
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const { customerId } = useParams()

    const getStatusesData = async (customerId) => {
        try {
            const response = await Customers.getCustomerStatuses(customerId)
            setJourneyStatuses(response?.data)
        } catch (e) {
            console.error(e)
        }
    }

    const getVehiclesData = async (customerId) => {
        try {
            const response = await Customers.getCustomerVehiclesDetails(customerId)
            setVehiclesData(response.data)
            // MSP-51523 - Hide ident
            // setHasCustomerIdentConsent(
            //     response.data.find((item) => item.consents?.[consentsEnum.IDENT])
            // )
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        setSearchParam(null)
        const loadData = async () => {
            setLoading(true)
            try {
                await getStatusesData(customerId)
                await getVehiclesData(customerId)
                const response = await Customers.getCustomer(customerId)
                setCustomer(response.data)
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
            if (window.location.hash === '#vehicleDetails') {
                setActiveTab(1)
            }
        }
        customerId && loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId])

    return (
        <ScrollTop>
            {loading && <Loading />}
            {!loading && (
                <Page
                    withBackLink={true}
                    backLinkUrl={'/customers'}
                    title={`${customer.customerInformation.lastName} ${customer.customerInformation.firstName}`}
                    subtitle={`(${customer.customerInformation.email})`}
                >
                    <TabNavigation
                        tabs={
                            hasCustomerIdentConsent
                                ? [
                                      { label: 'general.journey.title' },
                                      { label: 'general.vehicledetails.title' },
                                      // MSP-51523 - Hide ident
                                      // { label: 'general.ident.title' },
                                      { label: 'general.customer.title' },
                                  ]
                                : [
                                      { label: 'general.journey.title' },
                                      { label: 'general.vehicledetails.title' },
                                      { label: 'general.customer.title' },
                                  ]
                        }
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />

                    {customer && journeyStatuses && activeTab === 0 && (
                        <Journey
                            customer={customer}
                            statuses={journeyStatuses}
                            getStatusesData={getStatusesData}
                        />
                    )}
                    {customer && activeTab === 1 && (
                        <VehiclesList
                            customer={customer}
                            statuses={journeyStatuses}
                            vehiclesData={vehiclesData}
                            getVehiclesData={getVehiclesData}
                            getStatusesData={getStatusesData}
                        />
                    )}
                    {/* MSP-51523 - Hide ident */}
                    {/*{customer && hasCustomerIdentConsent && activeTab === 2 && (*/}
                    {/*    <UpdateSkodaIdent customer={customer} statuses={journeyStatuses} />*/}
                    {/*)}*/}
                    {customer &&
                        ((hasCustomerIdentConsent && activeTab === 3) ||
                            (!hasCustomerIdentConsent && activeTab === 2)) && (
                            <UpdateCustomerInformation customer={customer} />
                        )}
                    {!customer && <Loading />}
                </Page>
            )}
        </ScrollTop>
    )
}

export default WithHeader(CustomerDetail)

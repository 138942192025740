import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    TRANSLATIONS_NAMESPACE,
    rqStatusEnum,
    SUPPORTED_LANGUAGES_CULTURES,
    DEFAULT_CULTURE,
} from '../../constants/global'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import { downloadAndDispatchTranslation } from '../../actions/i18nActions'
import { Accounts } from '../../api'
import { CircularProgress, Container } from '@material-ui/core'
import ConsentsHeader from '../../assets/images/consents-hero-banner.png'
import styled from 'styled-components'

const ContentContainer = styled('div')`
    padding-left: 40px;
    padding-right: 40px;
    background-color: #fff;
`

const Header = styled('div')`
    padding-top: 24px;
    padding-bottom: 24px;
    text-align: left;
    flex: 0 0 auto;
`

const HeroWrapper = styled('div')`
    position: relative;
    overflow: hidden;
    flex: 0 0 40%;
    background-image: url(${ConsentsHeader});
    background-size: cover;
    background-position: center;

    &::before {
        display: block;
        position: absolute;
        top: -5%;
        left: -40%;
        width: 70%;
        height: 29%;
        background-color: #0e3a2f;
        transform: rotate(-28deg);
        content: '';
    }

    &::after {
        display: block;
        position: absolute;
        top: -5%;
        left: 82%;
        width: 57%;
        height: 187%;
        background-color: #0e3a2f;
        transform: rotate(18deg);
        content: '';
    }
`

const TextWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 auto;
    text-align: center;
`

const InfoText = styled('p')`
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.54px;
    text-align: center;
    padding-bottom: 5rem;
`

const MainWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fff;
`

const StatusIconWrapper = styled('div')`
    padding: 20px;
`

const Footer = styled('div')`
    background-color: #0e3a2f;
    padding: 24px;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.24px;
`

function ConsentsConfirmation() {
    const [rqStatus, setRqStatus] = useState(rqStatusEnum.IN_PROGRESS)
    const [consentStatus, setConsentStatus] = useState(null)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { setLanguage } = useChangeLanguage()

    useEffect(() => {
        const init = async () => {
            const url = new URL(window.location)
            const hash = url.searchParams.get('hash')

            if (hash) {
                setRqStatus(rqStatusEnum.IN_PROGRESS)
                try {
                    const response = await Accounts.setConsents({
                        hash,
                    })
                    const culture = response.data.locale
                        ? response.data.locale
                        : SUPPORTED_LANGUAGES_CULTURES[navigator.language.substring(0, 2)] ||
                          DEFAULT_CULTURE
                    setLanguage(culture)
                    await downloadAndDispatchTranslation(culture)
                    setConsentStatus(response.data.consentStatus)
                    setRqStatus(rqStatusEnum.SUCCESS)
                } catch (e) {
                    console.error(e)
                    const culture =
                        SUPPORTED_LANGUAGES_CULTURES[navigator.language.substring(0, 2)] ||
                        DEFAULT_CULTURE
                    await downloadAndDispatchTranslation(culture)
                    setLanguage(culture)
                    setRqStatus(rqStatusEnum.ERROR)
                }
            } else {
                const culture =
                    SUPPORTED_LANGUAGES_CULTURES[navigator.language.substring(0, 2)] ||
                    DEFAULT_CULTURE
                await downloadAndDispatchTranslation(culture)
                setLanguage(culture)
                setRqStatus(rqStatusEnum.ERROR)
            }
        }
        init()
    }, [setLanguage])

    return (
        <MainWrapper maxWidth={'md'} disableGutters>
            <Header>
                <ContentContainer>
                    <svg
                        width="120"
                        height="16"
                        viewBox="0 0 120 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            maxWidth: '50%',
                        }}
                    >
                        <path
                            d="M0 16L2.22307 12.6125H15.1169C15.8473 12.6125 16.3237 12.1587 16.3237 11.4631V9.49713H5.24009C1.93724 9.49713 0.4764 8.10585 0.4764 5.02078V3.68997C0.4764 1.3913 1.90549 0 4.16032 0H4.41438V4.96029C4.41438 5.65595 4.89078 6.10964 5.6212 6.10964H16.3554C18.8008 6.10964 20.2617 7.50094 20.2617 9.76936V11.5236C20.2617 14.5179 18.7055 16 15.498 16L0 16ZM77.2654 12.552H87.2374C88.8889 12.552 89.9369 11.5841 89.9369 10.0718V5.92816C89.9369 4.41587 88.8889 3.44801 87.2374 3.44801H77.2654V12.552ZM73.2639 16V0H87.3328C91.5248 0 93.9385 2.29867 93.9385 6.10964V9.89034C93.9385 13.7013 91.5248 16 87.3328 16H73.2639ZM53.872 12.552H62.288C63.8123 12.552 64.7968 11.6446 64.7968 10.2231V5.77693C64.7968 4.35538 63.8123 3.44801 62.288 3.44801H53.872C52.3477 3.44801 51.3632 4.35538 51.3632 5.77693V10.223C51.3632 11.6446 52.3477 12.552 53.872 12.552ZM62.3832 16H53.7768C49.7118 16 47.3616 13.7618 47.3616 10.0416V5.9584C47.3616 2.23818 49.7117 0 53.7768 0H62.3832C66.4482 0 68.7984 2.23818 68.7984 5.9584V10.0416C68.7984 13.7618 66.4483 16 62.3832 16ZM95.578 16L105.01 0H110.568L120 16H115.427L113.807 13.2476H105.772L103.613 9.98108L100.056 16H95.578ZM103.708 9.79961H111.775L107.741 2.99428L103.708 9.79961ZM8.3524 3.32702L6.06581 0H19.8806L17.6575 3.32702H8.3524ZM24.6649 16V0H28.6665V6.23062H33.9701L40.8616 0H46.1652L37.4 7.95462L46.324 16H40.7028L33.8113 9.67863H28.6665V16H24.6649Z"
                            fill="#0E3A2F"
                        />
                    </svg>
                </ContentContainer>
            </Header>
            <HeroWrapper />

            <TextWrapper>
                <ContentContainer>
                    {rqStatus === rqStatusEnum.IN_PROGRESS && <CircularProgress />}
                    {rqStatus === rqStatusEnum.SUCCESS && consentStatus === 'SUCCESS' && (
                        <>
                            <StatusIconWrapper>
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15 29.958C23.194 29.958 29.994 23.192 29.994 15.032C29.994 6.872 23.194 0.106 15 0.106C6.806 0.106 0.00600014 6.872 0.00600014 15.032C0.00600014 23.192 6.806 29.958 15 29.958ZM7.894 15.202L9.866 13.264L13.368 16.766L20.474 9.66L22.446 11.632L13.368 20.71L7.894 15.202Z"
                                        fill="#37D002"
                                    />
                                </svg>
                            </StatusIconWrapper>
                            <InfoText>{t('general.consents.setSuccess')}</InfoText>
                        </>
                    )}
                    {rqStatus === rqStatusEnum.SUCCESS && consentStatus === 'ALREADY_CONFIRMED' && (
                        <>
                            <StatusIconWrapper>
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15 29.958C23.194 29.958 29.994 23.192 29.994 15.032C29.994 6.872 23.194 0.106 15 0.106C6.806 0.106 0.00600014 6.872 0.00600014 15.032C0.00600014 23.192 6.806 29.958 15 29.958ZM7.894 15.202L9.866 13.264L13.368 16.766L20.474 9.66L22.446 11.632L13.368 20.71L7.894 15.202Z"
                                        fill="#37D002"
                                    />
                                </svg>
                            </StatusIconWrapper>
                            <InfoText>{t('general.consents.alreadySet')}</InfoText>
                        </>
                    )}
                    {((rqStatus === rqStatusEnum.SUCCESS && consentStatus === 'ERROR') ||
                        rqStatus === rqStatusEnum.ERROR) && (
                        <>
                            <StatusIconWrapper>
                                <svg
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={30}
                                    height={30}
                                    viewBox="0 0 30 30"
                                >
                                    <g transform="translate(-5.006 -5.106)">
                                        <path
                                            d="M20 34.958c8.194 0 14.994-6.766 14.994-14.926S28.194 5.106 20 5.106c-8.194 0-14.994 6.766-14.994 14.926S11.806 34.958 20 34.958zM18.504 21.97v-9.452h2.992v9.452ZM20 27.546c-1.02 0-1.904-.884-1.904-1.904s.884-1.904 1.904-1.904 1.904.884 1.904 1.904-.884 1.904-1.904 1.904z"
                                            fill="#ec6513"
                                        />
                                    </g>
                                </svg>
                            </StatusIconWrapper>
                            <InfoText>{t('general.consents.hashError')}</InfoText>
                        </>
                    )}
                </ContentContainer>
            </TextWrapper>
            <Footer>&copy; ŠKODA AUTO a.s.</Footer>
        </MainWrapper>
    )
}

export default ConsentsConfirmation

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { SET_PSP } from '../../types/reduxActionTypes'

export function useServicePartnersActions() {
    const dispatch = useDispatch()

    const setPsp = useCallback(
        (id, name) => {
            dispatch({
                type: SET_PSP,
                payload: {
                    value: {
                        id,
                        name,
                    },
                },
            })
        },
        [dispatch]
    )

    return { setPsp }
}

import React from 'react'
import styled from 'styled-components'
import { SKODA_LOGO } from '../../constants/global'

const Logo = styled.div`
    width: 150px;
    cursor: pointer;
`

export function SkodaLogo({ click }) {
    return (
        <Logo onClick={click}>
            <img src={SKODA_LOGO} alt="Skoda Logo" height="41" />
        </Logo>
    )
}

import React, { useEffect } from 'react'
import './index.scss'
import styled, { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import muiTheme from './muiTheme'
import { StylesProvider, MuiThemeProvider } from '@material-ui/core'
import CreateCustomerInformation from './pages/Registration/CreateCustomerInformation'
import CreateSkodaIdent from './pages/Registration/CreateSkodaIdent'
import CreateOrderDetails from './pages/Registration/CreateVehicleDetails'
import ConsentDocumentPreview from './pages/Documents/ConsentDocumentPreview'
import CommissionsList from './pages/Commissions/CommissionsList'
import CustomerDetail from './pages/Commissions/CustomerDetail'
import Footer from './components/Footer'
import LoginCallback from './pages/Auth/LoginCallback'
import ThankYouPage from './pages/Static/ThankYouPage'
import TermsOfUsePage from './pages/Static/TermsOfUsePage'
import LegalPage from './pages/Static/LegalPage'
import Notification from './components/Notification'
import ErrorBoundary from './components/ErrorBoundary'
import ErrorPage from './pages/Static/ErrorPage'
import LoginHandler from './pages/Auth/LoginHandler'
import { useSelector } from 'react-redux'
import NotLoggedInPage from './pages/Static/NotLoggedInPage'
import LogoutHandler from './pages/Auth/LogoutHandler'
import { useChangeLanguage } from './hooks/useChangeLanguage'
import { getTranslations } from './actions/i18nActions'
import useOcwCreateUser from './hooks/useOcwCreateUser'
import ConsentsConfirmation from './pages/Registration/ConsentsConfirmation'
import { ExpiredLicenses } from './components/ExpiredLicensnes'

const ContentContainer = styled.div`
    flex: 1 0 auto;
`

function App() {
    const userInfo = useSelector((state) => state.oidc.userInfo)
    const isAuthenticated = userInfo !== null

    const { setLanguage } = useChangeLanguage()
    const { selectedLanguage, translations } = useSelector((state) => state.options)

    useEffect(() => {
        setLanguage(selectedLanguage)
    }, [selectedLanguage, setLanguage, userInfo])

    useEffect(() => {
        getTranslations()
    }, [translations])

    useOcwCreateUser()

    return (
        <Router>
            {process.env.REACT_APP_ENV === 'dev_local' && (
                <div
                    style={{
                        position: 'fixed',
                        top: '-10px',
                        left: '-40px',
                        zIndex: 999999,
                        background: 'red',
                        transform: 'rotate(-45deg)',
                        color: 'white',
                        fontSize: '12px',
                        padding: '20px 40px 5px 40px',
                    }}
                >
                    local
                </div>
            )}
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={muiTheme}>
                    <StyledComponentsThemeProvider theme={muiTheme}>
                        <ErrorBoundary>
                            <Switch>
                                <ProtectedRoute
                                    path="/consent-documents/:lang"
                                    isAuth={isAuthenticated}
                                >
                                    <ConsentDocumentPreview />
                                </ProtectedRoute>
                                <Route path="/thank-you">
                                    <ThankYouPage />
                                </Route>
                                <Route path="/consents-confirmation">
                                    <ConsentsConfirmation />
                                </Route>
                                <Route path="/not-logged-in">
                                    <NotLoggedInPage />
                                </Route>
                                <Route path="/login-callback">
                                    <LoginCallback />
                                </Route>
                                <Route exact path="/">
                                    <LoginHandler />
                                </Route>
                                <Route exact path="/logout">
                                    <LogoutHandler />
                                </Route>
                                <Route path="/error">
                                    <ErrorPage />
                                </Route>
                                <ProtectedRoute
                                    path="/customer-information"
                                    isAuth={isAuthenticated}
                                >
                                    <CreateCustomerInformation />
                                </ProtectedRoute>
                                <ProtectedRoute path="/skoda-ident" isAuth={isAuthenticated}>
                                    <CreateSkodaIdent />
                                </ProtectedRoute>
                                <ProtectedRoute path="/vehicle-details" isAuth={isAuthenticated}>
                                    <CreateOrderDetails />
                                </ProtectedRoute>
                                <ProtectedRoute path="/customers" exact isAuth={isAuthenticated}>
                                    <CommissionsList />
                                </ProtectedRoute>
                                <ProtectedRoute
                                    path="/customers/:customerId"
                                    exact
                                    isAuth={isAuthenticated}
                                >
                                    <CustomerDetail />
                                </ProtectedRoute>
                                <ProtectedRoute path="/terms-of-use" isAuth={isAuthenticated}>
                                    <TermsOfUsePage />
                                </ProtectedRoute>
                                <ProtectedRoute path="/legal" isAuth={isAuthenticated}>
                                    <LegalPage />
                                </ProtectedRoute>
                                <Route path="*">
                                    <ErrorPage code={'404'} />
                                </Route>
                            </Switch>
                            <ExpiredLicenses />
                        </ErrorBoundary>
                    </StyledComponentsThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </Router>
    )
}

function ProtectedRoute({ isAuth, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth ? (
                    <>
                        <ContentContainer>{children}</ContentContainer>
                        <Notification position={'TopRight'} />
                        <Footer />
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/not-logged-in',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

export default App

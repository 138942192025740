import React, { useEffect, useState } from 'react'
import HorizontalStepper from '../../components/HorizontalStepper'
import { useDispatch, useSelector } from 'react-redux'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import Panel from '../../components/Wrappers/Panel'
import { useHistory } from 'react-router-dom'
import { getErrorText, OrderDetailsFormValidator } from '../../util/validators'
import { useTranslation } from 'react-i18next'
import { consentsEnum, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { FormTitle } from '../../components/FormElements'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import RequiredBox from '../../components/RequiredBox'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
} from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import { Commissions, Customers } from '../../api'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import {
    customerCreatedErrorMessage,
    customerCreatedSuccessMessage,
} from '../../types/reduxNotificationTypes'
import { SET_EXPIRED_LICENSES } from '../../types/reduxActionTypes'
import { CONSENT_TYPES_ENUM, VEHICLE_INFO_TYPES_ENUM } from '../../util/util'
import VehicleInfoDialog from '../../components/VehicleInfoDialog'
import { VehicleConsentsDialog } from '../../components/VehicleConsentsDialog'
import ServicePartnerSearchInput from '../../components/ServicePartnerSearchInput/ServicePartnerSearchInput'
import { useServicePartnersActions } from '../../hooks/actions/useServicePartnerActions'
import ArrowBack from '@material-ui/icons/ArrowBack'

const isFormValid = (data) => {
    const validStateOptions = ['vin', 'commissionNumber', 'orderNumber']
    return validStateOptions.every((item) => data[item] === null)
}

function CreateVehicleDetails() {
    const dispatchNotification = useDispatchNotification()
    const dispatch = useDispatch()
    const customer = useSelector((state) => state.customer)
    const {
        customerInformation,
        orderDetails,
        consentId,
        consentType,
        skodaIdent,
        consentVerification,
    } = customer
    const { userInfo } = useSelector((state) => state.oidc)
    const { accountCheck } = useSelector((state) => state.account)
    const { accessToCreateProcess } = useSelector((state) => state.popups)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { saveOrderDetailsField, clearCustomer } = useUpdateCustomerActions()
    const { setPsp } = useServicePartnersActions()
    const [isConsentGranted, setIsConsentGranted] = useState(
        consentType === CONSENT_TYPES_ENUM.digital
    )
    const [isConsentsDialogUpdateOpen, setIsConsentsDialogUpdateOpen] = useState(-1)
    const [isVehicleConsentPopupOpenWithConsentId, setIsVehicleConsentPopupOpenWithConsentId] =
        useState(-1)
    const [isRqInProgress, setIsRqInProgress] = useState(false)

    useEffect(() => {
        if (!accessToCreateProcess) {
            history.push({
                pathname: '/customers',
            })
        }
    }, [accessToCreateProcess, history])

    const onSubmitHandler = async () => {
        const validationErrors = OrderDetailsFormValidator(orderDetails)
        setErrors(validationErrors)
        if (isFormValid(validationErrors) && !isRqInProgress) {
            if (accountCheck.cbaCustomerId) {
                const vehicleData = {
                    skodaIdent: consentVerification?.[consentsEnum.IDENT]
                        ? {
                              licenseNumber: skodaIdent.licenseNumber,
                              countryOfIssueCode: skodaIdent.countryOfIssueCode,
                          }
                        : null,
                    orderDetails: {
                        vin: orderDetails.vin || null,
                        commissionNumber: orderDetails.commissionNumber || null,
                        commissionNumberBid: userInfo.bid || null,
                        commissionNumberYear: orderDetails.commissionYear || null,
                        orderNumber: orderDetails.orderNumber || null,
                        preferredServicePartnerId: orderDetails.preferredServicePartnerId,
                        preferredServicePartnerName: orderDetails.preferredServicePartnerName,
                        dealer: {
                            id: userInfo.dealershipId,
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName,
                        },
                        createdByOcwData: !!orderDetails.createdByOcwData,
                    },
                    consentId,
                }
                setIsRqInProgress(true)
                try {
                    await Customers.addVehicleToCustomer(accountCheck.cbaCustomerId, vehicleData)
                    dispatchNotification(customerCreatedSuccessMessage)
                    history.push('/customers')
                } catch (e) {
                    console.error(e)
                    dispatchNotification(customerCreatedErrorMessage)
                    setIsRqInProgress(false)
                }
            } else {
                const commissionData = {
                    customerInformation: {
                        email: customerInformation.email,
                        firstName: customerInformation.firstName,
                        middleName: customerInformation.middleName,
                        lastName: customerInformation.lastName,
                        nickname: customerInformation.nickname,
                        address: {
                            // hardcoded value for addressType - param not used anymore
                            // kept just for BE purposes where it is required
                            addressTypeId: 1,
                            countryId: customerInformation.address.countryId,
                        },
                        preferredLanguage: customerInformation.preferredLanguage,
                        phoneNumber: customerInformation.phoneNumber
                            ? customerInformation.phoneNumber.replace(/\s/g, '')
                            : null,
                        dialCode:
                            customerInformation.dialCode && customerInformation.phoneNumber
                                ? customerInformation.dialCode
                                : null,
                    },
                    skodaIdent: consentVerification?.[consentsEnum.IDENT]
                        ? {
                              licenseNumber: skodaIdent.licenseNumber,
                              countryOfIssueCode: skodaIdent.countryOfIssueCode,
                          }
                        : null,
                    orderDetails: {
                        vin: orderDetails.vin || null,
                        commissionNumber: orderDetails.commissionNumber || null,
                        commissionNumberBid: userInfo.bid || null,
                        commissionNumberYear: orderDetails.commissionYear || null,
                        orderNumber: orderDetails.orderNumber || null,
                        preferredServicePartnerId: orderDetails.preferredServicePartnerId,
                        preferredServicePartnerName: orderDetails.preferredServicePartnerName,
                        dealer: {
                            id: userInfo.dealershipId,
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName,
                        },
                        createdByOcwData: !!orderDetails.createdByOcwData,
                    },
                    skodaConnectExistsFlag: accountCheck.connectAccount,
                    skodaIdentExistsFlag: accountCheck.skodaIdentAccount,
                    skodaGroupIdExistsFlag: accountCheck.groupIdAccount,
                    consentId,
                }
                setIsRqInProgress(true)
                try {
                    await Commissions.createCommission(commissionData)
                    dispatchNotification(customerCreatedSuccessMessage)
                    clearCustomer()
                    history.push('/customers')
                } catch (e) {
                    console.error(e)
                    dispatchNotification(customerCreatedErrorMessage)
                    setIsRqInProgress(false)
                }
            }
            dispatch({
                type: SET_EXPIRED_LICENSES,
                payload: {},
            })
        }
    }

    const openUpdateDialog = () => {
        setIsConsentsDialogUpdateOpen(1)
    }

    return (
        <ScrollTop>
            <FormWrapper>
                <HorizontalStepper activeStep={consentVerification?.[consentsEnum.IDENT] ? 2 : 1} />
                <Panel>
                    <FormTitle>{t('general.order.title')}</FormTitle>
                    <form noValidate autoComplete="off">
                        <TextField
                            name={'email'}
                            value={customerInformation.email}
                            disabled
                            label={t('general.customer.create.popup.email')}
                        />
                        <TextField
                            name={'vin'}
                            value={orderDetails.vin}
                            label={t('general.order.vin')}
                            fullWidth
                            error={!!errors.vin}
                            helperText={
                                errors.vin && (
                                    <>
                                        {t('general.order.vin')} {t(getErrorText(errors.vin))}
                                    </>
                                )
                            }
                            onClick={openUpdateDialog}
                        />
                        <TextField
                            name={'commissionNumber'}
                            value={`${[
                                orderDetails.commissionNumber || '______',
                                userInfo.bid ?? '___',
                                orderDetails.commissionYear || '',
                            ].join('-')}`}
                            label={t('general.order.commission')}
                            fullWidth
                            error={!!errors.commissionNumber}
                            helperText={
                                errors.commissionNumber && (
                                    <>
                                        {t('general.order.commission')}{' '}
                                        {t(getErrorText(errors.commissionNumber))}
                                    </>
                                )
                            }
                            onClick={openUpdateDialog}
                        />
                        <TextField
                            name={'orderNumber'}
                            value={orderDetails.orderNumber}
                            label={t('general.order.orderNo')}
                            fullWidth
                            error={!!errors.orderNumber}
                            helperText={
                                errors.orderNumber && (
                                    <>
                                        {t('general.order.orderNo')}{' '}
                                        {t(getErrorText(errors.orderNumber))}
                                    </>
                                )
                            }
                            onChange={(e) => saveOrderDetailsField('orderNumber', e.target.value)}
                        />
                        {consentVerification?.[consentsEnum.PSP] && (
                            <ServicePartnerSearchInput
                                orderDetails={orderDetails}
                                setPsp={setPsp}
                                errors={errors}
                                editMode={true}
                                disabled={false}
                            />
                        )}
                        <Box mt={4}>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isConsentGranted}
                                                onChange={(_e, v) => setIsConsentGranted(v)}
                                                name={'consentGranted'}
                                                disabled={
                                                    consentType === CONSENT_TYPES_ENUM.digital
                                                }
                                            />
                                        }
                                        label={t(
                                            consentType === CONSENT_TYPES_ENUM.digital
                                                ? 'general.consents.checkboxConsent.awaitingDigital'
                                                : 'general.order.consentGranted'
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() =>
                                            setIsVehicleConsentPopupOpenWithConsentId(consentId)
                                        }
                                    >
                                        <DescriptionIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                    <RequiredBox withTop />
                    <Box mt={4}>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item>
                                <Button
                                    style={{ minWidth: 'auto' }}
                                    variant={'outlined'}
                                    onClick={() =>
                                        consentVerification?.[consentsEnum.IDENT]
                                            ? history.push('/skoda-ident')
                                            : history.push('/customer-information')
                                    }
                                    startIcon={<ArrowBack />}
                                    disabled={isRqInProgress}
                                >
                                    {t('general.button.back')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={onSubmitHandler}
                                    disabled={!isConsentGranted || isRqInProgress}
                                >
                                    {t(
                                        accountCheck.cbaCustomerId
                                            ? 'general.button.createNewVehicle'
                                            : 'general.button.consents.create.customer'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Panel>
            </FormWrapper>
            {isConsentsDialogUpdateOpen !== -1 && (
                <VehicleInfoDialog
                    actionType={VEHICLE_INFO_TYPES_ENUM.updateOnCreation}
                    customer={customer}
                    vehicleData={{
                        consents: customer.consentVerification,
                        vin: orderDetails.vin,
                        commissionNumber: `${orderDetails.commissionNumber}-${userInfo.bid ?? ''}-${
                            orderDetails.commissionYear
                        }`,
                        orderNumber: orderDetails.orderNumber,
                        consentId: customer.consentId,
                        preferredServicePartnerId: orderDetails.preferredServicePartnerId,
                        preferredServicePartnerName: orderDetails.preferredServicePartnerName,
                    }}
                    setOpen={setIsConsentsDialogUpdateOpen}
                />
            )}
            <VehicleConsentsDialog
                isVehicleConsentPopupOpenWithConsentId={isVehicleConsentPopupOpenWithConsentId}
                setIsVehicleConsentPopupOpenWithConsentId={
                    setIsVehicleConsentPopupOpenWithConsentId
                }
            />
        </ScrollTop>
    )
}

export default WithHeader(CreateVehicleDetails)

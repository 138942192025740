import React, { useEffect, useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@material-ui/core'
import { CloseCross } from './global'
import Close from '@material-ui/icons/Close'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../constants/global'
import { Accounts } from '../api'
import { somethingWentWrongErrorMessage } from '../types/reduxNotificationTypes'
import { useDispatchNotification } from '../hooks/useDispatchNotification'

export const VehicleConsentsDialog = ({
    isVehicleConsentPopupOpenWithConsentId,
    setIsVehicleConsentPopupOpenWithConsentId,
}) => {
    const [isVehicleConsentLoading, setIsVehicleConsentLoading] = useState(true)
    const [vehicleConsentData, setVehicleConsentData] = useState('')
    const dispatchNotification = useDispatchNotification()

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const onCloseVehicleConsentHandler = () => {
        setIsVehicleConsentPopupOpenWithConsentId(false)
        setIsVehicleConsentLoading(true)
        setVehicleConsentData('')
    }

    useEffect(() => {
        const showConsents = async () => {
            setIsVehicleConsentLoading(true)

            try {
                const response = await Accounts.getConsentTextByConsentId(
                    isVehicleConsentPopupOpenWithConsentId.toString()
                )
                if (response.response.statusCode === 204) {
                    setVehicleConsentData(
                        t('general.consents.documentNotAvailable', { replace: ignoreKeyInHTML })
                    )
                } else {
                    setVehicleConsentData(response.data.consentsText)
                }
                setIsVehicleConsentLoading(false)
            } catch (e) {
                console.error(e)
                dispatchNotification(somethingWentWrongErrorMessage)
            }
        }
        isVehicleConsentPopupOpenWithConsentId > 0 && showConsents()
    }, [
        dispatchNotification,
        isVehicleConsentPopupOpenWithConsentId,
        setIsVehicleConsentPopupOpenWithConsentId,
        t,
    ])

    return (
        <Dialog
            open={isVehicleConsentPopupOpenWithConsentId > 0}
            maxWidth={'sm'}
            fullWidth
            onClose={onCloseVehicleConsentHandler}
        >
            <DialogTitle disableTypography>
                <CloseCross onClick={onCloseVehicleConsentHandler}>
                    <Close />
                </CloseCross>
                {t('general.vehicle.consents')}
            </DialogTitle>
            <DialogContent>
                {isVehicleConsentLoading ? (
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    parse(vehicleConsentData ?? '')
                )}
            </DialogContent>
            <DialogActions>
                <>
                    <Button
                        style={{ minWidth: '140px' }}
                        variant={'outlined'}
                        onClick={onCloseVehicleConsentHandler}
                    >
                        {t('general.button.ok')}
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    )
}

import React from 'react'
import { useSelector } from 'react-redux'
import CustomerHasSkodaIdentPopUp from './variants/CustomerHasSkodaIdentPopUp'
import LogoutFromGRPPopUp from './variants/LogoutFromGRPPopUp'

export default function FullPagePopUp() {
    const { hasSkodaIdentActive, logoutFromGRPPopupActive } = useSelector((state) => state.popups)

    if (hasSkodaIdentActive) {
        return <CustomerHasSkodaIdentPopUp />
    } else if (logoutFromGRPPopupActive) {
        return <LogoutFromGRPPopUp />
    } else {
        return null
    }
}

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { showLogoutFromGRPPopup } from '../actions/popupsActions'
import React, { useState } from 'react'
import { REPORT_ADMINISTRATOR_ROLE, TRANSLATIONS_NAMESPACE } from '../constants/global'
import { DownloadReport } from '../api'
import { saveToCsvFile } from '../util/savers'
import { formatDateIntoDDMMYYYY } from '../util/formatters'
import { downloadReportErrorMessage } from '../types/reduxNotificationTypes'
import { useDispatchNotification } from '../hooks/useDispatchNotification'
import { Box, Button, CircularProgress, Popover, Typography } from '@material-ui/core'
import Download from '@material-ui/icons/GetApp'

export default function DealerProfilePopover({ onClose, anchorEl }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const dispatchNotification = useDispatchNotification()
    const [isDownloadReportRqInProgress, setIsDownloadReportRqInProgress] = useState(false)

    const userInfo = useSelector((state) => state.oidc.userInfo)

    const downloadReport = async () => {
        try {
            const response = await DownloadReport.downloadReport()
            saveToCsvFile(response.response.body, formatDateIntoDDMMYYYY(new Date()))
        } catch (error) {
            console.error(error)
            dispatchNotification(downloadReportErrorMessage)
        }
    }

    const download = async () => {
        setIsDownloadReportRqInProgress(true)
        await downloadReport()
        setIsDownloadReportRqInProgress(false)
        onClose()
    }

    const onLogout = () => {
        onClose()
        showLogoutFromGRPPopup()
    }

    return (
        <Popover
            onClose={onClose}
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box color={'text.secondary'} m={4}>
                <Box mb={2}>
                    <Typography variant={'h4'}>{t('general.dealer.profile.title')}</Typography>
                </Box>
                <Typography variant={'body1'}>
                    {userInfo.firstName} {userInfo.lastName}
                </Typography>
                <Box mt={2}>
                    {userInfo && userInfo.role === REPORT_ADMINISTRATOR_ROLE && (
                        <Box mb={2}>
                            <Button
                                onClick={download}
                                endIcon={!isDownloadReportRqInProgress ? <Download /> : undefined}
                                disabled={isDownloadReportRqInProgress}
                            >
                                {isDownloadReportRqInProgress ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    t('general.dealer.profile.download')
                                )}
                            </Button>
                        </Box>
                    )}
                    <Button variant={'contained'} color={'primary'} onClick={onLogout}>
                        {t('general.header.profile.logout')}
                    </Button>
                </Box>
            </Box>
        </Popover>
    )
}

import { store } from '../index'
import {
    RESET_CUSTOMER,
    SET_ACCESS_TO_CREATE_PROCESS,
    SET_CONSENTS_CREATE_DIALOG_STATUS,
    SET_HAS_SKODA_IDENT_ACTIVE,
    SET_LOGOUT_FROM_GRP_ACTIVE,
    SET_EXPIRED_LICENSES,
} from '../types/reduxActionTypes'
import { CONSENTS_CREATE_DIALOG_STATUS_MAP } from '../util/util'

export const setConsentsCreateDialogStatus = (status) => {
    store.dispatch({
        type: SET_CONSENTS_CREATE_DIALOG_STATUS,
        payload: {
            value: status,
        },
    })
}

export const closeConsentsCreateDialog = () => {
    setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive)
}

export const closeCancelNewCustomer = (consentsDialogStatus) => {
    if (
        window.location.pathname !== '/customer-information' &&
        window.location.pathname !== '/skoda-ident' &&
        window.location.pathname !== '/vehicle-details'
    ) {
        setConsentsCreateDialogStatus(
            consentsDialogStatus === CONSENTS_CREATE_DIALOG_STATUS_MAP.cancelAddVehicle
                ? CONSENTS_CREATE_DIALOG_STATUS_MAP.addVehicle
                : CONSENTS_CREATE_DIALOG_STATUS_MAP.active
        )
    } else {
        setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive)
    }
}

export const onCancelNewCustomerConfirm = () => {
    setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.inactive)
    store.dispatch({
        type: RESET_CUSTOMER,
    })
    store.dispatch({
        type: SET_EXPIRED_LICENSES,
        payload: {},
    })
}

export const showHasSkodaIdentPopup = () => {
    store.dispatch({
        type: SET_HAS_SKODA_IDENT_ACTIVE,
        payload: {
            value: true,
        },
    })
}

export const closeHasSkodaIdentPopup = () => {
    store.dispatch({
        type: SET_HAS_SKODA_IDENT_ACTIVE,
        payload: {
            value: false,
        },
    })
}

export const showLogoutFromGRPPopup = () => {
    store.dispatch({
        type: SET_LOGOUT_FROM_GRP_ACTIVE,
        payload: {
            value: true,
        },
    })
}

export const closeLogoutFromGRPPopup = () => {
    store.dispatch({
        type: SET_LOGOUT_FROM_GRP_ACTIVE,
        payload: {
            value: false,
        },
    })
}

export const allowAccessToCreateProcess = () => {
    store.dispatch({
        type: SET_ACCESS_TO_CREATE_PROCESS,
        payload: {
            value: true,
        },
    })
}

export const disallowAccessToCreateProcess = () => {
    store.dispatch({
        type: SET_ACCESS_TO_CREATE_PROCESS,
        payload: {
            value: false,
        },
    })
}

import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import configureStore, { history } from './configureStore'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { OidcProvider, loadUser } from './oidc'
import ConfigurationHolder from './configurationHolder'
import UserManagerHolder from './userManagerHolder'
import './i18n'
import { setBasePath } from './api'

const initializeApplication = () => {
    if (process.env.NODE_ENV === 'production') {
        // Hotjar Tracking Code - MSP-51520
        ;(function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    ;(h.hj.q = h.hj.q || []).push(arguments)
                }
            h._hjSettings = { hjid: 4953657, hjsv: 6 }
            a = o.getElementsByTagName('head')[0]
            r = o.createElement('script')
            r.async = 1
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
            a.appendChild(r)
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }

    fetch(`${window.location.origin}/config/config.json`)
        .then(async (response) => {
            try {
                const responseJson = await response.json()
                const configuration = {
                    ...responseJson,
                    userManagerConfiguration: {
                        ...responseJson.userManagerConfiguration,
                        redirect_uri: `${window.location.origin}/login-callback`,
                        post_logout_redirect_uri: `${window.location.origin}/logout`,
                    },
                }

                ConfigurationHolder.setConfiguration(configuration)
                setBasePath(ConfigurationHolder.getConfiguration().basePath)

                UserManagerHolder.initUserManager(
                    ConfigurationHolder.getConfiguration().userManagerConfiguration
                )

                const userPromise = loadUser(store, UserManagerHolder.getUserManager())
                Promise.all([userPromise]).then(() => {
                    ReactDOM.render(
                        <Provider store={store}>
                            <OidcProvider
                                store={store}
                                userManager={UserManagerHolder.getUserManager()}
                            >
                                <ConnectedRouter history={history}>
                                    <PersistGate loading={null} persistor={persistor}>
                                        <App />
                                    </PersistGate>
                                </ConnectedRouter>
                            </OidcProvider>
                        </Provider>,
                        document.getElementById('root')
                    )
                })
            } catch (error) {
                console.error(error)
                throw new Error('External configuration failed to load.')
            }
        })
        .catch((error) => {
            console.error(error)
            throw new Error('External configuration failed to load.')
        })
}

const initialState = {}
export const { store, persistor } = configureStore(initialState)

initializeApplication()

serviceWorker.unregister()

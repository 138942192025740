import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { closeLogoutFromGRPPopup } from '../../../actions/popupsActions'
import PopUp from '../PopUp'
import React from 'react'
import { TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { WarningIcon } from '../../Icons/WarningIcon'
import { useAuthActions } from '../../../hooks/actions/useAuthActions'
import { Box, Button, Typography } from '@material-ui/core'

export default function LogoutFromGRPPopUp() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { logoutFromGRPPopupActive } = useSelector((state) => state.popups)
    const { user } = useSelector((state) => state.oidc)
    const { logoutFromGRP } = useAuthActions()

    const content = (
        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <WarningIcon width={56} />
            <Box mt={3}>
                <Typography variant={'h3'} align={'center'}>
                    {t('general.customer.logout.grp.popup.content')}
                </Typography>
            </Box>
        </Box>
    )

    const logout = () => {
        logoutFromGRP(user && user.refresh_token, user.access_token)
    }

    const actions = (
        <Box flexDirection={'column'} display={'flex'} alignItems={'center'}>
            <Button variant={'contained'} color={'primary'} onClick={logout}>
                {t('general.customer.create.popup.yes')}
            </Button>
            <Box mt={2}>
                <Button variant={'outlined'} onClick={closeLogoutFromGRPPopup}>
                    {t('general.customer.create.popup.no')}
                </Button>
            </Box>
        </Box>
    )

    const config = {
        title: t('general.dealer.profile.title'),
        type: 'LOGOUT',
        actions: actions,
        body: content,
    }
    return (
        <PopUp
            onClose={closeLogoutFromGRPPopup}
            open={logoutFromGRPPopupActive}
            body={config.body}
            title={config.title}
            actions={config.actions}
        />
    )
}

import React, { useEffect, useState } from 'react'
import { MuiThemeProvider } from 'material-ui'
import HorizontalStepper from '../../components/HorizontalStepper'
import {
    NAVIGATION_BUTTONS_CONFIGURATION,
    SKODA_IDENT_STEP,
} from '../../constants/registrationConstants'
import { useSelector } from 'react-redux'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import Panel from '../../components/Wrappers/Panel'
import { useHistory } from 'react-router-dom'
import { SkodaIdentFormValidator, isIdentValid, getErrorText } from '../../util/validators'
import { useTranslation } from 'react-i18next'
import { INPUT_MEDIUM } from '../../constants/defaultFormValues'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { FormTitle } from '../../components/FormElements'
import { countryPhoneCodes } from '../../constants/countryPhoneCodesConfiguration'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import RequiredBox from '../../components/RequiredBox'
import { Box, Button, Grid, MenuItem, TextField } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

function CreateSkodaIdent() {
    const { skodaIdent, customerInformation } = useSelector((state) => state.customer)
    const { countries, selectedLanguage } = useSelector((state) => state.options)
    const { accessToCreateProcess } = useSelector((state) => state.popups)
    const [errors, setErrors] = useState({})

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    let history = useHistory()
    const { saveSkodaIdentField } = useUpdateCustomerActions()
    const buttonConfig = NAVIGATION_BUTTONS_CONFIGURATION[SKODA_IDENT_STEP]

    useEffect(() => {
        if (!accessToCreateProcess) {
            history.push({
                pathname: '/customers',
            })
        }
        if (!skodaIdent.countryOfIssueCode && customerInformation.address?.countryId) {
            saveSkodaIdentField(
                'countryOfIssueCode',
                countries.find(
                    (country) =>
                        country.id.toString() === customerInformation.address.countryId.toString()
                )?.countryCode || ''
            )
        }
    }, [
        accessToCreateProcess,
        countries,
        customerInformation.address,
        history,
        saveSkodaIdentField,
        skodaIdent.countryOfIssueCode,
    ])

    const handleChange = (event) => {
        event.persist()
        saveSkodaIdentField(event.target.name, event.target.value)
    }

    const onNextClick = () => {
        const validationErrors = SkodaIdentFormValidator(skodaIdent, null)
        setErrors(validationErrors)
        if (isIdentValid(validationErrors)) {
            history.push({
                pathname: buttonConfig.nextButtonUrl,
            })
        }
    }

    return (
        <ScrollTop>
            <MuiThemeProvider>
                <FormWrapper>
                    <HorizontalStepper activeStep={SKODA_IDENT_STEP} />
                    <Panel>
                        <FormTitle>{t('general.ident.title')}</FormTitle>
                        <form noValidate autoComplete="off">
                            <TextField
                                name={'licenseNumber'}
                                onChange={handleChange}
                                label={t('general.ident.licenseNumber')}
                                value={skodaIdent.licenseNumber || ''}
                                fullWidth
                                error={!!errors.licenseNumber}
                                helperText={
                                    errors.licenseNumber && (
                                        <>
                                            {t('general.ident.licenseNumber')}{' '}
                                            {t(getErrorText(errors.licenseNumber))}
                                        </>
                                    )
                                }
                                inputProps={{ maxLength: INPUT_MEDIUM }}
                                required
                            />
                            <TextField
                                select
                                name={'countryOfIssueCode'}
                                onChange={handleChange}
                                label={t('general.ident.issueCountry')}
                                value={skodaIdent.countryOfIssueCode || ''}
                                error={!!errors.countryOfIssueCode}
                                helperText={
                                    errors.countryOfIssueCode && (
                                        <>
                                            {t('general.ident.issueCountry')}{' '}
                                            {t(getErrorText(errors.countryOfIssueCode))}
                                        </>
                                    )
                                }
                                required
                            >
                                {countryPhoneCodes
                                    .map((item) => ({
                                        ...item,
                                        label: t(`general.country.${item.code}`),
                                        countryNameTranslated: t(`general.country.${item.code}`, {
                                            replace: ignoreKeyInHTML,
                                        }),
                                    }))
                                    .sort((a, b) =>
                                        a.countryNameTranslated.localeCompare(
                                            b.countryNameTranslated,
                                            selectedLanguage.substring(0, 2)
                                        )
                                    )
                                    .map((item) => (
                                        <MenuItem value={item.code} key={item.code}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </form>
                        <RequiredBox withTop />
                        <Box mt={4}>
                            <Grid container spacing={2} justifyContent={'center'}>
                                <Grid item>
                                    <Button
                                        style={{ minWidth: 'auto' }}
                                        variant={'outlined'}
                                        onClick={() => history.push('/customer-information')}
                                        startIcon={<ArrowBack />}
                                    >
                                        {t('general.button.back')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={onNextClick}
                                    >
                                        {t('general.button.continue.order')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Panel>
                </FormWrapper>
            </MuiThemeProvider>
        </ScrollTop>
    )
}

export default WithHeader(CreateSkodaIdent)
